@import "../../../scss/index.scss";

.header {
  padding: 24px;
  background-color: var(--color-grayscale-25);
  @include safe-top-margin;
  --padding-top: 24px;
  

  .topSection {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      @include typography($title-2);
      color: var(--color-grayscale-1000);
    }
  }
}

.toggleContainer {
  display: flex;
  align-items: center;
  gap: 8px;
  @include typography($label-body-4-regular);
}

.content {
  --background: var(--color-grayscale-25);
  --padding-start: 24px;
  --padding-end: 24px;
  --padding-top: 12px;
  --padding-bottom: 12px;

  .topbar {
    width: 50%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;

    @media screen and (min-width: 768px) {
      padding-top: 12px;
    }

    .title {
      @include typography($headline-h3);
      color: var(--color-grayscale-1000);
    }
  }

  .container {
    border-radius: 8px;
    ion-list {
      border-radius: 8px;
      ion-item {
        display: flex;
      }
    }
    
    @media screen and (min-width: 768px) {
      width: 50%;
      margin: 0 auto;
      padding: 4px 12px;
      background-color: var(--color-white);
      border-radius: 8px;

      box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.08),
        0px 1px 18px 0px rgba(0, 0, 0, 0.06), 0px 3px 5px 0px rgba(0, 0, 0, 0.1);
    }
  }

  .noActivities {
    text-align: center;
    padding: 12px;
  }
}

@media screen and (min-width: 768px) {
  .header {
      padding: 0;
    }
}