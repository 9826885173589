@import '../../../../../../scss/index.scss';

.container {
    ion-item {
        margin: 12px 0;
    }

    .calculator {
        margin-top: 24px;
    }
}

@media screen and (min-width: $breakpoint-md) {
    .container {
        .header {
            display: grid;
            grid-template-columns: repeat(4, 1fr);

            ion-item {
                grid-column: 1 / 2;
            }
        }

        .calculator {
            .calculatorContainer {
                padding: 0;
                background: none !important;
            }
        }
    }
}