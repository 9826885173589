@import "../../../../scss/index.scss";

ion-alert {
  .alert-title {
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 1.8rem;
    color: $grey-dark-2;
  }

  .alert-message {
    display: flex;
    justify-content: center;
    font-size: 1.5rem;
    color: $grey-dark-1;
    text-align: center;
  }
}

.errorToast {
  --background: #ff5500;
  --color: white;
  font-size: 1.2rem;
  --button-color: white;
}
.successToast {
  --background: #1f7a60;
  --color: white;
  font-size: 1.2rem;
  --button-color: white;
}
