@import "../../../../../scss/index.scss";

.container {
  display: flex;
  flex-direction: column;
  gap: 12px;

  .status {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;

    ion-label {
      @include typography($caption-1);
      color: var(--color-grayscale-400);
    }

    ion-item {
      flex: 1;
      text-transform: capitalize;
    }
  }

  .note {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 12px;

    div {
      display: flex;
      flex-direction: column;
      gap: 4px;
      .text {
        @include typography($caption-1);
        color: var(--color-grayscale-400);
      }

      .title {
        display: none;
        color: var(--color-grayscale-800);
        @include typography($label-body-5-medium);
      }
    }

    .noteButtons {
      display: flex;
      align-items: center;
      gap: 8px;
      .addButton {
        display: none;
      }
    }
  }

  .stops {
    display: flex;
    flex-direction: column;

    .reorderButton {
      align-self: flex-end;
    }

    .loading {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 80px;
    }

    ion-reorder-group {
      display: flex;
      flex-direction: column;
      gap: 12px;

      ion-item {
        position: relative;
        --padding-start: 0;
        --padding-end: 0;
        --inner-padding-start: 0;
        --inner-padding-end: 0;
        --background: unset;
      }

      ion-reorder {
        ion-item {
          box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2);
        }
      }
    }
  }

  .buttons {
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}

@media screen and (min-width: $breakpoint-md) {
  .container {
    flex-direction: column;
    gap: 24px;
    border: 1px solid var(--color-grayscale-200);
    border-radius: 8px;
    padding: 12px;

    .status {
      display: flex;
      flex-direction: column;
      gap: 12px;
      align-items: flex-start;

      ion-item {
        width: 50%;
      }
    }

    .note {
      div {
        .title {
          display: block;
        }
      }

      .noteButtons {
        flex-direction: row;
        .addButton {
          display: block;
        }
      }
    }

    .stops {
      .disabled {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;

        .loading {
          grid-column: -1 / 1;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 200px;
        }
      }
    }

    .buttons {
      display: none;
    }
  }
}