@import "../../../../scss/index.scss";

.container {
  position: relative;
  display: flex;
  flex-direction: column;

  .title {
    @include typography($style: $label-body-3-medium);
    color: var(--color-grayscale-800);
  }

  ion-segment {
    position: -webkit-sticky;
    position: -moz-sticky;
    position: -ms-sticky;
    position: -o-sticky;
    position: sticky;
    @include safe-top('50px');
    left: 0;
    right: 0;
    z-index: 10;
    @include hide-scrollbar;
    margin-top: 24px;
    margin-bottom: 12px;
    z-index: 100;

    --background: var(--color-grayscale-50);

    &.noMargin {
      margin-left: calc(-50vw + 50%);
      margin-right: calc(-50vw + 50%);
    }

    ion-segment-button {
      height: 32px;
      --color: var(--color-grayscale-600);
      --color-checked: var(--color-white);
      --border-radius: 8px;
      @include typography($style: $label-body-3-medium);
    }

    ion-segment-button::part(indicator-background) {
      background: var(--color-grayscale-1000);
    }

    ion-segment-button.ios::part(native) {
      color: var(--color-grayscale-1000);
    }

    .segment-button-checked.ios::part(native) {
      color: var(--color-white);
    }

    ion-segment-button.ios::part(indicator-background) {
      border-radius: 8px;
    }

    @media screen and (min-width: $breakpoint-md) {
      top: 0;
    }
  }

  .segmentContent {
    position: relative;
    max-height: 512px;
    overflow-y: hidden;

    .fade {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 120px;
      z-index: 1;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, var(--color-grayscale-25) 100%);
      pointer-events: none;
    }

    .showMoreButton {
      cursor: pointer;
      position: absolute;
      bottom: 12px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 2;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 240ox;
      @include typography($style: $caption-1);
      color: var(--color-grayscale-1000);

      ion-icon {
        font-size: 14px;
      }
    }
  }

  .expanded {
    max-height: none;
    overflow-y: visible;

    .fade {
      display: none;
    }

    .showMoreButton {
      bottom: -36px;
    }
  }
}


@media screen and (min-width: $breakpoint-md) {
  .container {
    .title {
      @include typography($style: $title-2);
      margin: 0 48px;
    }

    ion-segment {
      top: 0;
    }

    ion-segment-button {
      height: 40px !important;
      @include typography($style: $label-body-1-semi-bold, $important: true);
    }

    .segmentContent {
      .showMoreButton {
        @include typography($style: $label-body-5-regular);
        ion-icon {
          font-size: 16px;
        }
      }
    }
  }
}