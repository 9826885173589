@import "../../../scss/index.scss";

.sheet {
  --height: auto;
  --max-height: 80vh;
  ion-content {
    --background: var(--color-white);
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 24px;
  }
}

.modal {
  --border-radius: 12px;
  --height: auto;

  ion-content {
    --background: var(--color-white);
    padding: 24px;
    height: auto;
  }
}
