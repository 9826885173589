@import "../../../../../../scss/index.scss";

.container {
  margin: 12px 0;

  .loadingContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 12px;
  }

  .marketDataContianer {
    .summary {
      display: flex;
      flex-direction: column;
    }

    .title {
      margin-bottom: 12px;
      @include typography($style: $label-body-3-light);

      span {
        font-weight: 500;
      }
    }
    .valueChanges {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .change {
        @include typography($style: $label-body-3-light);
        display: flex;
        gap: 16px;
        padding: 0 8px;

        &:nth-of-type(2) {
          border-left: 1px solid var(--color-grayscale-200);
          border-right: 1px solid var(--color-grayscale-200);
        }

        .increase {
          color: var(--color-success-normal);
        }

        .decrease {
          color: var(--color-danger-normal);
        }
      }
    }

    ion-item {
      margin: 24px 0;
    }

    .chartsTitle {
      @include typography($style: $label-body-3-regular);
      color: var(--color-grayscale-800);
      margin-bottom: 24px;
    }

    .chart {
      width: 100%;
      height: auto;
      margin: 12px 0;
    }

    .chartLabel {
      @include typography($style: $label-body-4-regular);
    }
  }
}

@media screen and (min-width: $breakpoint-md) {
  .container {
    .marketDataContianer {
      .summary {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        column-gap: 24px;

        & > div {
          height: 100%;
        }
      }

      .header {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        column-gap: 24px;

        ion-item {
          grid-column: 1 / 2;
        }

        .chartsTitle {
          grid-column: 2 / -1;
          @include typography($style: $label-body-1-regular);
          display: flex;
          align-items: center;
        }
      }

      .charts {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 24px;
        row-gap: 12px;

        & > div {
          height: 100%;
          padding: 24px;
        }
      }

      .title {
        @include typography($style: $label-body-1-regular);
      }
      .valueChanges {
        .change {
          @include typography($style: $label-body-2-regular);
        }
      }

      .chartLabel {
        @include typography($style: $label-body-1-semi-bold);
      }
    }
  }
}
