.container {
    height: auto;
    background-color: var(--color-white);
    padding: 12px;
    display: flex;
    flex-direction: column;
    color: var(--color-grayscale-600);
    border-radius: 12px;

    .header {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 8px;
        border-bottom: 1px solid var(--color-grayscale-200);
        padding: 12px 0;

        h4 {
            margin: 0;
        }

        .info {
            display: flex;
            align-items: center;
            font-size: 12px;
            font-weight: 400;

            span:not(:last-of-type)::after {
                content: '|';
                margin: 0 8px;
                color: var(--color-grayscale-200)
            }
        }
    }

    .rating {
        display: flex;
        align-items: center;
        gap: 12px;
        border-bottom: 1px solid var(--color-grayscale-200);
        padding: 12px 0;

        .rate {
            width: 80px;
            height: 80px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-color: var(--color-grayscale-50);
            padding: 8px 12px;
            border-radius: 8px;

            span:first-of-type {
                font-size: 36px;
                font-weight: 600;
                color: var(--color-tag-orange);
            }

            span:last-of-type {
                font-size: 10px;
                font-weight: 400;
                color: var(--color-grayscale-400);
            }
        }

        .ratingDetails {
            display: flex;
            flex-direction: column;
            width: 100%;
            gap: 8px;
            font-size: 14px;
            font-weight: 400;
            color: var(--color-grayscale-600);

            .ratingTitle {
                font-weight: 300;
            }

            .yearRate {
                display: flex;
                justify-content: space-between;

                span:last-of-type {
                    color: var(--color-grayscale-1000);
                }
            }
        }
    }

    .boundary {
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding: 12px 0;
        border-bottom: 1px solid var(--color-grayscale-200);

        .boundaryTitle {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 14px;
            font-weight: 500;
        }

        p {
            font-size: 14px;
            font-weight: 400;
            margin: 0;
        }
    }

    .footer {
        font-size: 10px;
        font-weight: 400;
        color: var(--color-grayscale-800);
        padding-top: 12px;

        a {
            text-decoration: underline;
        }
    }
}