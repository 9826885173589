@import "../../../../../../scss/index.scss";

.clusterMarker {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 18px;
  font-size: 18px;
  font-weight: 600;
  border-radius: 50%;
  color: var(--color-grayscale-800);
  background-color: var(--color-cluster-roadmap);
  cursor: pointer;
  z-index: 10;

  &:hover {
    background-color: var(--color-grayscale-1000);
    color: var(--color-white);
  }
}

.preClusterMarker {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 18px;
  font-size: 18px;
  font-weight: 600;
  border-radius: 50%;
  color: white;
  background-color: $pre-cluster-bg-color;
  border: $blue-light;
  cursor: pointer;
  z-index: 10;

  &:hover {
    background-color: $blue-dark;
  }
}

.animatedClusterMarker {
  animation: mount 0.4s ease-in-out;

  //0.2s calc(var(--animation-order) * 125ms) both ease-in;
}

.animatedMarker {
  animation: mountTranslate 0.4s ease-in-out;
}

.clusterMarkerLight {
  background-color: $grey-light-2;
  border-color: $grey-dark-2;
  color: $grey-dark-2;

  &:hover {
    background-color: $grey-dark-2;
    color: white;
    border-color: white;
  }
}

.preClusterMarkerLight {
  background-color: $pre-cluster-bg-color-light;
  border-color: $blue-medium;
  color: $blue-medium;

  &:hover {
    background-color: $blue-medium;
    color: $blue-light;
    border-color: $blue-light;
  }
}

.marker {
  position: relative;
  background-color: var(--color-marker-light);
  width: 57px;
  height: 32px;
  border-radius: 100px;
  cursor: pointer;
  z-index: 10;
  padding: 10px;
  .price {
    @include center-vertically;
    @include center-horizontally;
    color: var(--color-grayscale-800);
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
  }

  // &:after {
  //   content: "";
  //   position: absolute;
  //   top: 100%;
  //   left: 50%;
  //   transform: translateX(-50%);
  //   width: 0;
  //   height: 0;
  //   border-top: solid 0.7rem var(--color-marker-light);
  //   border-left: solid 0.7rem transparent;
  //   border-right: solid 0.7rem transparent;
  // }

  &:hover {
    background-color: var(--color-marker-dark);
    .price {
      color: var(--color-white);
    }
   

    // &::after {
    //   border-top-color: var(--color-marker-dark);
    // }
  }
}

.preMarker {
  position: relative;
  background-color: $blue-medium;
  width: 5.5rem;
  height: 2.5rem;
  cursor: pointer;
  z-index: 10;
  .price {
    @include center-vertically;
    @include center-horizontally;
    color: $blue-light;
    font-size: 1.5rem;
    text-transform: uppercase;
  }

  // &:after {
  //   content: "";
  //   position: absolute;
  //   top: 100%;
  //   left: 50%;
  //   transform: translateX(-50%);
  //   width: 0;
  //   height: 0;
  //   border-top: solid 0.7rem $blue-medium;
  //   border-left: solid 0.7rem transparent;
  //   border-right: solid 0.7rem transparent;
  // }

  &:hover {
    background-color: $blue-dark;

    &::after {
      border-top-color: $blue-dark;
    }
  }
}

.detailsMarker {
  background-color: var(--color-main-mint);
}

.selectedDetailsMarker {
  background-color: var(--color-green-shade1);
  .price {
    color: var(--color-white);
  }
}

.wideMarker {
  width: 6rem;
}
.preWideMarker {
  width: 6.5rem;
}

.markerLight {
  background-color: $grey-light-2;
  .price {
    color: $grey-dark-2;
  }

  &::after {
    border-top-color: $grey-light-2;
  }

  &:hover {
    background-color: $grey-dark-2;

    .price {
      color: white;
    }

    &::after {
      border-top-color: $grey-dark-2;
    }
  }
}

.preMarkerLight {
  background-color: $blue-light;
  .price {
    color: $blue-medium;
  }

  &::after {
    border-top-color: $blue-light;
  }

  &:hover {
    background-color: $blue-medium;

    .price {
      color: $blue-light;
    }

    &::after {
      border-top-color: $blue-medium;
    }
  }
}

.selectedCluster {
  background-color: var(--color-grayscale-800);
  color: var(--color-white);
}
.preSelectedCluster {
  background-color: $blue-dark;
}

.selectedMarker {
  background-color: var(--color-grayscale-800);
  
  .price {
    color: var(--color-white);
  }

  &:after {
    border-top-color: var(--color-grayscale-800);
  }
}

.preSelectedMarker {
  background-color: $blue-dark;

  &:after {
    border-top-color: $blue-dark;
  }
}

.selectedClusterLight {
  background-color: $grey-dark-2;
  border-color: white;
  color: white;
}

.preSelectedClusterLight {
  background-color: $blue-medium;
  border-color: $blue-light;
  color: $blue-light;
}

.selectedMarkerLight {
  background-color: $grey-dark-2;
  .price {
    color: white;
  }

  &:after {
    border-top-color: $grey-dark-2;
  }
}

.preSelectedMarkerLight {
  background-color: $blue-medium;
  .price {
    color: $blue-light;
  }

  &:after {
    border-top-color: $blue-medium;
  }
}

.hovered {
  animation: hoverAnimation 1.5s ease infinite;
}

.activeCluster {
  background-color: #ffeee5e8;
  border-color: $orange-medium;
  color: $orange-medium;
}

.preActiveCluster {
  background-color: #ffeee5e8;
  border-color: $orange-medium;
  color: $orange-medium;
}

.activeMarker {
  background-color: $orange-medium;
  &:after {
    border-top-color: $orange-medium;
  }
}

.preActiveMarker {
  background-color: $orange-medium;
  &:after {
    border-top-color: $orange-medium;
  }
}

@keyframes hoverAnimation {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-0.5rem);
  }
  60% {
    transform: translateY(-0.2rem);
  }
}

@keyframes mount {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes mountTranslate {
  0% {
    opacity: 0;
    transform: scale(1) translateY(-25px);
  }
  100% {
    opacity: 1;
    transform: scale(1) translateY(0);
  }
}
