@import "../../../scss/index.scss";

.schoolDetails {
  @include safe-top-padding;
  display: flex;
  flex-direction: column;
  height: 100%;
  @include show-scrollbar;

  ion-card {
    height: 100%;
    flex: 1;
    overflow-y: scroll;
  }
}
