@import "../../../../scss/index.scss";

.container {
  position: relative;
  width: 100%;
  min-width: 312px;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 8px;
  cursor: pointer;

  .card {
    display: flex;
    gap: 14px;
  }

  .restrictedAccess {
    position: absolute;
    width: 70%;
    height: 70%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    background-color: var(--color-white);
    border-radius: 24px;
    padding: 16px;
    box-shadow: 0px 1px 12px 0px #0000001a;
    box-shadow: 0px 1px 7px 0px #0000000f;
    box-shadow: 0px 1px 5px 0px #00000014;
  }

  .openHouses {
    background-color: var(--color-sub-mint);
    padding: 4px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    gap: 8px;
    @include typography($style: $label-body-5-regular);
    .title {
      @include typography($style: $label-body-5-medium);
      white-space: nowrap;
    }
    span:last-of-type {
      color: var(--color-grayscale-600);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .image {
    position: relative;
    width: 130px;
    height: 148px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 8px;
    }

    ion-spinner {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .details {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;

    .icons {
      position: absolute;
      top: 0;
      right: 0;
    }

    .price {
      @include typography($style: $label-body-1-semi-bold);
      color: var(--color-grayscale-1000);
      margin-bottom: 8px;
    }

    .address,
    .type,
    .rooms,
    .tag,
    .mlsNumber {
      @include typography($style: $label-body-5-regular);
      color: var(--color-grayscale-600);
    }

    .rooms {
      span:not(:last-of-type) {
        padding-right: 8px;
      }

      span:not(:first-of-type) {
        padding-left: 8px;
        border-left: 1px solid var(--color-grayscale-200);
      }
    }

    .tag {
      display: flex;
      align-items: center;
      gap: 12px;
      margin: 8px 0;
    }

    .mlsNumber {
      width: fit-content;
      margin-top: auto;
      border: 1px solid var(--color-grayscale-600);
      border-radius: 4px;
      padding: 0 4px;
    }
  }

  .scatteredData {
    filter: blur(4px);
  }
}
