@import "../../../scss/index.scss";

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  border-radius: 4px;
  background-color: var(--color-warning-normal);
  color: var(--color-grayscale-1000);
  @include typography($label-body-5-medium);
  text-transform: capitalize;
  height: fit-content;
}

.small {
  padding: 1px;
  @include typography($caption-1);
}

.medium {
  width: 75px;
  padding: 4px;
  @include typography($label-body-5-medium);
}

.large {
  width: 100px;
  padding: 6px;
  @include typography($label-body-4-regular);
}

.completed {
    background-color: var(--color-green-shade2);
    color: var(--color-white) !important;
  }
  .ready, .confirmed {
    background-color: var(--color-green-shade1);
    color: var(--color-white) !important;
  }
  .pending, .requested {
    background-color: var(--color-warning-normal);
    color: var(--color-grayscale-1000) !important;
  }
  .cancelled {
    background-color: var(--color-grayscale-200);
    color: var(--color-grayscale-1000) !important;
  }
  .draft {
    background-color: var(--color-warning-shade);
    color: var(--color-grayscale-1000) !important;
  }
  .archived, .skipped {
    background-color: var(--color-warning-tint);
    color: var(--color-grayscale-1000) !important;
  }
  .rejected {
    background-color: var(--color-tag-red);
    color: var(--color-grayscale-1000) !important;
  }
