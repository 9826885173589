@import '../../../scss/index.scss';


.container {
    flex: 1;
    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;

    .title {
        @include typography($style: $headline-h3);

        @media screen and (min-width: $breakpoint-md) {
            @include typography($style: $headline-h1);
        }
    }

    .message {
        @include typography($style: $label-body-3-light);
        text-align: center;

        @media screen and (min-width: $breakpoint-md) {
            @include typography($style: $label-body-1-light);
        }
    }

    svg {
        width: 320px;
        height: 320px;
    }
}