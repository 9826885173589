@import "../../../scss/index.scss";
.appHeader {
  top: 0;
  z-index: 100;
  width: 100%;
  position: fixed;
  transition: "opacity 0.6s, transform 0.6s";
  background-color: var(--color-white);
  box-shadow: 0px 6px 10px 0px #00000014;
  box-shadow: 0px 1px 18px 0px #0000000f;
  box-shadow: 0px 3px 5px 0px #0000001a;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 12px;
  @include safe-top-padding("12px");
  display: flex;
  align-items: center;
  justify-content: space-between;

  ion-icon {
    font-size: 20px;
    color: var(--color-grayscale-800);
    fill: none;
  }

  .buttons {
    display: flex;
    align-items: center;
    gap: 16px;

    .enabledFavourite {
      fill: var(--color-black);
    }

    .disabledFavourite {
      fill: var(--color-white);
      stroke: var(--color-black);

      &:hover {
        fill: var(--color-black);
      }
    }

    .enabledNotification {
      fill: var(--color-warning-normal) !important;
      color: var(--color-warning-normal) !important;
    }

    .disabledNotification {
      fill: var(--color-white) !important;
      color: var(--color-black) !important;

      &:hover {
        fill: var(--color-warning-normal);
        color: var(--color-warning-normal);
      }
    }

    .loading {
      animation: rotate 0.5s ease-in-out infinite;
      animation-fill-mode: forwards;
    }

    .disabled {
      cursor: not-allowed;
      fill: var(--color-white) !important;
      color: var(--color-grayscale-200) !important;
    }
  }
}

.content {
  --background: var(--color-grayscale-25);

  .container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  .listingContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .section {
    padding: 12px 24px;

    &:not(:first-of-type),
    &:not(:last-of-type) {
      margin: 12px 0;
    }
  }

  .webFooter {
    display: none;
  }
}

.appFooter {
  background-color: var(--color-white);
  box-shadow: 0px -6px 10px 0px #00000014;
  box-shadow: 0px -1px 18px 0px #0000000f;
  box-shadow: 0px -3px 5px 0px #0000001a;

  ion-toolbar {
    --padding-start: 12px;
    --padding-end: 12px;
    --padding-bottom: 12px;
    --padding-top: 12px;
  }
}

@media screen and (min-width: $breakpoint-md) {
  .appHeader {
    display: none;
  }

  .content {
    .section {
      padding: 12px 48px;
    }

    .webFooter {
      display: block;
    }
  }

  .appFooter {
    display: none;
  }
}

@keyframes rotate {
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}
