@import "../../scss/index.scss";

.footer {
  font-size: 1.5rem;
  display: grid;
  grid-template-columns: auto auto auto;
  width: 100%;
  background-color: $grey-dark-3;
  color: white;
  padding: 2rem 3rem;
  justify-content: space-between;

  .stores {
    display: grid;
    align-items: center;

    .applestore {
      cursor: pointer;
    }
    .googlestore {
      height: 6rem;
      width: 16rem;
      cursor: pointer;
    }
  }

  .contact {
    display: flex;
    flex-direction: column;

    .social {
      display: flex;
      flex-direction: column;

      .label {
        font-weight: 500;
        margin-bottom: 1rem;
        color: $green-light-2;
      }
      .icons {
        display: flex;
        width: 25rem;
        color: white;

        ion-icon {
          cursor: pointer;
          width: 3rem;
          height: 3rem;
          cursor: pointer;
          &:not(:last-of-type) {
            margin-right: 1.5rem;
          }
        }
      }
    }

    .question {
      font-weight: 500;
      margin-bottom: 2rem;
      color: $green-light-2;
    }

    .feedback {
      font-weight: 300;
      @media screen and (max-width: $large) {
        display: none;
      }
    }

    .email {
      margin-top: 3rem;
      font-weight: 300;
      margin-top: 0;
      margin-bottom: 1rem;
      color: white;

      .link {
        cursor: pointer;
        text-decoration: underline;
        font-weight: 500;
        color: white;
      }
    }
  }

  .details {
    display: flex;
    flex-direction: column;
    justify-content: stretch;

    .termsContainer {
      display: flex;
      align-items: center;
      .terms {
        color: white;
        font-size: 1rem;

        .link {
          a {
            color: white;
            font-size: 1rem;
            text-decoration: underline;
          }
          &:first-of-type::after {
            content: "|";
            margin: 0 0.5rem;
          }
        }
      }

      .version {
        color: white;
        font-size: 1rem;
        margin-left: 1rem;
      }
    }

    .title {
      font-weight: 500;
      color: $green-light-2;
    }
    .address {
      font-weight: 300;
    }

    .copyRight {
      flex: 1;
      justify-self: flex-end;
      display: flex;
      align-items: flex-end;
      color: white;
      font-size: 1rem;
    }
  }
}
