@import "../../../scss/index.scss";

.header {
  padding: 24px;
  background-color: var(--color-grayscale-25);

  ion-toolbar {
    --background: none;
    --border-width: 0 !important;
  }
}

.summary {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0;

  .moreIcon {
    font-size: 24px;
    cursor: pointer;
  }

  .title {
    display: flex;
    align-items: center;
    gap: 8px;
    @include typography($label-body-1-semi-bold);
    color: var(--color-grayscale-600);
  }
}

.datetime {
  display: flex;
  align-items: center;
  justify-content: space-between;

  div:first-of-type {
    display: flex;
    flex-direction: column;
    gap: 4px;

    span {
      display: flex;
      align-items: center;
      gap: 4px;

      @include typography($label-body-5-regular);
      ion-icon {
        font-size: 16px;
      }
    }
  }

  @media screen and (min-width: 1240px) {
    justify-content: flex-start;
    gap: 0px;

    div:first-of-type {
      flex-direction: row;
      gap: 8px;
    }
  }
}

.content {
  --background: var(--color-grayscale-25);
  --padding-start: 24px;
  --padding-end: 24px;
  @include safe-top-margin;

  .label {
    @include typography($label-body-3-medium);
    color: var(--color-grayscale-600);
  }

  .attendees,
  .meetupDetails,
  .stops,
  .generalNotes {
    display: flex;
    flex-direction: column;
  }

  .attendees {
    .items {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }

  .meetupDetails {
    margin-top: 12px;
    gap: 8px;

    .content {
      display: flex;
      flex-direction: column;
      @include typography($label-body-3-light);
      color: var(--color-grayscale-800);
      background-color: var(--color-white);
      border-radius: 8px;
      padding: 8px 12px;
    }
  }

  .stops {
    gap: 8px;

    .items {
      display: flex;
      flex-direction: column;
      gap: 24px;
    }
  }

  .generalNotes {
    gap: 8px;
    height: 100%;

    .text {
      flex: 1;
      position: relative;
      @include typography($label-body-3-regular);
      color: var(--color-grayscale-800);

      ion-icon {
        font-size: 128px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  .card {
    margin-top: 12px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 24px;
    padding: 24px;
    background-color: var(--color-white);
    border-radius: 8px;

    section {
      display: flex;
      flex-direction: column;
      gap: 24px;

      &:not(:last-of-type) {
        border-right: 1px solid var(--color-grayscale-50);
      }

      .sectionHeader {
        @include typography($label-body-2-semi-medium);
      }

      .overview {
        gap: 24px;

        .attendees {
          border-bottom: 1px solid var(--color-grayscale-50);
        }

        .meetupDetails {
          gap: 8px;
          .content {
            padding: 0;
            @include typography($label-body-3-regular);
          }
        }
      }

      .stops {
        .items {
          @include hide-scrollbar;
          max-height: 478px;
          overflow-y: auto;
          padding: 12px;
        }
      }

      .generalNotes {
        gap: 0px !important;
      }
    }
  }
}

.segment {
  position: sticky;
  left: 0;
  right: 0;
  z-index: 10;
  @include hide-scrollbar;
  margin-top: 12px;
  margin-bottom: 12px;

  --background: var(--color-grayscale-50);

  &.noMargin {
    margin-left: calc(-50vw + 50%);
    margin-right: calc(-50vw + 50%);
  }

  ion-segment-button {
    @include typography($label-body-1-semi-bold);
  }
}

@media screen and (min-width: 1240px) {
  .header {
    padding: 0;
  }

  .content {
    --padding-start: 128px;
    --padding-end: 128px;
    --padding-top: 24px;
    --padding-bottom: 24px;
    ion-toolbar {
      --background: var(--color-grayscale-25);
    }

    .topButtons {
      display: flex;
      align-items: center;
      gap: 12px;
    }

    ion-segment {
      margin-top: 12px;
      ion-segment-button {
        @include typography($label-body-1-semi-bold);
      }
    }
  }
}

.footer {
  padding: 12px 24px;
  background-color: var(--color-grayscale-25);
  box-shadow: none;
  display: flex;
  align-items: center;
  gap: 12px;

  ion-button {
    flex: 1;
    height: 40px;
  }
}
