@import "../../../scss/index.scss";

.container {
    background-color: var(--color-white);
    height: 24px;
    padding: 0 24px;
    border-top: 1px solid var(--color-grayscale-25);
    @include typography($style: $caption-1);
    color: var(--color-grayscale-800);
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 24px;

    .links {
        span {
            padding: 0 8px;
        }
    }
}