button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.button {
    position: relative;
    border: 0;
    padding: 0;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
    background-color: white;
    box-shadow: 1px 1px 15px #00000034;

    svg {
      stroke: var(--color-grayscale-400);
      height: 22px;
      width: 22px;
    }

    .label {
      font-size: 15px;
      color:  var(--color-grayscale-600);
      margin-left: 5px;

      .clearIcon {
        font-size: 12px;
      }
    }

    &:active {
      background-color: var(--color-grayscale-800);
      svg {
        stroke: var(--color-white);
      }
    }
  }


  .disabled {
    cursor: default;
    svg {
      stroke: var(--color-grayscale-200);
    }
  }

  .active {
      background-color: var(--color-grayscale-1000);
      svg {
        stroke: var(--color-white) !important;
      }
  }

  .update {
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: var(--color-main-mint);
    border-radius: 50%;
    top: -4px;
    right: -4px;
    z-index: 1;
  }