.container {
    line-height: 18px;
    font-weight: 400;
    color: var(--color-white);
    border-radius: 4px;
    padding: 4px 8px;
    text-align: center;
    width: fit-content;
}

.small {
    font-size: 12px;
}

.large {
    font-size: 14px;
}

.New, .Cs {
    background-color: var(--color-tag-green);
}

.Pc, .Ext {
    background-color: var(--color-tag-orange);
}

.Sld, .Lsd, .Sc, .Sce, .Lsc{
    background-color: var(--color-tag-red);
}

.Sus, .Exp, .Ter, .Dft {
    background-color: var(--color-grayscale-200);
}