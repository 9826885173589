@import "../../../scss/index.scss";

.header {
  padding: 24px;
  background-color: var(--color-grayscale-25);

  .topSection {
    display: flex;
    flex-direction: column;
    gap: 8px;

    ion-toolbar {
      --background: none;
      --border-width: 0 !important;
    }

    .title {
      @include typography($title-2);
      color: var(--color-grayscale-1000);
    }
  }
}

.content {
  --background: var(--color-grayscale-25);
  --padding-start: 24px;
  --padding-end: 24px;
  --padding-top: 12px;
  --padding-bottom: 12px;

  .topbar {
    width: 50%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-bottom: 24px;

    ion-back-button {
      width: fit-content;
    }

    @media screen and (min-width: 768px) {
      padding-top: 12px;
    }

    .title {
      @include typography($headline-h3);
      color: var(--color-grayscale-1000);
    }
  }

  .container {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .listingSummary {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .address {
        display: flex;
        flex-direction: column;
        gap: 4px;

        span:first-of-type {
          @include typography($label-body-2-semi-medium);
          color: var(--color-grayscale-1000);
        }

        span:last-of-type {
          @include typography($label-body-3-light);
          color: var(--color-grayscale-800);
        }
      }
    }

    .tourText {
      display: flex;
      justify-content: space-between;
      background-color: var(--color-white);
      padding: 12px;
      border-radius: 8px;
      @include typography($label-body-3-regular);
    }

    .requesterInfo {
      display: flex;
      justify-content: space-between;
      background-color: var(--color-white);
      padding: 12px;
      border-radius: 8px;

      div {
        display: flex;
        flex-direction: column;
        gap: 4px;
        @include typography($label-body-5-regular);
        color: var(--color-grayscale-400);

        &:first-of-type {
          span:first-of-type {
            @include typography($label-body-3-semi-bold);
            color: var(--color-grayscale-600);
          }
        }
      }
    }

    .buttons {
      display: flex;
      align-items: center;
      gap: 12px;
      margin: 12px 0;

      ion-button {
        flex: 1;
      }
    }

    .tours {
      display: flex;
      flex-direction: column;
      gap: 12px;

      .note {
        @include typography($label-body-3-semi-bold);
        color: var(--color-grayscale-800);

        @media screen and (min-width: 768px) {
          @include typography($label-body-3-regular);
        }
      }

      .tour {
        background-color: var(--color-white);
        padding: 12px;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        gap: 12px;

        .tourHeader {
          display: flex;
          justify-content: space-between;
          align-items: center;

          span:first-of-type {
            display: flex;
            align-items: center;
            gap: 8px;
            @include typography($label-body-2-semi-medium);

            ion-icon {
              font-size: 18px;
              border: 1px solid var(--color-grayscale-200);
              border-radius: 4px;
              padding: 4px;
            }
          }

          .date {
            @include typography($label-body-5-regular);
            color: var(--color-grayscale-400);
          }
        }

        .stops {
          display: flex;
          flex-direction: column;
          gap: 8px;

          .title {
            @include typography($caption-1);
            color: var(--color-grayscale-400);
          }

          .stop {
            &:not(:last-of-type) {
              border-bottom: 1px solid var(--color-grayscale-50);
            }
            padding: 8px 0;
            display: flex;
            justify-content: space-between;

            .address {
              display: flex;
              flex-direction: column;
              gap: 4px;

              span:first-of-type {
                display: flex;
                align-items: center;
                gap: 4px;
                @include typography($label-body-2-semi-medium);
                color: var(--color-grayscale-1000);
              }

              span:last-of-type {
                @include typography($label-body-3-light);
                color: var(--color-grayscale-800);
              }

              .order {
                position: relative;
                ion-icon {
                  font-size: 24px;
                }
                span {
                  font-size: 8px;
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                  color: var(--color-white);
                }
              }
            }

            .time {
              @include typography($label-body-5-regular);
              color: var(--color-grayscale-400);
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .header {
    padding: 0;
  }
  .container {
    width: 50%;
    margin: 0 auto;
    background-color: var(--color-white);
    border-radius: 8px;
    padding: 12px;
    box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.08), 0px 1px 18px 0px rgba(0, 0, 0, 0.06), 0px 3px 5px 0px rgba(0, 0, 0, 0.1);
  }
}
