@import "../../../../../../scss/index.scss";

.container {
  margin: 12px 0;

  .history {
    flex-direction: column;
    align-items: flex-start;

    .historyInfo {
      &_label {
        @include typography($style: $label-body-4-regular);

        &_empty {
          display: none;
        }
      }

      &_value {
        @include typography($style: $label-body-3-light);

        &:first-of-type {
          @include typography($style: $label-body-4-regular);
        }
      }
    }

    &:first-of-type {
      display: none;
    }
  }
}

@media screen and (min-width: $breakpoint-md) {
  .container {
    .unauthenticatedAccess {
      ion-icon {
        font-size: 96px;
      }

      p {
        @include typography($style: $label-body-1-semi-bold);
      }
    }

    .history {
      flex-direction: row;
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      grid-template-rows: 1fr;
      column-gap: 24px;

      .historyInfo_label {
        display: none;
      }

      .historyInfo {
        &_value {
          @include typography($style: $label-body-1-light, $important: true);
        }

        &_label {
          &_empty {
            display: block;
          }
        }
      }

      &:first-of-type {
        display: grid;
        margin: 24px 0;
        @include typography($style: $label-body-1-semi-bold);
      }
    }
  }
}
