@import '../modals.module.scss';

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
    gap: 20px;

    ion-icon {
        font-size: 64px;
    }

    .title {
        @include typography($style: $label-body-1-semi-bold);
        color: var(--color-grayscale-600);
    }

    .description {
        @include typography($style: $label-body-3-light);
        color: var(--color-grayscale-1000);
        text-align: center;
    }

    .actions {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 8px;

        ion-button {
            flex: 1;
        }
    }
}