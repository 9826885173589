@import "../../../scss/index.scss";

.header {
  padding: 24px;
  background-color: var(--color-grayscale-25);

  ion-toolbar {
    --background: none;
    --border-width: 0 !important;
  }
}

.summary {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0;

  .title {
    display: flex;
    align-items: center;
    gap: 8px;
    @include typography($label-body-1-semi-bold);
    color: var(--color-grayscale-600);
    margin-right: 10px;
  }

  .shareText {
    @include typography($label-body-5-regular);
    color: var(--color-tag-orange);
    white-space: nowrap;
  }


}

.content {
  @include safe-top-margin;
  --background: var(--color-grayscale-25);
  --padding-start: 24px;
  --padding-end: 24px;
}

.segment {
  position: sticky;
  left: 0;
  right: 0;
  z-index: 10;
  @include hide-scrollbar;
  margin-top: 12px;
  margin-bottom: 12px;

  --background: var(--color-grayscale-50);

  &.noMargin {
    margin-left: calc(-50vw + 50%);
    margin-right: calc(-50vw + 50%);
  }

  ion-segment-button {
    @include typography($label-body-1-semi-bold);
  }
}

@media screen and (min-width: $breakpoint-md) {
  .header {
    padding: 0;
  }

  .content {
    --padding-start: 128px;
    --padding-end: 128px;
    --padding-top: 24px;
    --padding-bottom: 24px;
    ion-toolbar {
      --background: var(--color-grayscale-25);
    }

    .topButtons {
      display: flex;
      align-items: center;
      gap: 12px;
    }

    ion-segment {
      margin-top: 12px;
      ion-segment-button {
        @include typography($label-body-1-semi-bold);
      }
    }
  }
}

