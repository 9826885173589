.multiContainer {
    height: 100%;
    background-color: var(--color-white);
    --padding-start: 24px;
    --padding-end: 24px;
    --padding-top: 24px;
    --padding-bottom: 24px;

    ion-list {
        & > div:not(:last-of-type) {
            margin-bottom: 12px;
        }
    }
}

.singleContainer {
    height: 100%;
    background-color: var(--color-white);
    padding: 24px;
}
