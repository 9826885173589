@import '../../../../scss/index.scss';

.attendee {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px;

    ion-avatar {
        width: 32px;
        height: 32px;
    }

    .info {
        display: flex;
        flex-direction: column;
        align-items: stretch;

        span:first-of-type {
            @include typography($label-body-3-medium);
            color: var(--color-grayscale-1000);
        }

        span:last-of-type {
            @include typography($label-body-5-regular);
            color: var(--color-grayscale-400);
        }
    }
}