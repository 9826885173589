@import "../../../scss/index.scss";

.content {
  height: 100%;
  .container {
    height: 100%;
    display: flex;
    flex-direction: column;

    .listingsMap {
      position: relative;
      flex: 1;
      display: grid;
      grid-template-columns: min-content;
      grid-template-areas: "listings map";
      grid-template-rows: 1fr;
      overflow: hidden;

      .listings {
        position: relative;
        height: 100%;
        grid-column: 1/2;
        grid-area: "listings";

        transition: flex 0.3s ease;
        z-index: 4;
        overflow: auto;
        transition: flex 0.3s ease;
        @include hide-scrollbar;

        .listingsHeader {
          position: sticky;
          top: 0;
          left: 0;
          background-color: var(--color-white);
          display: flex;
          align-items: center;
          justify-content: space-between;
          z-index: 2;

          .titleSort {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 24px;
          }

          .collapseExpandButton {
            position: absolute;
            top: 15px;
            right: 0;
            button {
              position: fixed;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 2rem;
              padding: 0;
              width: 40px;
              height: 40px;
              border-top-right-radius: 50%;
              border-bottom-right-radius: 50%;
              background-color: var(--color-white);
              border: none;
              cursor: pointer;
              z-index: 2;
            }
          }
        }

        .noListings {
          width: 100%;
          font-size: 14px;
          font-weight: 400;
          color: var(--color-grayscale-400);
          padding: 0 24px;
        }

        .listingsList {
          display: flex;
          flex-direction: column;
          gap: 16px;
          width: 372px;
          padding: 0 24px;

          .loading {
            width: 100%;
          }

          ion-spinner {
            margin: 0 auto;
          }
        }
      }

      .fullSizeListings {
        grid-column: 1/-1;
        background-color: var(--color-grayscale-25);

        .listingsHeader {
          background-color: var(--color-grayscale-25);
          .collapseExpandButton {
            display: none;
          }
        }

        .listingsList {
          width: 100%;
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(336px, 1fr));
          gap: 16px;
          padding-bottom: 24px;
          justify-items: center;
          align-items: center;

          .noListings {
            grid-column: 2/3;
            grid-row: 2/3;
            text-align: center;
            font-size: 15px;
          }

          .loading {
            grid-column: 1/-1;
            grid-row: 1/-1;
          }
        }
      }

      .map {
        grid-column: 2/3;
        grid-area: "map";
        height: 100%;
        position: relative;

        .noClusters {
          position: absolute;
          bottom: 24px;
          left: 50%;
          transform: translateX(-50%);
          z-index: 10;
          font-size: 14px;
          font-weight: 400;
          background-color: var(--color-danger-tint);
          padding: 8px 12px;
          border-radius: 4px;
          color: var(--color-grayscale-500);
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        }
      }
    }

    .listingsCollapsed {
      .listings {
        width: 0;
        padding: 0;

        .listingsHeader {
          .collapseExpandButton {
            right: 0;
            button {
              box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
                rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
            }
          }
        }
      }
    }
  }
}
