@import "../../../scss/index.scss";

.header {
  padding: 24px;
  background-color: var(--color-grayscale-25);

  ion-toolbar {
    --background: none;
    --border-width: 0 !important;
  }

  .title {
    @include typography($label-body-1-semi-bold);
    color: var(--color-grayscale-600);
    padding: 24px 0;
  }
}

.content {
  @include safe-top-margin;
  --background: var(--color-grayscale-25);
}

.container {
  padding: 0 24px;
  height: 100%;

  .avatar {
    display: flex;
    align-items: center;
    gap: 12px;

    .avatarPicture {
      position: relative;

      ion-avatar {
        height: 48px;
        width: 48px;
      }

      ion-icon {
        position: absolute;
        bottom: 0;
        right: 0;
        font-size: 18px;
      }
    }

    .avatarName {
      @include typography($label-body-3-medium);
      color: var(--color-grayscale-100);
    }
  }

  .settings {
    margin-top: 48px;

    ion-accordion-group {
      display: flex;
      flex-direction: column;
      gap: 12px;
      ion-accordion {
        background-color: inherit;
        &:not(:last-of-type) {
          border-bottom: 1px solid var(--color-grayscale-50);
        }
      }
    }

    @media screen and (min-width: $breakpoint-md) {
      display: grid;
      grid-template-columns: 1fr 2fr;
      gap: 12px;

      .list {
        background-color: var(--color-grayscale-25);
        display: flex;
        flex-direction: column;
        gap: 12px;

        .settingsCard {
          cursor: pointer;
          padding: 24px;
          transition: all 0.2s ease;
          border-radius: 8px;
          background-color: var(--color-white);

          &:hover {
            background-color: var(--color-grayscale-1000);
            .cardHeader {
              .settingsTitle {
                color: var(--color-white);
              }
            }
          }
          .cardHeader {
            .settingsTitle {
              @include typography($title-3);
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: space-between;
              ion-icon {
                display: block;
              }
            }
          }
        }

        .selected {
          background-color: var(--color-grayscale-1000);
          .cardHeader {
            .settingsTitle {
              color: var(--color-white);
            }
          }
        }
      }

      .settingsContent {
        padding: 12px;
        background-color: var(--color-white);
        border-radius: 8px;
        display: flex;
        flex-direction: column;
      }
    }

    .accordionHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 12px;
      .title {
        @include typography($label-body-2-semi-medium);
        color: var(--color-grayscale-800);
      }

      ion-icon {
        font-size: 13px;
      }

      @media screen and (min-width: $breakpoint-md) {
        display: none;
      }
    }

    .accordionContent {
      padding: 12px;
      background-color: var(--color-white) !important;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      
      .item {
        display: flex;
        flex-direction: column;
        gap: 4px;
        padding: 12px 0;

        .note {
          @include typography($label-body-5-regular);
          color: var(--color-grayscale-400);
          margin-top: 4px;
        }

        &:not(:last-of-type) {
          border-bottom: 1px solid var(--color-grayscale-50);
        }

        .label {
          @include typography($label-body-5-medium);
          color: var(--color-grayscale-400);
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        .input {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 12px;
          border-radius: 8px;
          background-color: var(--color-grayscale-50);
          @include typography($label-body-3-regular);
          color: var(--color-graysacle-800);

          div:first-of-type {
            flex: 1;
          }

          ion-icon {
            font-size: 18px;
            cursor: pointer;
          }
        }

        .card {
          background-color: var(--color-white);
          padding: 0;
        }
      }

      .select {
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding: 12px 0;
        .label {
          @include typography($label-body-3-semi-bold);
          color: var(--color-grayscale-400);
        }

        .options {
          display: flex;
          flex-direction: column;
          gap: 8px;
        }
      }
    }
  }
}

.sheetContent {
  background-color: var(--color-white);
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 24px;

  .modalHeader {
    position: relative;
    @include typography($style: $label-body-1-semi-bold);
    color: var(--color-grayscale-600);
    text-align: center;

    ion-icon {
      position: absolute;
      right: 12px;
      top: 45%;
      transform: translateY(-50%);
      font-size: 24px;
      color: var(--color-grayscale-600);
      cursor: pointer;
      z-index: 10;
    }
  }

  p {
    @include typography($label-body-3-regular);
    color: var(--color-grayscale-600);
  }

  ion-radio-group {
    display: flex;
    flex-direction: column;
    gap: 8px;
    @include typography($label-body-2-regular);
    color: var(--color-grayscale-600);
  }

  .buttons {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 12px;
    ion-button {
      flex: 1;
      height: 40px;
    }
  }

  .agentCard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
    ion-avatar {
      height: 80px;
      width: 80px;
    }

    .agentName {
      @include typography($label-body-3-medium);
      color: var(--color-grayscale-1000);
    }

    .buttons {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 2px;
    }
  }

  ion-label {
    @include typography($caption-1);
    color: var(--color-grayscale-400);
  }

  ion-list {
    max-height: 300px;
    overflow: auto;
    .item {
      span,
      ion-icon {
        color: var(--color-grayscale-400);
      }
      &:not(:first-of-type) {
        margin-top: 8px;
      }
    }
  }
}

.footer {
  padding: 12px 24px;
  background-color: var(--color-grayscale-25);
  box-shadow: none;
  display: flex;
  align-items: center;
  gap: 12px;

  ion-button {
    flex: 1;
    height: 40px;
  }
}

.popover {
  --padding-start: 12px;
  --padding-end: 12px;
  --padding-top: 12px;
  --padding-bottom: 12px;
}

@media screen and (min-width: $breakpoint-md) {
  .content {
    position: relative;
    --padding-start: 128px;
    --padding-end: 128px;
    --padding-top: 24px;
    --padding-bottom: 24px;

    .title {
      @include typography($headline-h3);
      color: var(--color-grayscale-1000);
      margin-bottom: 48px;
    }

    .avatar {
      .avatarPicture {
        ion-avatar {
          height: 64px;
          width: 64px;
        }

        ion-icon {
          font-size: 24px;
        }
      }

      .avatarName {
        @include typography($label-body-1-semi-bold);
      }

      ion-button {
        margin-left: auto;
        width: 143px;
      }
    }
  }

  .header {
    padding: 0;
  }
}
