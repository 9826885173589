@import "../../../../scss/index.scss";

.stop {
  background-color: var(--color-white);
  display: flex;
  flex-direction: column;
  padding: 8px 12px;
  border-radius: 8px;
  border: 1px solid var(--color-grayscale-25);
  flex: 1;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;

    span {
      @include typography($label-body-5-medium);
      color: var(--color-grayscale-400);
    }
  }

  .body {
    display: flex;
    flex-direction: column;

    div:first-of-type {
      span:first-of-type {
        @include typography($label-body-2-regular);
      }

      span:last-of-type {
        @include typography($label-body-5-regular);
      }
    }
    div:last-of-type {
      @include typography($label-body-5-regular);
    }

    div {
      display: flex;
      justify-content: space-between;
      align-items: center;

      ion-button {
        --color: var(--color-green-shade1) !important;
        @include typography($label-body-3-medium);
        --padding-end: 0;
      }
    }
  }
}
