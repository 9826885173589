@import "../../../scss/index.scss";

.container {
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  box-shadow: 0px 6px 10px 0px #00000014;
  box-shadow: 0px 1px 18px 0px #0000000f;
  box-shadow: 0px 3px 5px 0px #0000001a;
  z-index: 500;

  .filter,
  ion-item {
    flex: 1 1 calc(25% - 10px);
    box-sizing: border-box;
  }

  .filter {

    display: flex;
    align-items: center;
    justify-content: space-between;

    .activeFilters {
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: var(--color-main-mint);
      margin-left: 4px;
    }

    .viewButtons {
      display: flex;
      align-items: center;
      gap: 4px;

      .viewButton {
        background-color: var(--color-grayscale-50);
        color: var(--color-grayscale-1000);
        display: flex;
        align-items: center;
        padding: 8px;
        border-radius: 4px;
        cursor: pointer;

        ion-icon {
          font-size: 18px;
        }
      }

      .activeViewButton {
        background-color: var(--color-grayscale-1000);
        color: var(--color-grayscale-50);
      }
    }
  }
}

.popoverContent {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;

  .item {
    display: flex;
    flex-direction: column;
    gap: 4px;

    ion-label {
      font-size: 14px;
    }

    .label {
      font-weight: 600;
      direction: rtl;
    }
  }

  .inputs {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
  }

  ion-button {
    margin: 12px 0;
  }
}

.searchResultsPopover {
  --offset-y: 5px;
  --width: auto;
}

.searchResultsPopoverContent {
  padding: 8px;

  .searchResultsHeader {
    font-size: 15px;
    font-weight: 500;
    color: var(--color-grayscale-1000);
    margin-bottom: 4px;
  }
  
  .searchResultsList {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
}