@import "../../../../scss/index.scss";

.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  @include typography($style: $label-body-3-medium);
  color: var(--color-grayscale-600);

  table {
    border-collapse: collapse;
    tbody {
      background-color: var(--color-grayscale-25);
      border: 1px solid var(--color-grayscale-50);
      border-radius: 4px;
      tr:not(:last-of-type) {
        border-bottom: 1px solid var(--color-grayscale-50);
      }

      td {
        text-align: center;
        &:not(:last-of-type) {
          border-right: 1px solid var(--color-grayscale-50);
        }
      }

      .slot {
        color: var(--color-white);
        cursor: pointer;
        font-size: 12px;
        font-weight: 300;

        &:hover {
          background-color: var(--color-green-tint3);
        }
      }

      .selectedSlot {
        background-color: var(--color-green-shade1);
        border-right: none !important;
      }
    }
  }

  .selectedSlots {
    label {
      @include typography($style: $label-body-3-medium);
      color: var(--color-grayscale-600);
    }

    .slots {
      display: flex;
      flex-direction: column;
      gap: 12px;

      .slot {
        display: flex;
        width: max-content;
        align-items: center;
        gap: 12px;
        padding: 5px 8px;
        border: 1px solid var(--color-grayscale-200);
        border-radius: 8px;

        ion-icon {
            cursor: pointer;
        }
      }
    }
  }
}
