.picker-button.sc-ion-picker-ios,
.picker-button.ion-activated.sc-ion-picker-ios {
  color: #363636 !important;
}

.picker-button.sc-ion-picker-md,
.picker-button.ion-activated.sc-ion-picker-md {
  color: #363636 !important;
}

.picker-prefix,
.picker-suffix,
.picker-opt.picker-opt-selected {
  color: #363636 !important;
}

// change the color of radio buttons insid the alerts
.alert-radio-inner.sc-ion-alert-md {
  background-color: #363636 !important;
}
[aria-checked="true"].sc-ion-alert-md .alert-radio-icon.sc-ion-alert-md {
  border-color: #363636 !important;
}
[aria-checked="true"].sc-ion-alert-ios .alert-radio-label.sc-ion-alert-ios {
  color: #363636 !important;
}
[aria-checked="true"].sc-ion-alert-ios .alert-radio-inner.sc-ion-alert-ios {
  border-color: #363636 !important;
}

ion-toast::part(button) {
  font-size: 1.2rem;
}
