@import "../../../../scss/index.scss";

.errorToast {
  --background: #ff5500;
  --color: white;
  font-size: 1.2rem;
  --button-color: white;
}

.successToast {
  --background: #1f7a60;
  --color: white;
  font-size: 1.2rem;
  --button-color: white;
}

.modal {
  .modalContent {
    background-color: white;
    position: relative;
    top: 40%;
    width: 90%;
    margin: 0 auto;
  }
  .title {
    display: flex;
    justify-content: center;
    padding: 1rem;
    font-size: 1.8rem;
    font-weight: 500;
  }
  .subtitle {
    font-size: 1.5rem;
    justify-content: center;
    text-align: center;
    padding: 1rem 0.5rem;
    color: $grey-dark-1;
  }
  ion-item {
    width: 80%;
    margin: 0 auto;
    --color: #363636;
  }
  .buttons {
    display: flex;
    justify-content: space-around;
    margin-top: 1rem;

    ion-button {
      --color: #1f7a60;
    }
  }
}

.popover {
  .title {
    display: flex;
    justify-content: center;
    padding: 1rem;
    font-size: 1.8rem;
    font-weight: 500;
  }
  .subtitle {
    font-size: 1.5rem;
    justify-content: center;
    text-align: center;
    padding: 1rem 0.5rem;
    color: $grey-dark-1;
  }
  .label {
    font-size: 1.5rem;
    font-weight: 500;
    padding: 1rem 2rem 0rem;
  }

  ion-select {
    font-size: 1.5rem;
    padding: 1rem 5rem 1rem 2rem;
    --placeholder-color: #363636;
  }
  .buttons {
    display: flex;
    justify-content: space-around;
    margin-top: 1rem;

    ion-button {
      --color: #1f7a60;
    }
  }
}
