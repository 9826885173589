@import "../../../../../../scss/index.scss";

.container {
    .loadingContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 12px;
    }

    .schoolsContainer {
        display: flex;
        flex-direction: column;

        .header {
            display: flex;
            flex-direction: column;

            ion-item {
                margin: 12px 0;
            }
    
            p {
                @include typography($style: $label-body-3-regular);
    
                ion-router-link {
                    text-decoration: underline;
                }
            }
        }

        .name {
            text-decoration: underline;
            cursor: pointer;
            @include typography($style: $label-body-4-regular);
            ion-router-link {
                color: var(--color-grayscale-1000);
            }
        }

        .grades  {
            @include typography($style: $label-body-3-light);
        }

        .programs {
            display: flex;
            flex-direction: column;
            @include typography($style: $label-body-3-light);

            span:first-of-type {
                @include typography($style: $label-body-4-regular);
                margin-bottom: 8px;
            }
        }

        .rating {
            @include typography($style: $label-body-3-light);
            & span:first-of-type {
                color: var(--color-success-normal);
            }
        }

        .boundary {
            @include typography($style: $caption-1);
        }
    }
}

@media screen and (min-width: $breakpoint-md) {
    .container {
        .schoolsContainer {
            display: grid;
            grid-template-columns: 1fr 1fr;
            column-gap: 24px;
            row-gap: 12px;

            .header {
                grid-column: 1 / -1;
                display: grid;
                grid-template-columns: repeat(4, 1fr);
                column-gap: 24px;

                ion-item {
                    grid-column: 1 / 2;
                }

                p {
                    grid-column: 2 / -1;
                    @include typography($style: $label-body-1-regular);
                }
            }

            .name {
                @include typography($style: $label-body-2-regular);
            }

            .grades  {
                @include typography($style: $label-body-2-regular);
            }

            .programs {
                @include typography($style: $label-body-2-regular);
                span:first-of-type {
                    @include typography($style: $label-body-1-light);
                }
            }

            .rating {
                @include typography($style: $label-body-1-light);
            }

            .boundary {
                @include typography($style: $label-body-5-regular);
            }
        }
    }
}