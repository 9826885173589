@import "../../../scss/index.scss";
.container {
  background-color: #000;

  .top {
    display: flex;
    flex-direction: column;
    background-image: url("../../../assets/img/landing_header_bg.png");
    background-size: cover;
    background-position: center;
    height: calc(100vh - 54px);

    .search {
      flex: 1;
      padding: 0 24px;
      align-self: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 16px;
      @media screen and (min-width: $breakpoint-md) {
        width: 50%;
      }

      .title {
        padding: 12px 24px;
        text-align: center;
        @include typography($style: $headline-h3);
        color: var(--color-white);
        span {
          color: var(--color-main-mint);
        }
      }

      .input {
        cursor: pointer;
        background-color: var(--color-white);
        padding: 12px 24px;
        border-radius: 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        color: var(--color-grayscale-600);
        box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.08),
          0px 1px 18px 0px rgba(0, 0, 0, 0.06),
          0px 3px 5px 0px rgba(0, 0, 0, 0.1);
        ion-icon {
          font-size: 24px;
        }
      }
    }
  }

  .middle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: var(--color-grayscale-25);

    section {
      padding: 48px 24px;
      flex: 1;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 24px;

      @media screen and (min-width: $breakpoint-md) {
        padding: 48px 128px;
      }

      .swiper {
        flex: 1;
        width: 100%;
        height: 100%;

        .swiperSlide {
          width: auto;
          padding: 8px;
        }
      }

      .title {
        @include typography($style: $title-1);
        color: var(--color-grayscale-1000);
        text-align: center;

        @media screen and (min-width: $breakpoint-md) {
          @include typography($style: $headline-h2);
        }
      }

      .text {
        @include typography($style: $title-3);
        text-align: center;
      }

      &:first-of-type {
        padding-top: 96px;
      }
    }

    .listings {
      background-color: #fff;
    }

    .about {
      align-self: center;
      @media screen and (min-width: $breakpoint-md) {
        width: 70%;

        ion-button {
          margin: 0 auto;
          width: fit-content;
        }
      }
    }

    .services {
      .cards {
        display: flex;
        flex-direction: column;
        gap: 12px;

        @media screen and (min-width: $breakpoint-md) {
          flex-direction: row;
          justify-content: center;
          gap: 24px;
        }

        .card {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 16px;
          background-color: var(--color-white);
          padding: 24px;
          border-radius: 8px;
          box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.08),
            0px 1px 18px 0px rgba(0, 0, 0, 0.06),
            0px 3px 5px 0px rgba(0, 0, 0, 0.1);

          ion-icon {
            padding: 12px 0;
            font-size: 180px;
          }

          .title {
            @include typography($style: $title-2);
          }

          .text {
            @include typography($style: $label-body-1-regular);
            color: var(--color-grayscale-600);
          }

          ion-button {
            width: 80%;
          }

          @media screen and (min-width: $breakpoint-md) {
            width: 342px;
          }
        }
      }
    }

    .featureImage {
      width: 100%;
    }

    .app {
      background-color: var(--color-white);

      .content {
        display: grid;
        grid-template-columns: 1fr;
        gap: 48px;

        .info {
          display: flex;
          flex-direction: column;
          gap: 12px;

          .subtitle {
            @include typography($style: $title-2);
            color: var(--color-grayscale-1000);
          }

          .text {
            @include typography($style: $label-body-1-regular);
            color: var(--color-grayscale-600);
            text-align: left;
          }

          .buttons {
            display: flex;
            align-items: center;
            gap: 16px;
          }
        }

        @media screen and (min-width: $breakpoint-md) {
          grid-template-columns: 1fr 1fr;
          gap: 24px;

          .info {
            justify-content: center;
          }
        }
      }
    }

    .feature {
      background-color: var(--color-white);
      display: grid;
      grid-template-columns: 1fr;
      gap: 24px;

      .content {
        display: flex;
        flex-direction: column;
        gap: 12px;

        .subtitle {
          @include typography($style: $title-2);
          color: var(--color-grayscale-1000);
        }

        .text {
          @include typography($style: $label-body-1-regular);
          color: var(--color-grayscale-600);
          text-align: left;
        }

        .tag {
          padding: 8px 16px;
          background-color: var(--color-green-shade1);
          color: var(--color-white);
          border-radius: 8px;
          width: fit-content;
          @include typography($style: $label-body-5-medium);
        }
      }

      @media screen and (min-width: $breakpoint-md) {
        grid-template-columns: 1fr 1fr;
        gap: 128px;

        .content {
          margin-top: 48px;

          .subtitle {
            @include typography($style: $headline-h2);
          }
          .text {
            @include typography($style: $title-2);
            font-weight: 400;
          }
          .tag {
            @include typography($style: $label-body-3-medium);
          }
        }
      }
    }

    .reversed {
      @media screen and (min-width: $breakpoint-md) {
        .content {
          order: 2;
        }
        .featureImage {
          order: 1;
        }
      }
    }
  }

  footer {
    padding: 12px 24px;
    border-top: 1px solid var(--color-grayscale-600);
    color: var(--color-white);
    display: flex;
    flex-direction: column;
    gap: 24px;

    @media screen and (min-width: $breakpoint-md) {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
    }

    & > div {
      display: flex;
      flex-direction: column;
      gap: 8px;

      @media screen and (min-width: $breakpoint-md) {
       padding: 12px;
      }

      img {
        align-self: self-start;
      }

      p {
        @include typography($style: $label-body-5-medium);
      }

      .title {
        @include typography($style: $label-body-2-regular);
      }

      a {
        color: var(--color-white);
        text-decoration: none;
        @include typography($style: $label-body-3-medium);
      }

      .social {
        display: flex;
        align-items: center;
        gap: 16px;
        @include typography($style: $caption-1);
        .icons {
          display: flex;
          gap: 16px;
          ion-icon {
            cursor: pointer;
            font-size: 24px;
            color: var(--color-main-mint);
          }
        }
      }

      .contacts {
        display: flex;
        flex-direction: column;
        gap: 8px;

        span {
          display: flex;
          align-items: center;
          gap: 8px;
          ion-icon {
            font-size: 24px;
            color: var(--color-main-mint);
          }
        }
      }

      .links {
        display: flex;
        justify-content: space-between;
        @include typography($style: $label-body-3-medium);
        color: var(--color-white) !important;
        text-decoration: none !important;
      }

      .copy {
        text-align: center;
        @include typography($style: $label-body-5-medium);
        color: var(--color-grayscale-400);
      }
    }

    .app {
      border-top: 1px solid var(--color-grayscale-600);
      border-bottom: 1px solid var(--color-grayscale-600);
      align-items: center;
      padding: 24px 0;

      .buttons {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }

      @media screen and (min-width: $breakpoint-md) {
        padding: 12px;
        grid-column: 1/-1;

        .buttons {
          flex-direction: row;
        }
      }
    }

    .legal {
      @media screen and (min-width: $breakpoint-md) {
        padding: 0;
        grid-column: 1/-1;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .links {
          gap: 24px;
        }
      }
    }
  }
}
