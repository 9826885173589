@import "../../../scss/index.scss";

.container {
  flex: 1;
  height: 100%;
  width: 100%;

  .loadingContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;

    .logoContainer {
      position: relative;
      padding: 48px;
      svg {
        width: 100px;
        height: 100px;
      }
    }

    .spinner {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      display: inline-block;
      border-top: 8px solid var(--color-main-mint);
      border-right: 8px solid transparent;
      box-sizing: border-box;
      animation: rotation 1s linear infinite;
    }

    span {
      @include typography($style: $label-body-3-light);
      text-align: center;

      @media screen and (min-width: $breakpoint-md) {
        @include typography($style: $label-body-1-light);
      }
    }
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
