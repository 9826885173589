@import '../../../../../../scss/index.scss';

.container {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .regions {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }
}