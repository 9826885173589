@import '../../../../../../../scss/index.scss';

.container {
    display: flex;
    flex-direction: column;
    width: 100%;

    .item {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 8px;

        .inputs {
            display: flex;
            align-items: center;
            gap: 8px;
        }

        ion-range {
            padding-right: 8px;
        }
    }

    .payment {
        margin: 8px 0;
        display: flex;
        align-items: center;
        gap: 4px;
        padding: 16px 12px;
        border-radius: 8px;
        background-color: var(--color-green-tint3);
        @include typography($style: $label-body-4-regular);

        span:first-of-type {
            color: var(--color-grayscale-1000);
        }

        span:last-of-type {
            color: var(--color-green-shade1);
        }
    }

    p {
        padding: 0 12px;
        color: var(--color-grayscale-600);
        @include typography($style: $label-body-5-regular);
    }
}

@media  screen and (min-width: $breakpoint-md) {
    .container {

        .item {
            margin: 12px 0;
            & > div {
                background-color: var(--color-white);
                display: grid;
                grid-template-columns: repeat(7, 1fr);
                column-gap: 8px;
                padding: 12px;
                border-radius: 12px;

                ion-range {
                    grid-row: 1;
                    grid-column: 1 / 6;
                }

                .inputs, ion-input {
                    grid-row: 1;
                    grid-column: 6 / 8;
                }
            }

            &:first-of-type {
                margin-top: 0;
            }
        }

        .payment {
            @include typography($style: $label-body-1-semi-bold);
        }

        p {
            @include typography($style: $label-body-1-light);
        }
    }
    
}