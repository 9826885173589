@import "../../../../../scss/index.scss";

.container {
  ion-label {
    @include typography($label-body-5-medium);
    color: var(--color-graysacle-800);
  }

  .row {
    display: flex;
    gap: 8px;

    .card {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 12px;
      border-radius: 8px;
      background-color: var(--color-white);
      @include typography($label-body-3-regular);
      color: var(--color-graysacle-800);

      div:first-of-type {
        flex: 1;
      }

      ion-icon {
        font-size: 18px;
        cursor: pointer;
      }
    }

    .emptyCard {
      background-color: transparent;
      border: 1px solid var(--color-grayscale-200);
      color: var(--color-grayscale-200);
    }
  }
}

.sheetContent {
  background-color: var(--color-white);
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 24px;
  text-align: center;

  .input {
    display: flex;
    flex-direction: column;
    gap: 4px;
    text-align: left;

    ion-label {
      @include typography($caption-1);
      color: var(--color-graysacle-400);
    }

    ion-datetime-button {
      width: 100%;
      &::part(native) {
        width: 100%;
        @include typography($style: $label-body-4-regular);
        text-align: left;
        height: 44px;
      }
    }
  }
}

@media screen and (min-width: $breakpoint-md) {
  .container {
    padding: 12px;
    border: 1px solid var(--color-grayscale-200);
    border-radius: 8px;

    .row {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
    }
  }

  .sheetContent {
    ion-datetime {
      --background: var(--color-grayscale-25) !important;
      --background-rgb: var(--color-grayscale-25) !important;
      --wheel-fade-background-rgb: var(--color-grayscale-25) !important;
      --wheel-highlight-background: var(--color-grayscale-25) !important;
    }
  }
}
