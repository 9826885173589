@import '../../../../scss/index.scss';

.container {
  @include safe-top-margin;
  position: absolute;
  top: 16px;
  left: 16px;
  width: calc(100% - 88px);
  z-index: 10;

  ion-item {
    --background: var(--color-grayscale-50) !important;
    --border-radius: 8px;
    box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.08);
    box-shadow: 0px 1px 18px 0px rgba(0, 0, 0, 0.06);
    box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.1);
    --color: var(--color-grayscale-500) !important;
  }
}
