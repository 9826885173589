@import "../../../../scss/index.scss";

.header {
  position: relative;
  background-color: #4f4f4fcc;
  padding: 48px 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  .logo {
    width: 100px;
    flex: 1;
  }

  ion-icon {
    font-size: 24px;
    color: var(--color-white);
    cursor: pointer;
    position: absolute;
    top: 18px;
    left: 14px;
  }
}

.content {
  --background: #4f4f4fcc;
  --padding-top: 0;
  --padding-bottom: 48px;
  --padding-start: 24px;
  --padding-end: 24px;

  .container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    color: var(--color-white);

    .error {
      display: flex;
      align-items: center;
      gap: 12px;
      @include typography($style: $label-body-5-regular);
      color: var(--color-white);
      background-color: var(--color-danger-normal);
      padding: 12px;
      border-radius: 4px;
      margin-bottom: 12px;

      ion-icon {
        font-size: 18px;

        &:last-of-type {
          cursor: pointer;
          margin-left: auto;
        }
      }
    }

    .title {
      @include typography($style: $title-2);
    }

    .socialButtons {
      @include typography($style: $label-body-5-medium);
      display: flex;
      flex-direction: column;
      gap: 10px;

      .socialButton {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 4px;
        border: 1px solid var(--color-white);
        border-radius: 4px;
        padding: 8px 16px;
        cursor: pointer;

        ion-icon {
          font-size: 24px;
        }
      }
    }

    .separator {
      display: flex;
      align-items: center;
      text-wrap: nowrap;
      @include typography($style: $label-body-5-medium);
      span {
        padding: 0 8px;
      }
      &::after {
        content: "";
        display: block;
        width: 100%;
        height: 1px;
        background-color: #ffffff;
      }
      &::before {
        content: "";
        display: block;
        width: 100%;
        height: 1px;
        background-color: #ffffff;
      }
    }


    p {
      @include typography($style: $label-body-3-light);
      color: var(--color-grayscale-200);
      margin: 0;
      margin-bottom: 24px;
    }

    .form {
      display: flex;
      flex-direction: column;
      gap: 24px;

      .formItem {
        display: flex;
        flex-direction: column;
        gap: 4px;
        ion-label {
          @include typography($style: $label-body-5-medium);
          color: var(--color-white);
        }

        ion-note {
          @include typography($style: $label-body-5-regular);
          color: var(--color-danger-normal);
        }

        ion-icon {
          font-size: 18px;
        }

        ion-input {
          ion-icon {
            cursor: pointer;
          }
        }
      }


      .forgotPassword {
        margin-top: 4px;
        cursor: pointer;
        color: var(--color-main-mint);
        @include typography($style: $label-body-4-underlined-regular);
      }

      .switch {
        @include typography($style: $label-body-4-regular);

        span {
          cursor: pointer;
          color: var(--color-main-mint);
          @include typography($style: $label-body-4-underlined-regular);
        }
      }

      .resendCode {
        @include typography($style: $label-body-4-regular);
        color: var(--color-main-mint);
        cursor: pointer;
      }

      ion-button {
        height: 40px;
      }
    }
  }
}

@media screen and (min-width: $breakpoint-md) {

  .header {
    .logo {
     width: 50%;
     flex: none;
    }
  }

  .content {
    --padding-top: 48px;
    --padding-bottom: 48px;
    --padding-start: 48px;
    --padding-end: 48px;

    @include hide-scrollbar;

    .container {
      .socialButtons {
        flex-direction: row;
        justify-content: space-between;
      }
    }
  }
  
}
