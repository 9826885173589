@import '../../../../../scss/index.scss';

.container {
    @include safe-top-margin;
    position: absolute;
    top: 16px;
    right: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    z-index: 3;
    
    svg {
        width: 28px;
        height: 28px;
        stroke: var(--color-grayscale-1000);
    }
}