@import "./scss/index.scss";

:focus {
  outline: -webkit-focus-ring-color auto 0px;
}


// .alert-wrapper {
//   --button-color: #971e49 !important;
//   background: white !important;
//   --button-color-hover: #79193b !important;
// }



// .alert-button {
//   color: $grey-dark-2 !important;
// }
// .select-interface-option {
//   .alert-radio-label {
//     color: $grey-dark-2 !important;
//   }
// }
// .alert-input.sc-ion-alert-ios {
//   margin-bottom: 1.2rem;
// }
// alert-message.sc-ion-alert-ios,
// .alert-input-group.sc-ion-alert-ios {
//   padding-bottom: 0.75rem !important;
// }
// .select-interface-option[aria-checked="true"] {
//   .alert-radio-icon {
//     border-color: $grey-dark-2 !important;
//   }

//   .alert-radio-inner {
//     border-color: $grey-dark-2 !important;
//     background-color: $grey-dark-2 !important;
//   }
//   .sc-ion-alert-ios {
//     background-color: transparent !important;
//   }
//   .alert-radio-label {
//     color: $grey-dark-2 !important;
//     font-weight: 600 !important;
//   }
// }

ion-tab-bar {
  background-color: $grey-dark-2;
}

ion-range::part(knob) {
  z-index: unset !important;
}

[aria-checked="true"].sc-ion-alert-ios .alert-checkbox-icon.sc-ion-alert-ios {
  background-color: #363636 !important;
  border-color: #363636 !important;
}
[aria-checked="true"].sc-ion-alert-md .alert-checkbox-icon.sc-ion-alert-md {
  background-color: #363636 !important;
  border-color: #363636 !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  box-shadow: 0 0 0px 1000px #ffffff inset !important;
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
}

// .tourItem {
//   --border-width: 0 0 0.1rem 0;
// }

.connection {
  display: none;
  --background: #ff5500;
  --color: white;
  font-size: 1.1rem;
  --button-color: white;
}

// .alert {
//   border-radius: 15px !important;
// }

// @media only screen and (min-width: $medium) {
//   .alert-button-group.sc-ion-alert-md {
//     justify-content: center !important;
//   }
//   .alert-wrapper {
//     border-radius: 15px !important;
//   }
// }

.fullScreenModal {
  --height: 100%;
  --width: 100%;
}
