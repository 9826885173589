@import '../../../scss/index.scss';

.container {
  position: relative;
  width: 100%;
  height: calc(100% + 8px);

  .map {
    height: 100%;
    width: 100%;
  }

  .listings {
    @include safe-top-margin;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 12px 24px;
    overflow: hidden;

    .listingsHeader {
      display: flex;
      flex-direction: column;
      gap: 4px;

      .item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 12px;

        .search,
        .sort {
          flex: 1;
        }

        ion-button {
          --padding-start: 0;
          --padding-end: 0;
        }

        ion-icon {
          font-size: 24px;
        }
      }
    }

    .listingsList {
      flex: 1;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding-top: 12px;
      margin-bottom: constant(safe-area-inset-top);
      margin-bottom: env(safe-area-inset-top);

      .noListings {
        width: 100%;
        margin-top: 50%;
        font-size: 14px;
        font-weight: 400;
        text-align: center;
        color: var(--color-grayscale-400);
      }
    }

    ion-spinner {
      margin: 0 auto;
      height: 36px;
      width: 36px;
    }
  }

  .hidden {
    display: none;
  }
}

.noOverflow {
  height: 100%;
}
