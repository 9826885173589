@import "../../../../scss/index.scss";

.content {
  @include hide-scrollbar;
  padding: 0 28px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 12px;

  .success {
    color: var(--color-grayscale-600);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px;
    gap: 12px;

    ion-icon {
      font-size: 72px;
      color: var(--color-green-shade2);
    }

    span {
      @include typography($style: $label-body-1-semi-bold);
    }
  }

  .form {
    display: flex;
    flex-direction: column;
    gap: 12px;

    label {
      @include typography($style: $label-body-3-medium);
      color: var(--color-grayscale-600);
    }

    .input {
      display: flex;
      align-items: center;
      gap: 12px;
    }

    .guest {
      @include typography($style: $label-body-3-medium);
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 5px 8px;
      border: 1px solid var(--color-grayscale-200);
      border-radius: 8px;

      ion-icon {
        cursor: pointer;
      }
    }
  }

  .note {
    @include typography($style: $label-body-3-regular);
    color: var(--color-grayscale-600);
  }
}