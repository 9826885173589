@import '../../../../../scss/index.scss';

.container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    background-color: var(--color-white);
    border-radius: 8px;
    padding: 16px 12px;
    min-height: 240px;

    ion-spinner {
        margin: auto;
    }

    @media screen and (min-width: $breakpoint-md) {
        padding: 24px 128px;
        gap: 48px;
    }
}