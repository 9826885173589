.container {
  display: flex;
  align-items: center;
  gap: 16px;
  cursor: pointer;
  color: var(--color-grayscale-500);
  background-color: var(--color-grayscale-10);
  border-radius: 8px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;

  .select {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  svg {
    width: 16px;
    height: 16px;
  }

  .icon {
    transition: all 0.2s;
  }

  .reversedIcon {
    transform: rotate(180deg);
  }
}

.options {
  ion-item {
    font-size: 14px;
    --color: var(--color-black) !important;
  }
}

.expand {
  width: 100%;
  background-color: var(--color-grayscale-40);
}