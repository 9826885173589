@import "../../../scss/index.scss";

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin: 24px 0;
  ion-icon {
    font-size: 64px;
    color: var(--color-grayscale-1000);
    margin-bottom: 12px;
  }

  p {
    @include typography($style: $label-body-4-regular);
    color: var(--color-grayscale-1000);
    text-align: center;

    span {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

.small {
  margin: 0;
  ion-icon {
    font-size: 28px;
    margin-bottom: 0;
  }
}
