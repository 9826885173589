@import "./variables.scss";
@import "./utils.scss";

* {
  font-family: $base-font-family !important;
  box-sizing: border-box;
  caret-color: $grey-dark-2;

  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  &:focus {
    outline: none;
  }
}

html {
  height: 100%;
  font-size: $base-font-size !important;
  font-family: $base-font-family;
}

body {
  @include safe-top-padding;
  font-size: 1.8rem !important;
  font-weight: 400;
  ::selection {
    background: var(--color-green-tint3);
    color: var(--color-green-shade1);
  }
}

a {
  text-decoration: none;
  color: black;
}

h1 {
  font-size: 3rem;
  font-weight: 600;
}

h2 {
  font-size: 2.6rem;
  font-weight: 500;
}

h3 {
  font-size: 2.2rem;
  font-weight: 500;
}

h4 {
  font-size: 1.8rem;
  font-weight: 500;
}

h5 {
  font-size: 1.5rem;
  font-weight: 500;
}

h6 {
  font-size: 1.2rem;
  font-weight: 500;
}
.gmnoprint a,
.gmnoprint span {
  display: none;
}
.gmnoprint div {
  background: none !important;
}

ion-item, ion-button, ion-input, ion-searchbar {
  font-size: 1.4rem;
}

ion-range {
  --bar-background: var(--color-grayscale-50);
  --bar-background-active: var(--color-green-shade2);
  --knob-background: var(--color-green-shade2);
  --knob-size: 24px;
  --bar-height: 4px;
  --bar-border-radius: 2px;

  &::part(knob) {
    border: 2px solid var(--color-white);
  }
}


// ion-item {
//   --background-activated: #dbdbdb !important;
//   --background-focused: #dbdbdb !important;
//   --highlight-color-focused: #dbdbdb !important;
//   --highlight-color-valid: #dbdbdb !important;
//   --ripple-color: #dbdbdb !important;
//   --background-hover: #dbdbdb !important;
// }

// ion-item::part(detail-icon) {
//   font-size: 2rem;
//   color: $grey-dark-2;
//   opacity: 1;
//   z-index: 100;
// }

// ion-item {
//   --color: #9c9c9c !important;
//   --color-focused: #9c9c9c !important;
//   --color-hover: #9c9c9c !important;
//   --highlight-color-focused: #9c9c9c !important;
//   --ripple-color: #6e6e6e !important;
//   --border-color: #dbdbdb !important;
// }

.error {
  --background: #ff5500;
  --color: white;
  font-size: 1.2rem;
  --button-color: white;
}
.success {
  --background: #1f7a60;
  --color: white;
  font-size: 1.2rem;
  --button-color: white;
}

ion-back-button {
  --ripple-color: #dbdbdb !important;
}


