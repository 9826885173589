@import "../scss/index.scss";

.app {
  &:focus {
    outline: -webkit-focus-ring-color auto 0px;
  }
}

.web {
  &:focus {
    outline: -webkit-focus-ring-color auto 0px;
  }
}

.tabs-inner {
  flex: 1;
  position: unset !important;
  background-color: transparent !important;
  background: none !important;
}

.tabBar {
  background-color: black;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border: unset;
  height: 64px;
}

ion-tab-button {
  position: relative;
  --background: $grey-dark-2;
  --color: var(--color-white);
  --color-selected: var(--color-main-mint);
  --padding-top: 8px;
  --ripple-color: var(--color-white);
  --background-focused-opacity: 0;
  --overflow: visible;

  &::part(native) {
    background-color: black;
  }

  &:first-of-type::part(native) {
    border-top-left-radius: 15px;
  }

  &:last-of-type::part(native) {
    border-top-right-radius: 15px;
  }

  ion-icon {
    font-size: 26px;
    stroke-width: 1px;
  }

  ion-label {
    font-size: 10px;
    font-weight: 500;

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 2px;
      background-color: var(--color-main-mint);
      transition: width 0.3s;
    }
  }
}

.active {
  stroke: var(--color-main-mint) !important;
}

.agent {
  background-color: var(--color-main-mint);

  ion-tab-button {
    --color-selected: var(--color-grayscale-800);
    &::part(native){
      background-color: var(--color-main-mint);
    }
  }

  .active {
    stroke: var(--color-grayscale-800) !important;
  }

  ion-label {
    font-size: 10px;
    font-weight: 500;

    &::after {
      background-color: var(--color-grayscale-800);
    }
  }
}

.error {
  --background: var(--color-danger-normal);
  --color: var(--color-white);
  font-size: 14px;
  --button-color: var(--color-white);
}

.success {
  --background: var(--color-success-normal);
  --color: var(--color-white);
  font-size: 14px;
  --button-color: var(--color-white);
}

.notification {
  --background: var(--color-grayscale-500);
  --color: var(--color-white);
  font-size: 14px;
  --button-color: var(--color-white);
}

.connectionContent {
  display: flex;
  flex-direction: column;
  margin-top: 6rem;
  justify-content: center;
  align-items: center;

  font-size: 1.5rem;
  text-align: center;
  color: #363636;

  .svg {
    width: 30rem;
    height: 30rem;
  }
  .title {
    font-size: 2.2rem;
    color: $grey-dark-2;
    font-weight: 600;
    margin-bottom: 2rem;
  }

  .subtitle {
    font-size: 1.5rem;
    text-align: center;
    color: $grey-dark-1;
    padding: 0 1rem;
    &:last-of-type {
      margin-bottom: 2rem;
      margin-top: 1rem;
    }
  }
}

.notificationBadge {
  --color: var(--color-grayscale-500);
  --background: var(--color-warning-normal);;
}
