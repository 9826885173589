@import '../../../scss/index.scss';

.container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 153px;
    width: 100%;
    padding: 36px 48px;
    color: var(--color-white);


    div {
        flex: 1;
        display: flex;
        flex-direction: column;

        span {
            @include typography($style: $label-body-2-regular);

            &:first-of-type {
                font-weight: 600;
                color: var(--color-main-mint);
            }
        }

        &:last-of-type {
            justify-self: flex-start;
        }
    }

    a {
        color: var(--color-main-mint);
        text-decoration: none;
    }
}