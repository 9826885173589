@import "../../../../../scss/index.scss";

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 12px;

  .noSearches {
    padding-top: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    text-align: center;
    margin: 0 auto;
    color: var(--color-grayscale-200);
    @include typography($label-body-2-semi-medium);

    ion-icon {
      font-size: 80px;
    }

    @media screen and (min-width: $breakpoint-md) {
      @include typography($title-3);

      ion-icon {
        font-size: 100px;
      }
    }
  }

  .note {
    @include typography($label-body-3-regular);
    color: var(--color-grayscale-600);

    @media screen and (min-width: $breakpoint-md) {
      @include typography($label-body-1-regular);
    }
  }

  .searches {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .card {
      background-color: var(--color-white);
      padding: 8px 12px;
      border-radius: 8px;
      display: flex;
      flex-direction: column;

      .cardHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .title {
          @include typography($label-body-1-semi-bold);
          color: var(--color-grayscale-600);

          ion-icon {
            display: none;
          }
        }

        ion-button {
          margin: 0;
          padding: 0;
          --padding-top: 0;
          --padding-bottom: 0;
        }
      }

      .searchCardHeader {
        display: flex;
        gap: 24px;
        align-self: flex-end;
        width: 50%;
        ion-button {
          flex: 1;
        }
      }

      .summary {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 24px;
        margin-top: 24px;

        .filters {
          display: flex;
          flex-direction: column;
          gap: 24px;

          div:first-of-type {
            @include typography($label-body-2-regular);
            color: var(--color-grayscale-600);
          }
        }

        .alerts {
          display: flex;
          flex-direction: column;
          gap: 24px;

          div:first-of-type {
            display: flex;
            align-items: center;
            gap: 8px;
            @include typography($label-body-3-medium);
            color: var(--color-grayscale-600);

            ion-icon {
              font-size: 24px;
            }
          }

          div:last-of-type {
            display: flex;
            flex-direction: column;
            gap: 2px;
            @include typography($label-body-3-light);
            color: var(--color-grayscale-400);

            span:first-of-type {
              text-transform: capitalize;
            }
          }
        }
      }
    }

    .listings {
      margin-top: 24px;
      display: flex;
      flex-direction: column;
      gap: 24px;

      .listingsHeader {
        div:first-of-type {
          display: flex;
          align-items: center;
          justify-content: space-between;

          span {
            @include typography($label-body-1-semi-bold);
            color: var(--color-grayscale-600);
          }
        }
        div:last-of-type {
          @include typography($label-body-3-regular);
          color: var(--color-grayscale-400);
        }

        ion-button {
          height: 32px;
          width: 32px;
          --padding-start: 0;
          --padding-end: 0;

          ion-icon {
            width: 70%;
            height: 70%;
          }
        }

        @media screen and (min-width: $breakpoint-md) {
          display: flex;
          align-items: center;
          justify-content: space-between;

          span {
            @include typography($label-body-2-regular);
            color: var(--color-grayscale-600);
          }
        }
      }

      .listingsList {
        .loading {
          display: flex;
          justify-content: center;
        }

        .noListings {
          text-align: center;
          @include typography($label-body-3-regular);
          color: var(--color-grayscale-600);
        }
      }

      .items {
        padding: 12px;
        display: flex;
        flex-direction: column;
        gap: 16px;

        ion-infinite-scroll {
          grid-column: 1/3;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        @media screen and (min-width: $breakpoint-md) {
          display: grid;
          grid-template-columns: 1fr 1fr;
          column-gap: 32px;
        }
      }
    }

    .searchCard {
      padding: 12px !important;
    }

    @media screen and (min-width: $breakpoint-md) {
      display: grid;
      grid-template-columns: 1fr 2fr;
      gap: 12px;

      .list {
        height: fit-content;
        z-index: 100;
        background-color: var(--color-grayscale-25);
        position: sticky;
        top: 108px;
        left: 0;
        display: flex;
        flex-direction: column;
        gap: 24px;

        .card {
          cursor: pointer;
          padding: 12px;
          transition: all 0.2s ease;

          &:hover {
            background-color: var(--color-grayscale-1000);
            .cardHeader {
              .title {
                color: var(--color-white);
              }
            }
          }
          .cardHeader {
            .title {
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: space-between;
              ion-icon {
                display: block;
              }
            }
            ion-button {
              display: none;
            }
          }

          ion-button {
            display: none;
          }
        }

        .selected {
          background-color: var(--color-grayscale-1000);
          .cardHeader {
            .title {
              color: var(--color-white);
            }
          }
        }
      }
    }
  }
}
