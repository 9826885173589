@import "../../scss/index.scss";

.fullPage {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $primary-bg-color;

  .loader {
    width: 15rem;
    height: 18rem;
    -webkit-animation: animate-svg-fill-1 0.7s ease 0.8s both;
    animation: animate-svg-fill-1 0.7s ease 0.8s both;

  }

  .msg {
    text-align: center;
    font-size: 1.3rem;
    color: $grey-dark-1;
    padding: 2rem 3rem;

    .reload {
      color: $green-medium-1;
      font-size: 1.4rem;
      margin-top: 1rem;
      font-weight: 500;
      cursor: pointer;
    }
  }
}

.spinner {
  margin-top: 1.5rem;
  width: 5rem;
  height: 5rem;
  --color: $green-medium-1;
}




 @-webkit-keyframes animate-svg-fill-1 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(0, 213, 170);
  }
}

@keyframes animate-svg-fill-1 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(0, 213, 170);
  }
}
