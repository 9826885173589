@import "../../../../../scss/index.scss";

.container {
  display: flex;
  padding-bottom: 12px;
  height: 100%;

  .card {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: var(--color-white);
    border-radius: 8px;

    .photosContainer {
      display: flex;
      flex-direction: column;
      gap: 8px;
      padding-bottom: 12px;
      border-bottom: 1px solid var(--color-grayscale-200);

      .swiper {
        width: 100%;
        height: 240px;

        .swiperSlide {
          position: relative;
          width: 100%;
          height: 100%;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 8px;
          }

          .count {
            position: absolute;
            top: 12px;
            left: 12px;
            padding: 4px 8px;
            background-color: rgba(239, 239, 239, 0.85);
            color: var(--color-grayscale-1000);
            @include typography($caption-1);
            border-radius: 4px;
          }

          .controls {
            position: absolute;
            top: 12px;
            right: 12px;
            display: flex;
            gap: 8px;

            .button {
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 8px;
              background-color: var(--color-grayscale-1000);
              color: var(--color-white);
              border-radius: 4px;
              cursor: pointer;

              ion-icon {
                font-size: 20px;
              }
            }
          }

          .uploader {
            position: absolute;
            bottom: 12px;
            right: 12px;
            padding: 4px 8px;
            background-color: rgba(239, 239, 239, 0.85);
            color: var(--color-grayscale-1000);
            @include typography($caption-1);
            border-radius: 4px;
          }
        }
      }
    }

    .messagesContainer {
      display: flex;
      flex-direction: column;
      gap: 8px;
      padding-bottom: 12px;
      min-height: 100%;
      max-height: 100%;
      overflow: hidden;

      .messages {
        position: relative;
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 12px;
        padding: 12px 0;
        overflow-y: scroll;
        @include hide-scrollbar;

        .noMessages {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 12px;

          span {
            @include typography($label-body-3-medium);
            color: var(--color-grayscale-600);
          }

          ion-icon {
            font-size: 48px;
            color: var(--color-grayscale-400);
          }
        }

        .message {
          position: relative;
          display: flex;
          gap: 8px;
          align-items: flex-start;

          @media screen and (min-width: $breakpoint-md) {
            width: 60%;
          }

          ion-avatar {
            width: 32px;
            height: 32px;
            overflow: hidden;
            border-radius: 50%;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }

          .content {
            position: relative;
            display: flex;
            flex-direction: column;
            gap: 4px;
            background-color: var(--color-grayscale-50);
            padding: 8px 12px;
            border-radius: 8px;
            flex: 1;

            .sender {
              @include typography($label-body-5-medium);
              color: var(--color-grayscale-1000);
            }

            .text {
              @include typography($label-body-3-light);
              color: var(--color-grayscale-1000);
              word-wrap: break-word;
              padding-right: 12px;
            }

            .image {
              display: flex;
              flex-direction: column;
              gap: 4px;

              ion-img::part(image) {
                cursor: pointer;
                object-fit: contain;
                border-radius: 8px;
              }
            }

            .audio {
              display: flex;
              align-items: center;
              gap: 4px;

              ion-icon {
                cursor: pointer;
                font-size: 24px;
                color: var(--color-grayscale-1000);
              }

              .visualizer {
                display: flex;
                gap: 4px;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 16px;
                border-radius: 8px;
                padding: 0 4px;

                .bar {
                  width: 3px;
                  height: 10px;
                  background-color: var(--color-grayscale-200);
                  border-radius: 2px;
                  transition: all 0.1s ease-in-out;
                }

                .active {
                  background-color: var(--color-grayscale-1000);
                }
              }
            }

            .datetime {
              @include typography($caption-1);
              color: var(--color-grayscale-600);
              text-align: right;
            }
          }
        }

        .moreIcon {
          position: absolute;
          top: 4px;
          right: 8px;
          font-size: 16px;
          color: var(--color-grayscale-1000);
          cursor: pointer;
          z-index: 10;
          justify-self: flex-end;
          align-self: flex-end;
        }

        .right {
          align-self: flex-end;

          ion-avatar {
            display: none;
          }

          .content {
            background-color: var(--color-main-mint);
            border-top-right-radius: 0;

            .sender {
              display: none;
            }
          }
        }

        .left {        
          .content {
            border-top-left-radius: 0;
            background-color: var(--color-grayscale-50);
            color: var(--color-grayscale-1000);
          }

          .moreIcon {
            display: none;
          }
        }
      }

      .input {
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
        gap: 8px;
        background-color: var(--color-white);
        padding: 8px 0;

        ion-input {
          flex: 1;
          --padding-start: 12px;
          --padding-end: 12px;
          --padding-top: 8px;
          --padding-bottom: 8px;
          --border-radius: 24px;
          --background: var(--color-grayscale-50);
          --color: var(--color-grayscale-1000);
          --placeholder-color: var(--color-grayscale-400);
          --placeholder-font-style: italic;
          --placeholder-font-weight: 300;
          --placeholder-opacity: 1;
          --caret-color: var(--color-grayscale-1000);
          --caret-width: 2px;
          --caret-opacity: 1;
          --caret-animation-duration: 0.5s;
          --caret-animation-timing-function: ease;
          --caret-animation-iteration-count: infinite;
          --caret-animation-direction: alternate;
          --caret-animation-play-state: running;
          --caret-animation-name: blink;
          --placeholder-font-style: normal;
          --highlight-color-valid: none;
          --highlight-color-focused: none;
          --highlight-color-invalid: none;
          @include typography($label-body-4-regular);
        }

        .photoPreview {
          height: 32px;
          width: 32px;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 4px;
            z-index: 100;
          }
        }

        .buttons {
          display: flex;
          gap: 8px;

          .sendButton {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 8px;
            background-color: var(--color-grayscale-1000);
            color: var(--color-grayscale-1000);
            border-radius: 50%;
            cursor: pointer;

            ion-icon {
              font-size: 18px;
              color: var(--color-white);
            }
          }

          .disabled {
            background-color: var(--color-grayscale-200);
            color: var(--color-grayscale-400);
            cursor: not-allowed;
            pointer-events: none;
          }
        }
      }
    }

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .label {
    @include typography($label-body-3-medium);
    color: var(--color-grayscale-600);
  }
}

.galleryModal {
  --height: 95vw;
  --width: 95vw;
}

.fullScreenGallery {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: var(--color-grayscale-25);

  .modalCaption {
    position: absolute;
    bottom: 0px;
    left: 0px;
    padding: 4px 8px;
    background-color: rgba(239, 239, 239, 0.75);
    color: var(--color-grayscale-1000);
    @include typography($label-body-3-light);
    z-index: 10;
    width: 100%;
    height: auto;
  }

  ion-icon {
    position: absolute;
    top: 12px;
    right: 12px;
    font-size: 24px;
    color: var(--color-grayscale-1000);
    cursor: pointer;
    z-index: 10;
  }

  --swiper-navigation-size: 24px;
  --swiper-navigation-top-offset: 50%;
  --swiper-navigation-sides-offset: 12px;
  --swiper-navigation-color: var(--color-green-shade2);

  .swiper-pagination {
    z-index: 1000 !important;
    display: block;
    backface-visibility: visible;
  }

  .modalSwiper {
    width: 100%;
    height: 100%;

    .modalSwiperSlide {
      width: 100%;
      height: 100%;

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

.agentContainer {
  .card {
    padding: 0 12px;

    .messagesContainer {
      padding: 12px 0;
    }
  }
}

@media screen and (min-width: $breakpoint-md) {
  .agentContainer {
    padding-bottom: 0;
    height: 50%;

    .card {
      background-color: var(--color-grayscale-25);
      border: 1px solid var(--color-grayscale-200);
      border-radius: 8px;
      padding-bottom: 12px;

      .messagesContainer {
        background-color: var(--color-grayscale-25);
        width: 40%;
        margin: 0 auto;

        .input {
          background-color: var(--color-grayscale-25);

          .buttons {
            .sendButton {
              height: 44px;
              width: 44px;
            }
          }
        }
      }
    }
  }
  .galleryModal {
    --height: 75vh;
    --width: 75vh;
  }
}
