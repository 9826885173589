@import "../../../../../scss/index.scss";

.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  .section {
    display: flex;
    flex-direction: column;
    gap: 12px;
  
    ion-label {
      @include typography($label-body-5-medium);
      color: var(--color-graysacle-800);
    }
  
    .content {
      display: flex;
      flex-direction: column;
      gap: 12px;
  
      .row {
        display: flex;
        gap: 8px;
        
        .card {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 12px;
          border-radius: 8px;
          background-color: var(--color-white);
          @include typography($label-body-3-regular);
          color: var(--color-graysacle-800);
  
          div:first-of-type {
              flex: 1;
          }
  
          ion-icon {
              font-size: 18px;
              cursor: pointer;
          }
        }

        .emptyCard {
          background-color: transparent;
          border: 1px solid var(--color-grayscale-200);
          color: var(--color-grayscale-200);
        }
      }
    }
  }
}

.sheet {
    --height: auto;
}

.sheetContent {
    background-color: var(--color-white);
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 24px;
    text-align: center;


    .input {
      display: flex;
      flex-direction: column;
      gap: 4px;
      text-align: left;

      ion-label {
        @include typography($caption-1);
        color: var(--color-graysacle-400);
      }

      ion-datetime-button {
        width: 100%;
        &::part(native) {
          width: 100%;
          @include typography($style: $label-body-4-regular);
          text-align: left;
          height: 44px;
        }
      }
    }
}


@media screen and (min-width: $breakpoint-md) {
  .container {
    padding: 12px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .sheetContent {
    .modalHeader {
      position: relative;

      ion-icon {
        position: absolute;
        right: 0;
      }
    }

    ion-datetime {
      --background: var(--color-grayscale-25) !important;
      --background-rgb: var(--color-grayscale-25) !important;
      --wheel-fade-background-rgb: var(--color-grayscale-25) !important;
      --wheel-highlight-background: var(--color-grayscale-25) !important;
    }
  }
}