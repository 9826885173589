@import "../../../../../../../scss/index.scss";

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;

  .item {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 8px;

    label {
      span {
        font-weight: 400;
      }

      ion-toggle {
        display: none;
      }
    }

    .value {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include typography($style: $label-body-3-light);
      color: var(--color-grayscale-600);

      ion-input {
        display: none;
      }

      ion-icon {
        font-size: 18px;
      }

      .toggleContainer {
        display: flex;
        align-items: center;
        gap: 8px;
        @include typography($style: $label-body-3-light);
        ion-toggle {
          &::part(track) {
            width: 56px;
          }
        }
      }
    }
  }

  p {
    padding: 0 12px;
    @include typography($style: $label-body-5-regular);
    color: var(--color-grayscale-600);
  }
}

.popoverContent {
  padding: 12px 8px;
  p {
    @include typography($style: $label-body-5-regular);
  }
}

@media screen and (min-width: $breakpoint-md) {
  .container {
    .item {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      column-gap: 8px;
      background-color: var(--color-white);
      border-radius: 12px;
      padding: 12px;

      label {
        display: flex;
        align-items: center;
        grid-column: 1 / 5;
        gap: 8px;
        ion-toggle {
          display: block;
        }
        span {
          font-weight: 500;
        }
      }

      .value {
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
        gap: 8px;

        .amount {
          display: none;
        }

        ion-input {
          display: block;
        }

        ion-icon {
          cursor: pointer;
          font-size: 24px;
        }

        @include typography($style: $label-body-1-light);

        .toggleContainer {
          display: none;
        }
      }
    }

    p {
      @include typography($style: $label-body-1-light);
    }
  }

  .popoverContent {
    padding: 12px 8px;
    p {
      @include typography($style: $label-body-3-light);
    }
  }
  
}
