@import '../../../../../scss/index.scss';

.container {
    flex: 1;

   ion-col:first-of-type {
    display: flex;
    flex-direction: column;
   }

   ion-col:last-of-type {
    display: flex;
    flex-direction: column;
    justify-content: center;
   }

    &:not(:last-of-type) {
        border-bottom: 1px solid var(--color-grayscale-50);
    }

    .header {
        display: flex;
        align-items: center;
        gap: 8px;

        .unread {
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background-color: var(--color-success-normal);
        }

        .read {
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background-color: var(--color-grayscale-50);
        }

        .title {
            @include typography($label-body-3-medium);
            color: var(--color-grayscale-1000);

            @media screen and (min-width: 768px) {
                @include typography($label-body-2-semi-medium);
            }
        }
    }

    .content {
        display: flex;
        flex-direction: column;
        gap: 8px;
        .sender {
            display: flex;
            align-items: center;
            gap: 4px;
            color: var(--color-grayscale-600);
            @include typography($caption-1);
            @media screen and (min-width: 768px) {
                @include typography($label-body-5-regular);
            }
            ion-icon {
               font-size: 12px; 
            }
        }

        .date {
            color: var(--color-grayscale-400);
            @include typography($caption-1);

            @media screen and (min-width: 768px) {
                @include typography($label-body-5-regular);
            }
        }
    }
}

.readContainer {
    .header .title {
        color: var(--color-grayscale-400);
    }

    .sender {
        color: var(--color-grayscale-400) !important;
    }

    .date {
        color: var(--color-grayscale-200) !important;
    }

    .readButton {
        --background: var(--color-grayscale-200);
    }
}