@import "./variables.scss";
@import "./colors.scss";
@import "./utils.scss";
@import "./breakpoints.scss";
@import "./typography.scss";

* {
  font-family: $base-font-family;
  box-sizing: border-box;

  &:focus {
    outline: none;
  }
}

html {
  height: 100%;
  font-size: $base-font-size;
  font-family: $base-font-family;
}

body {
  font-size: 18px;
  font-weight: 400;
  background-color: var(--color-grayscale-25);
  ::selection {
    background: var(--color-green-tint3);
    color: var(--color-green-shade1);
  }
}

ion-content::-webkit-scrollbar {
  width: 1em;
}

ion-content::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

ion-content::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

ion-content {
  &::part(scroll) {
    &::-webkit-scrollbar {
      width: 1em;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    &::-webkit-scrollbar-thumb {
      background-color: darkgrey;
      outline: 1px solid slategrey;
    }
  }
}

a {
  text-decoration: none;
  color: black;
}

h1 {
  font-size: 30px;
  font-weight: 600;
}

h2 {
  font-size: 26px;
  font-weight: 600;
}

h3 {
  font-size: 22px;
  font-weight: 600;
}

h4 {
  font-size: 18px;
  font-weight: 600;
}

h5 {
  font-size: 15px;
  font-weight: 500;
}

h6 {
  font-size: 12px;
  font-weight: 500;
}

@media screen and (max-width: $large) {
  html {
    font-size: 50%;
  }
}

@media screen and (max-width: $medium) {
  html {
    font-size: 45%;
  }
}

.gmnoprint a,
.gmnoprint span {
  display: none;
}
.gmnoprint div {
  background: none !important;
}

ion-loading {
  color: var(--color-grayscale-1000) !important;
  --spinner-color: var(--color-main-mint) !important;
  --background: var(--color-grayscale-25) !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 22px !important;
  --width: fit-content;
}

.aecorn-alert .alert-wrapper {
  .alert-title {
    @include typography($style: $title-3);
  }

  .alert-message {
    @include typography($style: $label-body-3-regular);
  }

  .alert-button-inner {
    @include typography($style: $label-body-3-regular);
  }

  .aecorn-alert-button-danger {
    background-color: var(--color-tag-red) !important;
    color: var(--color-white) !important;
  }

  .aecorn-alert-button {
    color: var(--color-grayscale-1000) !important;
  }

  .aecorn-alert-button-confirm {
    color: var(--color-green-shade1) !important;
  }

  .aecorn-alert-button-cancel {
    color: var(--color-grayscale-600) !important;
  }
}

ion-back-button {
  --ripple-color: #dbdbdb !important;
}

iframe {
  display: none;
}

.error {
  --background: #ff5500;
  --color: white;
  font-size: 1.5rem;
  --button-color: white;
}

.success {
  --background: #1f7a60;
  --color: white;
  font-size: 1.5rem;
  --button-color: white;
}

ion-item.aecorn-select {
  --background: var(--color-grayscale-40);
  --background-focused: var(--color-grayscale-40);
  --background-hover: var(--color-grayscale-40);
  --background-activated: var(--color-grayscale-40);
  --border-radius: 8px;
  --color: var(--color-grayscale-1000);
  --color-focused: var(--color-grayscale-1000);
  --color-hover: var(--color-grayscale-1000);
  --color-activated: var(--color-grayscale-1000);
  --ripple-color: var(--color-grayscale-600);
  --padding-start: 8px;
  --padding-end: 8px;
  --padding-top: 10px;
  --padding-bottom: 10px;
  --min-height: 0;
  --detail-icon-font-size: 16px;
  --detail-icon-color: var(--color-grayscale-1000);
  --detail-icon-opacity: 1;

  @include typography($style: $label-body-4-regular);

  ion-icon {
    color: var(--color-grayscale-1000);
  }

  &::part(native) {
    height: 40px;
    width: 100%;
  }

  ion-select {
    position: relative;
    --placeholder-color: var(--color-grayscale-1000);
    --highlight-color-valid: var(--color-grayscale-1000);
    --ripple-color: var(--color-grayscale-600);
    --placeholder-opacity: 1;

    &::part(placeholder),
    &::part(text) {
      font-size: 14px;
      font-weight: 500;
      color: var(--color-grayscale-1000);
    }

    &::part(text) {
      margin-right: 30px;
    }

    &::part(icon) {
      color: var(--color-grayscale-1000);
      width: 20px;
      height: 20px;
      position: absolute;
      top: 50%;
      right: 0px;
      transform: translateY(-50%);
    }
  }

  &.border {
    --border-style: solid;
    --border-width: 1px;
    --border-color: var(--color-grayscale-1000);
    --border-radius: 8px;
  }

  &.clear {
    --background: transparent;
    --background-focused: transparent;
    --background-hover: transparent;
    --background-activated: transparent;
    --color: var(--color-white);
    --color-focused: var(--color-white);
    --color-hover: var(--color-white);
    --color-activated: var(--color-white);
    --ripple-color: var(--color-grayscale-25);
    --detail-icon-color: var(--color-white);

    ion-icon {
      color: var(--color-white);
    }
  }

  &.dark {
    --background: var(--color-grayscale-50);
    --background-focused: var(--color-grayscale-50);
    --background-hover: var(--color-grayscale-50);
    --background-activated: var(--color-grayscale-50);
    --color: var(--color-grayscale-600);
    --color-focused: var(--color-grayscale-600);
    --color-hover: var(--color-grayscale-600);
    --color-activated: var(--color-grayscale-600);
    --ripple-color: var(--color-grayscale-50);
    --detail-icon-color: var(--color-grayscale-600);

    ion-icon {
      color: var(--color-grayscale-600);
    }
  }
}

.aecorn-select-popover {
  --border-radius: 8px;

  ion-select-popover {
    padding: 8px 4px;
  }

  .item-radio-checked.sc-ion-select-popover-md {
    --background: none !important;
    background-color: var(--color-grayscale-40) !important;
    --backround: var(--color-grayscale-40) !important;
    --background-focused: var(--color-grayscale-40) !important;
    --background-hover: var(--color-grayscale-40) !important;
  }

  ion-item {
    --background-focused: var(--color-grayscale-25);
    --background-hover: var(--color-grayscale-25);
    --background-activated: var(--color-grayscale-25);
    --border-radius: 8px;
    --color: var(--color-grayscale-1000);
    --color-focused: var(--color-grayscale-1000);
    --color-hover: var(--color-grayscale-1000);
    --color-activated: var(--color-grayscale-1000);
    --ripple-color: var(--color-grayscale-600);
    font-size: 14px;
    font-weight: 500;
  }

  ion-checkbox {
    --checkbox-background-checked: var(--color-grayscale-1000);
    --border-color-checked: var(--color-grayscale-1000);

    &::part(label) {
      font-size: 14px;
      font-weight: 500;
      color: var(--color-grayscale-1000);
    }
  }
}

ion-radio-group.aecorn-radio {
  ion-radio {
    width: auto;
    &::part(container) {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 1px solid var(--color-grayscale-600);
    }

    &::part(mark) {
      background: none;
      transition: none;
      transform: none;
      border-radius: 0;
    }

    &.radio-checked::part(container) {
      background: var(--color-grayscale-1000);
      border-color: transparent;
    }

    &.radio-checked::part(mark) {
      width: 6px;
      height: 8px;
      border-width: 0px 2px 2px 0px;
      border-style: solid;
      border-color: #fff;
      transform: rotate(45deg);
    }
  }
}

ion-input.aecorn-input,
ion-textarea.aecorn-input {
  box-sizing: border-box;
  position: relative;
  --border-color: transparent;
  --border-width: 0px;
  min-width: 115px;
  @include typography($style: $label-body-4-regular);
  --color: var(--color-grayscale-800);
  --highlight-color-valid: none;
  --highlight-color-focused: none;
  --highlight-color-invalid: none;
  caret-color: var(--color-grayscale-200) !important;
  --placeholder-color: var(--color-grayscale-600);
  border: 1px solid var(--color-grayscale-200);
  border-radius: 8px;
  --padding-start: 10px;
  --padding-end: 10px;

  .aecorn-input-label {
    position: absolute;
    top: -10px;
    left: 10px;
    color: var(--color-grayscale-200);
    background-color: var(--color-white);
    @include typography($style: $label-body-5-regular);
  }

  .input-bottom {
    position: absolute;
    bottom: -20px;
    left: 0;
    color: var(--color-success-normal);
    z-index: 1;
    display: flex;
    align-items: center;
    font-size: 11px;
    font-weight: 400;
  }

  &.dark {
    --color: var(--color-grayscale-1000);
    --background: var(--color-grayscale-50);
    border: none;
    --border-radius: 8px;
    --placeholder-color: var(--color-grayscale-600);
  }

  &.readonly {
    --background: var(--color-white);
    --border-width: 0px;
    --color: var(--color-grayscale-600) !important;
    --padding-start: 0px;
  }

  &.ion-invalid {
    border: 1px solid var(--color-tag-red) !important;
    .input-bottom {
      color: var(--color-danger-normal);
    }
  }
}

ion-item.aecorn-search-input {
  --background: var(--color-grayscale-40);
  --background-focused: var(--color-grayscale-40);
  --background-hover: var(--color-grayscale-40);
  --background-activated: var(--color-grayscale-40);
  --border-radius: 8px;
  --color: var(--color-grayscale-1000);
  --color-focused: var(--color-grayscale-1000);
  --color-hover: var(--color-grayscale-1000);
  --color-activated: var(--color-grayscale-1000);
  --ripple-color: var(--color-grayscale-600);
  --padding-start: 8px;
  --padding-end: 8px;
  --padding-top: 10px;
  --padding-bottom: 10px;
  --min-height: 0;
  font-size: 14px;
  font-weight: 500;

  &::part(native) {
    height: 40px;
    width: 100%;
  }

  ion-input,
  ion-textarea {
    position: relative;
    --border-color: transparent;
    --border-width: 0px;
    --placeholder-color: var(--color-grayscale-600);
  }

  &.clear {
    --background: transparent;
    --background-focused: transparent;
    --background-hover: transparent;
    --background-activated: transparent;
  }
}

ion-segment.aecorn-segment {
  --background: var(--color-grayscale-25);

  ion-segment-button {
    height: 32px;
    --color: var(--color-white);
    --color-checked: var(--color-grayscale-1000);
    --border-radius: 10px;
    --border-width: 0px;
  }

  ion-segment-button::part(indicator-background) {
    background: var(--color-grayscale-1000);
  }

  ion-segment-button.ios::part(native) {
    color: var(--color-grayscale-400);
  }

  .segment-button-checked.ios::part(native) {
    color: var(--color-white);
  }

  ion-segment-button.ios::part(indicator-background) {
    border-radius: 10px;
  }

  &.dark {
    --background: var(--color-grayscale-50);

    ion-segment-button.ios {
      --color: var(--color-grayscale-600);
    }
  }

  &.primary {
    --background: var(--color-grayscale-1000);

    ion-segment-button {
      height: 32px;
      --color: var(--color-white);
      --color-checked: var(--color-grayscale-1000);
      --border-radius: 10px;
    }

    ion-segment-button::part(indicator-background) {
      background: var(--color-main-mint);
    }

    .segment-button-checked.ios::part(native) {
      color: var(--color-grayscale-1000);
    }

    ion-segment-button.ios::part(native) {
      color: var(--color-white);
    }
  }
}

ion-breadcrumbs.aecorn-breadcrumbs {
  ion-breadcrumb {
    cursor: pointer;
    --color-active: var(--color-green-shade1);
    --color: var(--color-grayscale-1000);
    --color-hover: var(--color-grayscale-1000);
    --background-focused: unset;

    &::part(native) {
      @include typography($style: $label-body-3-medium);
    }

    &.breadcrumb-active {
      // all breadcrumbs that come after a breadcrumb with this class must have a red color
      & ~ ion-breadcrumb {
        --color: var(--color-grayscale-400);
        --color-hover: var(--color-grayscale-400);
      }
    }
  }
}

ion-back-button.aecorn-back-button {
  @include typography($style: $label-body-4-regular);
  box-shadow: none !important;
  --box-shadow: none !important;
  --color: var(--color-green-shade1);
  --background: transparent;
  --background-activated: var(--color-grayscale-50);
  --background-focused: var(--color-grayscale-25);
  --background-hover: var(--color-grayscale-25);
  --ripple-color: var(--color-grayscale-50);
  --padding-start: 0;
  --padding-end: 0;
  text-transform: none;

  &::part(icon) {
    font-size: 16px;
    margin-right: 8px;
  }
}

ion-button.aecorn-button {
  @include typography($style: $label-body-4-regular);
  text-transform: none;
  --border-radius: 8px;
  --color: var(--color-white);
  --background: var(--color-grayscale-1000);
  --background-activated: var(--color-grayscale-800);
  --background-focused: var(--color-grayscale-800);
  --background-hover: var(--color-grayscale-800);
  --ripple-color: var(--color-grayscale-800);
  box-shadow: none !important;
  --box-shadow: none !important;
  --border-width: 1px;

  @media screen and (min-width: $breakpoint-md) {
    @include typography($style: $label-body-2-semi-medium);
  }

  &.clear {
    --color: var(--color-grayscale-1000);
    --background: transparent;
    --background-activated: var(--color-grayscale-50);
    --background-focused: var(--color-grayscale-25);
    --background-hover: var(--color-grayscale-25);
    --ripple-color: var(--color-grayscale-50);
  }

  &.dark {
    --color: var(--color-white);
    --background: var(--color-grayscale-800);
    --background-activated: var(--color-grayscale-800);
    --background-focused: var(--color-grayscale-800);
    --background-hover: var(--color-grayscale-800);
    --ripple-color: var(--color-grayscale-800);
  }

  &.white {
    --color: var(--color-grayscale-1000);
    --background: var(--color-white);
    --background-activated: var(--color-grayscale-50);
    --background-focused: var(--color-grayscale-25);
    --background-hover: var(--color-grayscale-25);
    --ripple-color: var(--color-grayscale-50);
    --box-shadow: 0px 7.5px 12.5px 0px rgba(0, 0, 0, 0.08),
      0px 1.25px 22.5px 0px rgba(0, 0, 0, 0.06),
      0px 3.75px 6.25px 0px rgba(0, 0, 0, 0.1) !important;
  }

  &.primary {
    --color: var(--color-grayscale-800);
    --background: var(--color-main-mint);
    --background-activated: var(--color-green-shade1);
    --background-focused: var(--color-green-shade1);
    --background-hover: var(--color-green-shade1);
    --ripple-color: var(--color-green-shade1);
  }

  &.border {
    --border-style: solid;
    --border-width: 1px;
    --border-color: var(--color-grayscale-1000);
    --border-radius: 8px;
  }

  &.danger {
    --background: transparent;
    --background-focused: transparent;
    --background-hover: transparent;
    --background-activated: transparent;
    --color: var(--color-tag-red);
    --color-focused: var(--color-tag-red);
    --color-hover: var(--color-tag-red);
    --color-activated: var(--color-tag-red);
    --detail-icon-color: var(--color-tag-red);
    --border-color: var(--color-tag-red);

    ion-icon {
      color: var(--color-tag-red);
    }
  }

  &.danger-border {
    --border-style: solid;
    --border-width: 1px;
    --border-color: var(--color-tag-red);
    --border-radius: 8px;
  }

  &.border-primary {
    --border-style: solid;
    --border-width: 1px;
    --color: var(--color-main-mint);
    --border-color: var(--color-main-mint);
    --border-radius: 8px;
  }

  &.clear-primary {
    --color: var(--color-main-mint);
    --background: transparent;
    --background-activated: var(--color-green-tint3);
    --background-focused: var(--color-green-tint3);
    --background-hover: var(--color-green-tint3);
    --ripple-color: var(--color-green-tint3);
  }

  &.clear-primary-border {
    --color: var(--color-main-mint);
    --background: transparent;
    --background-activated: var(--color-green-tint3);
    --background-focused: var(--color-green-tint3);
    --background-hover: var(--color-green-tint3);
    --ripple-color: var(--color-green-tint3);
    --border-style: solid;
    --border-width: 1px;
    --border-color: var(--color-main-mint);
  }

  &.clear-dark {
    --color: var(--color-green-shade1);
    --background: transparent;
    --background-activated: var(--color-green-tint3);
    --background-focused: var(--color-green-tint3);
    --background-hover: var(--color-green-tint3);
    --ripple-color: var(--color-green-tint3);
  }

  &.clear-dark-border {
    --color: var(--color-green-shade1);
    --background: transparent;
    --background-activated: var(--color-green-tint3);
    --background-focused: var(--color-green-tint3);
    --background-hover: var(--color-green-tint3);
    --ripple-color: var(--color-green-tint3);
    --border-style: solid;
    --border-width: 1px;
    --border-color: var(--color-green-shade1);
  }

  &.shadow {
    --box-shadow: 0px 7.5px 12.5px 0px rgba(0, 0, 0, 0.08) !important;
    --box-shadow: 0px 1.25px 22.5px 0px rgba(0, 0, 0, 0.06) !important;
    --box-shadow: 0px 3.75px 6.25px 0px rgba(0, 0, 0, 0.1) !important;
  }
}

ion-chip.aecorn-chip {
  --color: var(--color-grayscale-1000);
  --background: var(--color-white);
  padding: 5px 8px;
  border-radius: 8px;
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  border: 1px solid var(--color-grayscale-200);

  ion-icon {
    color: var(--color-grayscale-1000);
    margin: 0;
    margin-right: 4px;
  }
}

ion-chip.aecorn-chip.active {
  --color: var(--color-white);
  color: var(--color-white);
  --background: var(--color-grayscale-1000);
  border: none;

  ion-icon {
    color: var(--color-white);
  }
}

ion-toggle.aecorn-toggle {
  --track-background: var(--color-green-tint3);
  --track-background-checked: var(--color-green-shade2);
  --handle-background: var(--color-white);
  --handle-background-checked: var(--color-green-shade2);

  &.toggle-checked {
    &::part(handle) {
      border: 2px solid var(--color-white);
    }
  }
}

ion-toggle.aecorn-toggle-label {
  position: relative;
  --track-background: var(--color-grayscale-1000);
  --track-background-checked: var(--color-green-shade1);
  --handle-background: var(--color-white);
  --handle-background-checked: var(--color-white);
  --handle-box-shadow: none;
  --handle-height: 16px;
  --handle-width: 16px;
  --handle-border-radius: 50%;

  &::part(label) {
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
    font-size: 12px;
    font-weight: 400;
    color: var(--color-white);
    z-index: 1;
  }

  &::part(track) {
    width: 96px;
    height: 28px;
  }

  &::part(handle) {
    z-index: 2;
  }

  &.toggle-checked {
    &::part(label) {
      left: 12px;
    }

    &::part(handle) {
      border: none;
    }
  }
}

ion-checkbox.aecorn-checkbox {
  font-size: 14px;
  font-weight: 400;
  --border-color: var(--color-grayscale-1000);
  --border-color-checked: var(--color-grayscale-1000);
  --border-radius: 4px;
  --checkbox-background: var(--color-white);
  --checkbox-background-checked: var(--color-grayscale-1000);
  --checkmark-color: var(--color-white);

  &:hover {
    --checkbox-background: var(--color-grayscale-50);
  }

  &.checkbox-disabled {
    --border-color: var(--color-grayscale-50);
    --border-color-checked: var(--color-grayscale-50);
    --checkbox-background: var(--color-grayscale-50);
    --checkbox-background-checked: var(--color-grayscale-50);
    --checkmark-color: var(--color-white);
  }
}

ion-datetime.aecorn-datetime {
  --background: var(--color-white);
  --background-rgb: 255, 255, 255;
  --wheel-highlight-background: rgb(255, 255, 255);
  --wheel-fade-background-rgb: 255, 255, 255;

  font-size: 24px !important;

  & > * {
    font-size: 24px !important;
  }

  &::part(month-year-button) {
    @include typography($style: $label-body-3-regular);
  }

  &::part(wheel-item) {
    color: var(--color-grayscale-200);
    color: rgb(211, 211, 211);
    @include typography($style: $label-body-1-semi-bold);
  }

  &::part(wheel-item active) {
    color: var(--color-grayscale-1000);
    color: rgb(0, 0, 0);
    @include typography($style: $label-body-1-semi-bold);
  }

  &::part(calendar-day) {
    color: var(--color-grayscale-1000);
    color: rgb(0, 0, 0);
    @include typography($style: $label-body-1-semi-bold);
  }

  &::part(calendar-day today) {
    border: 1px solid var(--color-grayscale-1000);
    border-radius: 8px;
  }

  &.md::part(calendar-day active),
  &.md::part(calendar-day active):focus {
    background-color: var(--color-grayscale-1000);
    border-radius: 8px;
    color: var(--color-white);
  }

  &.ios::part(calendar-day active),
  &.ios::part(calendar-day active):focus {
    background-color: var(--color-grayscale-1000);
    border-radius: 8px;
    color: var(--color-white);
  }

  &::part(calendar-day):focus {
    background-color: none;
    box-shadow: none;
  }
}

ion-modal.aecorn-modal {
  &::part(content) {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;

    @media screen and (min-width: $breakpoint-md) {
      border-radius: 12px;
    }
  }

  &.auto-height {
    --height: auto;
  }
}

ion-modal.aecorn-single-listing-sheet,
ion-modal.aecorn-listings-sheet,
ion-modal.aecorn-school-sheet {
  --background: var(--color-white) !important;

  &::part(content) {
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
  }
}

ion-modal.aecorn-single-listing-sheet {
  --height: 196px;
}

ion-modal.aecorn-school-sheet {
  --height: auto;
}

ion-toast::part(button),
ion-toast::part(buttoncancel) {
  display: flex;
  align-items: center;
  gap: 4px;
}

ion-toast.aecorn-success-toast {
  --background: var(--color-green-shade1);
  --color: var(--color-white);
  --button-color: var(--color-white);
  @include typography($style: $label-body-5-medium);
  --border-radius: 4px;
  --box-shadow: 0px 1px 1px 0px #0000000f;

  @media screen and (min-width: $breakpoint-md) {
    @include typography($style: $label-body-3-medium);
  }

  &::part(buttoncancel) {
    display: flex;
    align-items: center;
    gap: 40px;
  }
}

ion-toast.aecorn-error-toast {
  --background: var(--color-danger-normal);
  --color: var(--color-white);
  --button-color: var(--color-white);
  @include typography($style: $label-body-5-medium);
  --border-radius: 4px;
  --box-shadow: 0px 1px 1px 0px #0000000f;

  @media screen and (min-width: $breakpoint-md) {
    @include typography($style: $label-body-3-medium);
  }
}

ion-toast.aecorn-info-toast {
  --background: var(--color-white);
  --color: var(--color-grayscale-1000);
  --button-color: var(--color-grayscale-1000);
  @include typography($style: $label-body-5-medium);
  --border-radius: 4px;
  --box-shadow: 0px 1px 1px 0px #0000000f;

  @media screen and (min-width: $breakpoint-md) {
    @include typography($style: $label-body-3-medium);
  }
}

ion-toast.aecorn-warning-toast {
  --background: var(--color-tag-orange);
  --color: var(--color-white);
  --button-color: var(--color-white);
  @include typography($style: $label-body-5-medium);
  --border-radius: 4px;
  --box-shadow: 0px 1px 1px 0px #0000000f;

  @media screen and (min-width: $breakpoint-md) {
    @include typography($style: $label-body-3-medium);
  }
}

ion-modal.auth-modal {
  --height: 80%;
  --width: 90%;
  --border-radius: 24px;

  @media screen and (min-width: $breakpoint-md) {
    --height: calc(100% - 89px);
    --width: 496px;
  }
}

div.aecorn-listing-detail-tab {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--color-white);
  border-radius: 12px;
  padding: 12px;
  @include typography($style: $label-body-3-light);
  color: var(--color-grayscale-1000);

  @media screen and (min-width: $breakpoint-md) {
    @include typography($style: $label-body-1-light);
  }

  &:not(:last-of-type) {
    margin-bottom: 8px;
  }

  &.vertical {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }

  &.dark {
    padding: 24px;
    background-color: var(--color-grayscale-1000);
    color: var(--color-white);
  }

  &.black {
    padding: 24px;
    background-color: #000;
    color: var(--color-white);
  }
}

ion-router-link {
  color: var(--color-green-shade1);
  text-decoration: underline;
}

h1 {
  font-size: 42px;
  line-height: 52px;
  font-weight: 600;
  letter-spacing: 0px;
}

h2 {
  font-size: 36px;
  line-height: 44px;
  font-weight: 600;
  letter-spacing: 0px;
}

h3 {
  font-size: 30px;
  line-height: 36px;
  font-weight: 600;
  letter-spacing: 0.2px;
}

h4 {
  font-size: 26px;
  line-height: 32px;
  font-weight: 600;
  letter-spacing: 0px;
}

h5 {
  font-size: 22px;
  line-height: 32px;
  font-weight: 600;
  letter-spacing: 0px;
}

h6 {
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
  letter-spacing: 0px;
}

p {
  @include typography($style: $label-body-3-regular);

  @media screen and (min-width: $breakpoint-md) {
    @include typography($style: $label-body-1-regular);
  }
}

li {
  @include typography($style: $label-body-5-medium);

  @media screen and (min-width: $breakpoint-md) {
    @include typography($style: $label-body-3-medium);
  }
}

label {
  @include typography($style: $label-body-4-regular);

  @media screen and (min-width: $breakpoint-md) {
    @include typography($style: $label-body-1-regular);
  }
}

.gm-style iframe + div {
  border: none !important;
}

/* Scroll bar stylings */
::-webkit-scrollbar {
  width: 4px !important;
  height: 4px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--color-grayscale-25 !important);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--color-grayscale-200) !important;
  border-radius: 5px !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--color-grayscale-600) !important;
}

ion-content {
  &::part(scroll) {
    scrollbar-width: thin;
    scrollbar-color: var(--color-grayscale-200) var(--color-grayscale-25);
  }
}

.scattered {
  filter: blur(5px);
}