@import '../../../../scss/index.scss';

.container {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .section {
        display: flex;
        flex-direction: column;
        gap: 12px;

        .title {
            @include typography($style: $label-body-3-semi-bold);
            color: var(--color-grayscale-800);
        }

        .swiperSlide {
            width: fit-content;
        }
    }
}

@media screen and (min-width: $breakpoint-md) {
    .container {
        .section {
            .title {
                @include typography($style: $title-2);
            }
        }
    }
}