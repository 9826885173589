@import "../../../../scss/index.scss";

.container {
  display: flex;
  flex-direction: column;
  background-color: var(--color-white);
  border-radius: 8px;
  padding: 8px;

  & > div:not(:last-child) {
    padding: 12px;
    border-bottom: 1px solid var(--color-grayscale-25);
  }

  .header {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
      display: flex;
      align-items: center;
      gap: 12px;

      span:first-of-type {
        @include typography($title-3);
        color: var(--color-grayscale-800);
        width: 100px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      span:nth-of-type(2) {
        @include typography($label-body-5-regular);
        color: var(--color-tag-orange);
      }
    }

    .status {
      display: flex;
      align-items: center;
      gap: 8px;

      ion-icon {
        font-size: 24px;
      }
    }
  }

  .info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include typography($label-body-3-regular);
  }

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
  }

  .date,
  .attendees,
  .showings,
  .activity {
    display: grid;
    grid-template-columns: min-content 1fr;
    gap: 12px;
  }

  .attendees .info {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    div {
      display: flex;
      flex-direction: column;

      span:nth-of-type(2) {
        @include typography($label-body-3-light);
        color: var(--color-grayscale-600);
      }
    }
  }

  .attendees .icon {
    align-items: flex-start;
    margin-top: 3px;
  }

  .activity .info {
    display: flex;
    flex-direction: column;
    align-items: stretch;

    div:first-of-type {
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
    }

    .items {
      display: flex;
      flex-direction: column;
      padding: 4px 0;

      .activityItem {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: var(--color-grayscale-400);

        span:last-of-type {
          @include typography($label-body-5-regular);
        }
      }
    }
  }

  .activity .icon {
    align-items: flex-start;
    margin-top: 3px;
  }

  .showings {
    div {
      display: flex;
      align-items: center;
      gap: 6px;

      span:first-of-type {
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--color-white);
        border-radius: 2px;
        padding: 2px;
        width: 20px;
        height: 22px;
      }

      .withStops {
        background-color: var(--color-green-shade1);
      }

      .withoutStops {
        background-color: var(--color-tag-orange);
      }
    }

    ion-button {
      @include typography($label-body-3-medium);
      --color: var(--color-green-shade1) !important;
    }
  }

  .viewButtonContainer {
    padding-top: 8px;
    ion-button {
        width: 100%;
    }
  }
}

@media screen and (min-width: $breakpoint-md) {
  .container {
    background-color: var(--color-grayscale-25);
    border: 1px solid var(--color-grayscale-200);

    & > div {
      border: none;
    }

    .header {
      .title {
        span:first-of-type {
          width: auto;
        }
      }
    }

    .date {
      .info {
        justify-content: flex-start;
        gap: 48px;
      }
    }

    .viewButtonContainer {
        direction: rtl;
        ion-button {
            width: 50%;
        }
    }
  }
}
