@import "../../../scss/index.scss";

.header {
  padding: 24px;
  background-color: var(--color-grayscale-25);
  @include safe-top-margin;
}

.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .title {
    @include typography($title-2);
  }
}

.activeTour {
  margin: 12px 0;
}

.filters {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;

  .tabs {
    display: flex;
    align-items: center;
    gap: 24px;

    .title {
      @include typography($title-2);
      white-space: nowrap;
    }

    ion-segment-button {
      height: 40px;
      @include typography($label-body-1-regular);
    }
  }

  .buttons {
    display: flex;
    align-items: center;
  }
}

.content {
  --background: var(--color-grayscale-25);
  --ion-padding-bottom: 12px;
  
  .container {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 24px;
    height: 100%;

    .noTours {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      @include typography($title-2);
      color: var(--color-grayscale-600);
    }

    .tours {
      background-color: var(--color-grayscale-25);
      display: grid;
      grid-template-columns: 1fr;
      gap: 12px;
    }
  }
}

.popoverContent {
  --padding-start: 12px;
  --padding-end: 12px;
  --padding-top: 12px;
  --padding-bottom: 12px;
  .title {
    @include typography($label-body-2-regular);
    text-align: center;
  }
  ion-list {
    display: flex;
    flex-direction: column;
    gap: 12px;

    ion-checkbox {
      width: fit-content;
    }
  }
}

@media screen and (min-width: $breakpoint-md) {
  .header {
    padding: 0;
  }


  .content {
    .container {
      position: relative;
      padding: 24px 128px;

      .activeTour {
        width: 50%;
      }

      .tours {
        grid-template-columns: repeat(2, 1fr);
        gap: 24px;
      }
    }
  }
}
