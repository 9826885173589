@import "../../../../scss/index.scss";

.container {
  position: relative;
  min-width: 274px;
  max-width: 336px;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--color-white);
  border-radius: 8px;
  cursor: pointer;

  .image {
    position: relative;
    height: 143px;

    .status {
      position: absolute;
      top: 8px;
      left: 8px;
    }

    .openHouses {
      position: absolute;
      bottom: 8px;
      left: 8px;
      width: calc(100% - 16px);
      background-color: var(--color-sub-mint);
      padding: 4px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      gap: 8px;
      @include typography($style: $label-body-5-regular);
      .title {
        @include typography($style: $label-body-5-medium);
        white-space: nowrap;
      }
      span:last-of-type {
        color: var(--color-grayscale-600);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }
  }

  .details {
    display: flex;
    flex-direction: column;
    padding: 16px;

    .price {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 8px;

      .priceValue {
        @include typography($style: $label-body-1-semi-bold);
        color: var(--color-grayscale-1000);
      }
    }

    .address,
    .type,
    .rooms,
    .tag,
    .mlsNumber {
      @include typography($style: $label-body-5-regular);
      color: var(--color-grayscale-600);
      text-align: left;
    }

    .rooms {
      span:not(:last-of-type) {
        padding-right: 8px;
      }

      span:not(:first-of-type) {
        padding-left: 8px;
        border-left: 1px solid var(--color-grayscale-200);
      }
    }

    .tag {
      display: flex;
      align-items: center;
      gap: 12px;
      margin: 8px 0;
    }

    .mlsNumber {
      margin-top: auto;
      border: 1px solid var(--color-grayscale-600);
      border-radius: 4px;
      padding: 0 4px;
      width: fit-content;
    }
  }

  .restrictedAccess {
    position: absolute;
    width: 70%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    background-color: var(--color-white);
    border-radius: 24px;
    padding: 16px;
    box-shadow: 0px 1px 12px 0px #0000001a;
    box-shadow: 0px 1px 7px 0px #0000000f;
    box-shadow: 0px 1px 5px 0px #00000014;
  }
}

.scatteredData {
  filter: blur(4px);
}
