@import "../../../../../scss/index.scss";

.container {
  display: flex;
  flex-direction: column;
  gap: 12px;

  .stopInfo {
    display: flex;
    flex-direction: column;
    gap: 12px;
    background-color: var(--color-white);
    border-radius: 8px;

    .map {
      width: 100%;
      height: 240px;
      border-radius: 8px;
      overflow: hidden;
    }

    .pin {
      height: 3rem;
      width: 3rem;
      fill: $grey-dark-2;
      stroke: transparent;
      stroke-width: 1px;
    }

    .info {
      display: flex;
      flex-direction: column;
      gap: 12px;

      .row {
        display: flex;
        align-items: center;
        gap: 12px;
        justify-content: space-between;

        .column {
          display: flex;
          align-items: center;
          gap: 4px;
        }

        .label {
          @include typography($label-body-3-medium);
          color: var(--color-grayscale-400);
          white-space: nowrap;
        }

        .value {
          @include typography($label-body-3-regular);
          color: var(--color-grayscale-1000);
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    
  }

  .review {
    padding: 8px;
    border-radius: 8px;
    background-color: var(--color-white);

    .title {
      @include typography($label-body-3-medium);
      color: var(--color-grayscale-1000);
      margin-bottom: 12px;
    }

    .reviewItems {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  }
}

.agentContainer {
  .stopInfo {
      padding: 12px;
  }
}

@media screen and (min-width: $breakpoint-md) {
  .agentContainer {
    .stopInfo {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 24px;
    }
  }
}
