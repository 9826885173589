@import "../../../scss/index.scss";

.header {
  padding: 24px;
  background-color: var(--color-grayscale-25);

  ion-toolbar {
    --background: none;
    --border-width: 0 !important;
  }
}

.bottomButtons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;

  ion-button {
    flex: 1;
    height: 40px;
  }
}

.summary {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0;

  .title {
    display: flex;
    align-items: center;
    gap: 8px;
    @include typography($label-body-1-semi-bold);
    color: var(--color-grayscale-600);
  }

  .clientRequest {
    @include typography($label-body-5-regular);
    color: var(--color-grayscale-600);
    border: 1px solid var(--color-grayscale-600);
    border-radius: 4px;
    padding: 2px 8px;
  }
}

.timeAddress {
  margin-top: 8px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--color-grayscale-800);

  .left,
  .right {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .left {
    span:first-of-type {
      @include typography($label-body-2-regular);
    }
    span:last-of-type {
      @include typography($label-body-5-regular);
    }

    .address {
      display: flex;
      align-items: center;
      gap: 8px;

      span:first-of-type {
        @include typography($label-body-2-regular);
      }
      span:last-of-type {
        @include typography($label-body-5-regular);
      }
    }

    .clientAddress {
      span:first-of-type {
        @include typography($label-body-1-semi-bold);
      }
      span:last-of-type {
        @include typography($label-body-2-regular);
      }
    }

    .time {
      @include typography($label-body-5-regular);
    }

    .clientTime {
      @include typography($label-body-3-regular);
      display: flex;
      gap: 8px;
      align-items: center;
      ion-icon {
        font-size: 22px;
      }
    }
  }

  .right {
    text-align: right;
    @include typography($label-body-5-regular);
  }
}

.status {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 8px;
  margin-bottom: 16px;

  ion-label {
    @include typography($label-body-5-medium);
    color: var(--color-grayscale-800);
  }

  ion-item {
    flex-grow: 1;
  }

  @media screen and (min-width: 1240px) {
    flex-direction: column;
    align-items: start;

    ion-item {
      width: 400px;
    }
  }
}

.content {
  --background: var(--color-grayscale-25);
  --padding-start: 24px;
  --padding-end: 24px;
  --padding-bottom: 12px;
}

.segment {
  position: sticky;
  left: 0;
  right: 0;
  z-index: 10;
  @include hide-scrollbar;
  margin-top: 12px;
  margin-bottom: 12px;

  --background: var(--color-grayscale-50);

  &.noMargin {
    margin-left: calc(-50vw + 50%);
    margin-right: calc(-50vw + 50%);
  }

  ion-segment-button {
    @include typography($label-body-1-semi-bold);
  }
}

.clientContainer {
  background-color: var(--color-white);
  border-radius: 8px;
  position: relative;
  flex: 1;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 12px;

  section {
    flex: 1;
    height: 100%;
    padding: 12px 24px;
    display: flex;
    flex-direction: column;
    gap: 12px;

    .sectionTitle {
      @include typography($label-body-2-semi-medium);
      color: var(--color-grayscale-600);
    }

    &:not(:last-of-type) {
      border-right: solid 1px var(--color-grayscale-50);
    }
  }
}

@media screen and (min-width: 1240px) {
  .header {
    padding: 0;
  }

  .content {
    --padding-start: 128px;
    --padding-end: 128px;
    --padding-top: 24px;
    --padding-bottom: 24px;
    ion-toolbar {
      --background: var(--color-grayscale-25);
    }

    .topButtons {
      display: flex;
      align-items: center;
      gap: 12px;

      ion-button {
        height: 40px;
      }

      .review {
        padding: 8px 12px;
        background-color: var(--color-white);
        border-radius: 8px;
      }
    }

    ion-segment {
      margin-top: 12px;
      ion-segment-button {
        @include typography($label-body-1-semi-bold);
      }
    }
  }
}
