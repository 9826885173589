.container {
    display: flex;
    align-items: center;
    gap: 8px;

    ion-icon {
        font-size: 24px;
        font-weight: 300;
    }

    .priceValue {
        font-size: 14px;
        font-weight: 500;
        color: var(--color-success-normal);
    }

    .priceText {
        font-size: 14px;
        font-weight: 400;
        color: var(--color-grayscale-600);
    }

    .up {
        color: var(--color-success-normal);
    }

    .down {
        color: var(--color-danger-normal);
    }
}