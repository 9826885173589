@import "../../../scss/index.scss";

.container {
  padding: 0 20px;
  background-color: var(--color-white);
  z-index: 1;
  box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.08), 0px 1px 18px 0px rgba(0, 0, 0, 0.06), 0px 3px 5px 0px rgba(0, 0, 0, 0.1);

  .content {
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--color-grayscale-25);

    div:first-of-type {
      display: flex;
      align-items: center;
      gap: 20px;
      svg {
        height: 25pxrem !important;
      }
    }

    div:last-of-type {
      display: flex;
      align-items: center;
      gap: 12px;

      .notifications {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        ion-icon {
          font-size: 24px;
          color: var(--color-grayscale-600);
        }

        .notificationDot {
          position: absolute;
          top: 0;
          right: 0;
          width: 6px;
          height: 6px;
          background-color: var(--color-tag-orange);
          border-radius: 50%;
        }
      }

      .profileDropdown {
        ion-icon:first-of-type {
          svg {
            height: 25px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
          margin-right: 5px;
          background-color: var(--color-grayscale-50);
          padding: 10px;
          border-radius: 50%;
        }

        ion-icon:last-of-type {
          height: 1.5rem;
        }
      }
    }

    .links {
      display: flex;
      align-items: center;
      gap: 24px;
      @include typography($style: $label-body-3-medium);
      color: var(--color-grayscale-400);

      a {
        color: var(--color-grayscale-400);
        &:hover {
          color: var(--color-green-shade1);
        }

        &:active {
          color: var(--color-green-shade1);
        }

        &.active {
          color: var(--color-green-shade1);
        }
      }
    }
  }
}

.search {
  box-shadow: none;
}

.modal {
  --width: 75%;
  --height: 80%;
  --border-radius: 2.5rem;
}

.popoverContent {
  ion-item {
    @include typography($style: $label-body-3-regular);
    --color: var(--color-black) !important;

    ion-icon {
      color: var(--color-grayscale-600);
    }
  }

  .divider {
    border-top: 1px solid var(--color-grayscale-25);
  }
}