@import "../../../../scss/index.scss";

.stop {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;

    .address {
      @include typography($label-body-2-regular);
      color: var(--color-grayscale-1000);
    }

    ion-icon {
      font-size: 24px;
      cursor: pointer;
    }
  }

  .content {
    display: flex;
    gap: 8px;

    .image {
      height: 60px;
      width: 94px;

      img {
        width: 100%;
        height: 100%;
        border-radius: 4px;
        object-fit: cover;
      }
    }

    .details {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      span {
        @include typography($label-body-5-regular);
        color: var(--color-grayscale-600);
      }
    }
  }
}
