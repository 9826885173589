@import "../../../../scss/index.scss";

.container {
  display: flex;
  flex-direction: column;

  .restricted {
    position: absolute;
    top: 0;
    left: 0;
    filter: blur(7px);
    width: 100%;
    height: 100%;
  }

  .priceDataButtons {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .priceDate {
      display: flex;
      flex-direction: column;
      gap: 4px;

      .date {
        display: flex;
        align-items: center;
        gap: 4px;
        @include typography($style: $label-body-5-regular);

        .days {
          position: relative;
          @include typography($style: $label-body-5-regular);
          color: var(--color-grayscale-400);
        }
      }

      .price {
        position: relative;
        @include typography($style: $headline-h2);
        color: var(--color-grayscale-1000);
        display: flex;
        flex-direction: column;
        gap: 4px;

        .estimatedPrice {
          @include typography($style: $label-body-5-regular);
          color: var(--color-grayscale-800);
          display: flex;
          align-items: center;
          gap: 8px;

          span {
            display: flex;
            align-items: center;
            gap: 2px;
          }

          .increase {
            color: var(--color-success-shade);
          }

          .decrease {
            color: var(--color-danger-shade);
          }
        }
      }
    }
  }

  .iconButtons {
    align-self: flex-start;
    display: flex;
    align-items: center;
    gap: 4px;

    .button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      border-radius: 4px;
      background-color: var(--color-grayscale-1000);
      color: var(--color-white);
      cursor: pointer;

      &.active {
        background-color: var(--color-grayscale-500);
      }

      span {
        display: none;
      }
    }
  }

  .openHouses {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    gap: 8px;
    border-radius: 12px;
    padding: 12px 24px;
    row-gap: 4px;
    background-color: var(--color-white);
    @include typography($style: $label-body-3-light);
    color: var(--color-grayscale-800);
    border: 1px solid var(--color-grayscale-200);
    margin: 12px 0;

    .openHouseTitle {
      grid-column: 1/-1;
      @include typography($style: $label-body-3-semi-bold);
    }

    .openHouse {
      display: flex;
      align-items: center;
      gap: 8px;
      ion-icon {
        font-size: 24px;
      }

      div {
        display: flex;
        flex-direction: column;
        gap: 0px;
        @include typography($style: $label-body-3-light);
        color: var(--color-grayscale-600);
      }
    }
  }

  .rooms {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    border-radius: 12px;
    padding: 12px 24px;
    row-gap: 12px;
    background-color: var(--color-white);
    @include typography($style: $label-body-3-light);
    color: var(--color-grayscale-800);
    border: 1px solid var(--color-grayscale-200);
    margin: 12px 0;

    span {
      grid-row: 1 / 2;
      display: flex;
      align-items: center;
      gap: 4px;

      ion-icon {
        font-size: 18px;
      }
    }

    span:first-of-type {
      justify-content: flex-start;
      grid-column: 1 / 2;
    }

    span:nth-of-type(2) {
      justify-content: center;
      grid-column: 2 / 3;
      border-left: 1px solid var(--color-grayscale-200);
      border-right: 1px solid var(--color-grayscale-200);
    }

    span:nth-of-type(3) {
      justify-content: flex-end;
      grid-column: 3 / 4;
    }

    span:nth-of-type(4) {
      grid-row: 2 / 3;
      grid-column: 1 / -1;
    }
  }

  .addressType {
    display: flex;
    flex-direction: column;
    gap: 12px;
    @include typography($style: $label-body-3-light);
    color: var(--color-grayscale-800);
    margin: 12px 0;

    span {
      display: flex;
      align-items: center;
      gap: 8px;

      span {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--color-white);
        width: 24px;
        height: 24px;
        padding: 4px;
        border-radius: 4px;
        border: 1px solid var(--color-grayscale-200);
      }
    }
  }

  .description {
    display: flex;
    flex-direction: column;
    @include typography($style: $label-body-3-light);
    color: var(--color-grayscale-800);
    line-height: 24px;
    padding-bottom: 24px;
    margin-top: 12px;

    ion-button {
      margin-top: 24px;
    }

    .showMore {
      @include typography($style: $caption-1);
      color: var(--color-grayscale-1000);
      cursor: pointer;
    }
  }

  .descriptionTitle {
    display: none;
  }

  border-bottom: 1px solid var(--color-grayscale-200);
}

@media screen and (max-width: $breakpoint-md) {
  .address {
    display: none;
  }

  .contactButtons {
    display: none;
  }

  .iconButtons {
    display: none !important;
  }

  .priceDataButtons {
    .iconButtons {
      display: flex !important;
    }
  }
}

@media screen and (min-width: $breakpoint-md) {
  .container {
    display: grid;
    grid-template-columns: 1fr min-content;
    grid-template-rows: repeat(5, auto);
    column-gap: 48px;

   .priceDataButtons {
    .priceDate {
      .price {
        .estimatedPrice {
          @include typography($style: $label-body-1-light);
        }
      }
    }
   }

    .address {
      @include typography($style: $headline-h2);
      color: var(--color-grayscale-1000);
      grid-row: 1 / 2;
      grid-column: 1 / 2;
    }

    .descriptionTitle {
      display: block;
      grid-row: 2 / 3;
      grid-column: 1 / 2;
      @include typography($style: $title-2);
      padding-top: 36px;
      padding-bottom: 12px;
    }

    .addressType {
      grid-row: 3 / 4;
      grid-column: 1 / 2;
      flex-direction: row;
      gap: 36px;
      margin: 0;
      @include typography($style: $label-body-1-light);
    }

    .openHouses {
      grid-row: 5 / 6;
      grid-column: 1 / 2;
      row-gap: 12px;
      grid-template-columns: repeat(4, 1fr);
      @include typography($style: $label-body-1-light);

      .openHouseTitle {
        grid-column: 1 / -1;
        @include typography($style: $label-body-1-semi-bold);
      }

      .openHouse {
        gap: 12px;
        ion-icon {
          font-size: 36px;
        }
        div {
          @include typography($style: $label-body-1-light);
        }
      }
    }

    .rooms {
      display: flex;
      grid-row: 4 / 5;
      grid-column: 1 / 2;
      padding-top: 24px;
      padding-bottom: 24px;
      justify-content: space-between;
      @include typography($style: $label-body-1-light);

      span {
        flex: 1;
        justify-content: center !important;
        gap: 8px;
        ion-icon {
          font-size: 24px;
        }
      }

      span:not(:last-of-type) {
        border-right: 1px solid var(--color-grayscale-200);
      }

      span:first-of-type {
        justify-content: flex-start !important;
      }

      span:last-of-type {
        justify-content: flex-end !important;
      }
    }

    .description {
      grid-row: 6 / 7;
      grid-column: 1 / 2;
      text-wrap: pretty;
      @include typography($style: $label-body-1-light);
      ion-button {
        display: none;
      }
    }

    .showMore {
      @include typography($style: $label-body-4-regular, $important: true);
    }

    .priceDataButtons {
      grid-row: 1 / 5;
      grid-column: 2 / 3;
      width: 377px;
      align-items: flex-start;
      border: 1px solid var(--color-grayscale-200);
      padding: 20px;
      border-radius: 12px;

      .iconButtons {
        display: none;
      }

      .date {
        .days {
          font-size: 18px !important;
        }
      }

      .contactButtons {
        ion-button {
          width: 100%;
        }
      }
    }

    .iconButtons {
      grid-row: 5 / 6;
      grid-column: 2 / 3;
      display: flex;
      justify-content: space-between;
      gap: 8px;
      padding: 24px 0;

      .button {
        width: 100%;
        height: 48px;
        display: flex;
        align-items: center;
        gap: 8px;
        border-radius: 8px;
        cursor: pointer;

        span {
          display: block;
          font-size: 12px;
          font-weight: 400;
        }
      }
    }
  }
}
