@import "../../../../scss/index.scss";

.container {
  display: flex;
  flex-direction: column;
  background-color: var(--color-white);
  border-radius: 8px;
  padding: 8px;

  .header {
    padding: 0 12px;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
      display: flex;
      align-items: center;
      gap: 8px;

      span:first-of-type {
        @include typography($title-3);
        color: var(--color-grayscale-800);
        max-width: 120px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }

  .info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include typography($label-body-3-regular);
  }

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
  }

  .datetime {
    padding: 0 12px 12px 12px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--color-grayscale-25);

    .content {
      display: flex;
      flex-direction: column;
      gap: 8px;

      span {
        display: flex;
        align-items: center;
        gap: 4px;
        @include typography($label-body-5-regular);
        color: var(--color-grayscale-400);

        ion-icon {
          font-size: 16px;
        }
      }
    }
  }

  .label {
    @include typography($label-body-3-regular);
    color: var(--color-grayscale-600);
  }

  .attendees {
    padding: 12px;
    border-bottom: 1px solid var(--color-grayscale-25);
    display: flex;
    flex-direction: column;
    gap: 12px;

    .items {
      display: grid;
      grid-template-columns: repeat(2, 1fr);

      @media screen and (min-width: $breakpoint-md) {
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }

  .showings {
    padding: 12px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    .summary {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .title {
        display: flex;
        align-items: center;
        gap: 8px;

        .count {
          @include typography($label-body-5-medium);
          padding: 4px 8px;
          border-radius: 4px;
          background-color: var(--color-grayscale-25) !important;
          color: var(--color-grayscale-800) !important;
        }
      }
    }

      .stops {
        display: flex;
        flex-direction: column;
        gap: 8px;

        @media screen and (min-width: $breakpoint-md) {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
        }
      }

      .stopsShowMoreButton {
        justify-self: flex-start;
        align-self: flex-start;
        --padding-start: 0;
      }
    }

    .info {
      display: grid;
      grid-template-columns: repeat(2, 1fr);

      div {
        display: flex;
        flex-direction: column;

        span:nth-of-type(2) {
          @include typography($label-body-3-light);
          color: var(--color-grayscale-600);
        }
      }
    }

    .icon {
      align-items: flex-start;
      margin-top: 3px;
    }

    .activity .info {
      display: flex;
      flex-direction: column;
      align-items: stretch;

      div:first-of-type {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
      }

      .items {
        display: flex;
        flex-direction: column;
        padding: 4px 0;
      }
    }

    .icon {
      align-items: flex-start;
      margin-top: 3px;
    }

    .buttons {
      display: flex;
      align-items: center;
      gap: 8px;
      padding-top: 8px;
      ion-button {
        flex: 1;
      }
    }
  }

  @media screen and (min-width: $breakpoint-md) {
    .container {
      & > div {
        border: none;
      }

      .header {
        .title {
          span:first-of-type {
            width: auto;
          }
        }
      }
    }
  }
