/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
  --color-black: #000000;
  --color-grayscale-1000: #1c1c1c;
  --color-grayscale-800: #2f2f2f;
  --color-grayscale-600: #454748;
  --color-grayscale-500: #4f4f4f;
  --color-grayscale-400: #878787;
  --color-grayscale-200: #d3d3d3;
  --color-grayscale-50: #efefef;
  --color-grayscale-40: #f8f7f7;
  --color-grayscale-25: #f5f5f5;
  --color-grayscale-10: ##F8F8F8;
  --color-grayscale-transparent: #00000;
  --color-white: #ffffff;

  --color-main-mint: #00D5AA;
  --color-sub-mint: #04E5B8;
  --color-green-shade1: #00856A;
  --color-green-shade2: #005040;
  --color-green-tint1: #33E6C5;
  --color-green-tint2: #99F3E2;
  --color-green-tint3: #DAF6EC;
  
  --color-tag-green: #00856A;
  --color-tag-red: #F73B00;
  --color-tag-orange: #FF6B00;

  --color-success-normal: #5CB85C;
  --color-success-shade: #408140;
  --color-success-tint: #E2F3E2;

  --color-danger-normal: #F73B00;
  --color-danger-shade: #942300;
  --color-danger-tint: #FDD8CC;

  --color-warning-normal: #FFD054;
  --color-warning-shade: #876710;
  --color-warning-tint: #FFF7DE;

  --color-marker-light: #00000026;
  --color-marker-dark: #1C1C1C;

  /** map **/
  --color-cluster-roadmap: rgba(0, 0, 0, 0.26);

  /** primary **/
  --ion-color-primary: var(--color-main-mint);
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: var(--color-grayscale-1000);
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;

  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
}
