@import "../../../scss/index.scss";

.button {
  position: relative;
  display: flex;
  align-items: center;
  border: none;
  background: none;

  &:disabled {
    pointer-events: none;
    background-color: $grey-light-1;
    border-color: $grey-light-1;
    .title,
    .icon {
      color: $grey-light-2;
      stroke: $grey-light-2;
    }
  }

  .icon {
    height: 2rem;
    width: 2rem;
    display: grid;
    justify-content: center;
    align-self: center;
  }

  .title {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;

    .spinner {
      transform: scale(0.8);
      margin-right: 1rem;
      --color: #ffffff;
    }

    .loader {
      stroke: $grey-dark-2;
      margin-right: 1rem;
      stroke-width: 2rem;

      height: 2rem;
      width: 2rem;
    }
  }
}

.filter {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  border: $input-border;
  border-radius: $radius-sm;
  padding: 0.5rem 1rem;
  cursor: pointer;

  .title {
    font-size: 1.5rem;
    font-weight: 600;
    color: $grey-dark-2;
    margin-left: 0.5rem;
  }

  .icon {
    stroke: $grey-dark-2;
    stroke-width: 1.5px;
    order: 2;
    height: 1.8rem;
    width: 1.8rem;
  }
}

.clear {
  display: flex;
  align-items: center;
  width: 11rem;
  color: $green-dark-1;
  stroke: $green-dark-1;
  @include button-layout;
  &:not(:last-of-type) {
    border-right: 1px solid $line-color;
  }
}

.clearNoBorder {
  display: flex;
  align-items: center;
  width: 11rem;
  color: $green-medium-1;
  stroke: $green-medium-1;
  @include button-layout;
}

.glass {
  color: $grey-dark-2;
  width: 12rem;
  height: 3rem;
  @include button-layout;

  border: 1px solid $grey-dark-1;
}

.background {
  filter: blur(8px);
  position: absolute;
  background-color: $grey-light-2;
  width: 100%;
  height: 100%;
}

.foreground {
  backdrop-filter: blur(16px);
  font-size: 1.3rem;
  font-weight: 500;
}

.green {
  background-color: $green-light-1;
  color: $green-medium-1;
  stroke: $green-medium-1;
  width: 15rem;
  height: 4rem;
  @include button-layout;
  border-radius: $radius-lg;

  &:hover {
    color: $green-light-1;
    background-color: $green-medium-1;

    .icon {
      stroke: $green-light-1;
    }
  }

  &:focus {
    color: $green-light-1;
    background-color: $green-medium-1;

    .icon {
      stroke: $green-light-1;
    }
  }
}

.mobileGreen {
  background-color: $green-light-1;
  color: $green-medium-1;
  stroke: $green-medium-1;
  width: 15rem;
  height: 4rem;
  @include button-layout;
  border-radius: $radius-lg;
}

.darkGreen {
  background-color: $green-medium-1;
  color: $green-light-1;
  stroke: $green-light-1;
  width: 15rem;
  height: 4rem;
  @include button-layout;
  border-radius: $radius-lg;
  &:hover {
    color: $green-light-1;
    background-color: $green-medium-1;

    .icon {
      stroke: $green-light-1;
    }
  }
}

.mapIcon {
  font-size: 1.5rem;
  color: white;
  margin-right: 1rem;
}

.smallIcon {
  margin-right: 1rem;
  height: 1.5rem;
  width: 1.5rem;
  color: white;
  stroke: white;
}

.darkGreenApp {
  background-color: $green-dark-1;
  color: white;
  stroke: white;
  width: 20rem;
  height: 4rem;
  @include button-layout;
  border-radius: $radius-lg;

  &:disabled {
    pointer-events: none;
    background-color: $grey-light-1;
    border-color: $grey-light-1;
    .title,
    .icon,
    .mapIcon,
    .smallIcon {
      color: $grey-light-2;
      stroke: $grey-light-2;
    }
  }
}

.orange {
  background-color: $orange-light;
  color: $orange-dark;
  stroke: $orange-dark;
  width: 15rem;
  height: 4rem;
  @include button-layout;
  border-radius: $radius-lg;

  &:hover {
    color: $orange-light;
    background-color: $orange-dark;

    .icon {
      stroke: $orange-dark;
    }
  }
}

.blue {
  background-color: $blue-light;
  color: $blue-dark;
  stroke: $blue-dark;
  width: 15rem;
  height: 4rem;
  @include button-layout;
  border-radius: $radius-lg;

  &:hover {
    color: $blue-light;
    background-color: $blue-dark;
  }
}

.grayLight {
  background-color: white;
  color: $grey-dark-2;
  stroke: $grey-dark-2;
  width: 15rem;
  height: 4rem;
  @include button-layout;
  border-radius: $radius-lg;

  &:hover {
    color: white;
    background-color: $grey-dark-2;

    .icon {
      stroke: white;
    }
  }
}

.grayMedium {
  background-color: $grey-light-2;
  color: $grey-dark-2;
  stroke: $grey-dark-2;
  width: 15rem;
  height: 4rem;
  @include button-layout;
  border-radius: $radius-lg;

  &:hover {
    color: white;
    background-color: $grey-dark-2;

    .icon {
      stroke: white;
    }
  }
}

.gray {
  background-color: $grey-dark-2;
  color: white;
  stroke: white;
  width: 15rem;
  height: 4rem;
  @include button-layout;
  border-radius: $radius-lg;

  &:hover {
    color: $grey-dark-2;
    background-color: $grey-light-2;
    border: 0.1rem solid $grey-dark-2;

    .icon {
      stroke: $grey-dark-2;
    }
  }
}

.nav {
  background-color: $green-light-1;
  color: $green-medium-1;
  stroke: $green-medium-1;
  width: 15rem;
  height: 4rem;
  @include button-layout;
  border-radius: $radius-lg;
  border-color: $green-medium-1;
}

.icon {
  height: 2rem;
  width: 2rem;
  margin-right: 5px;
  transform: translateY(1px);
}

.button:disabled {
  cursor: not-allowed;
}

.block {
  border-radius: $radius-sm;
}

.border {
  border: 1px solid;
}
