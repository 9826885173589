@import "../../../../scss/index.scss";

.header {
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: var(--color-white);
  padding: 24px;
  --box-shadow: none;
  box-shadow: none;

  ion-item {
    flex: 1;
    box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.08),
      0px 1px 18px 0px rgba(0, 0, 0, 0.06), 0px 3px 5px 0px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    ion-input {
      @include typography($style: $label-body-3-regular);
      color: var(--color-grayscale-1000);
      position: relative;
      --padding-top: 14px;
      --padding-bottom: 14px;

      ion-icon {
        font-size: 24px;
        color: var(--color-grayscale-400);
      }
    }
  }
}

.container {
  padding: 0 24px 12px 24px;

  .listings {
    display: flex;
    flex-direction: column;

    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include typography($style: $label-body-3-semi-bold);
      color: var(--color-grayscale-400);
      &:not(:first-of-type) {
        padding: 12px 0;
        margin-top: 12px;
      }
    }

    .locationResult,
    .listingResult {
      display: flex;
      align-items: center;
      padding: 12px 0;
      &:not(:last-of-type) {
        border-bottom: 1px solid var(--color-grayscale-200);
      }
    }
  }

  .noListings {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    width: 100%;

    text-align: center;
    
    ion-icon {
      font-size: 128px;
      color: var(--color-grayscale-1000);
    }

    .title {
      @include typography($style: $label-body-1-semi-bold);
      color: var(--color-grayscale-600);
    }
    p {
      padding: 0;
      @include typography($style: $label-body-3-light);
      color: var(--color-grayscale-600);
    }
  }
}
