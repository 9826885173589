@import "../../../scss/index.scss";

.terms {
  margin: 2rem;
  color: $grey-dark-2;
  font-size: 1.5rem;

  .header {
    font-size: 2.2rem;
    font-weight: 600;
  }

  .subHeader {
    font-size: 1.5rem;
    font-weight: 600;
  }

  .title {
    margin-top: 2rem;
    font-size: 2.2rem;
    border-bottom: 0.1rem solid $grey-light-2;
    margin-bottom: 0.5rem;
    padding-bottom: 1rem;
  }

  .label {
    font-size: 1.8rem;
    font-weight: 600;
  }

  .bold {
    font-weight: 600;
  }
  a {
    text-decoration: underline;
  }
  .borderBottom {
    border-bottom: 0.1rem solid $grey-light-2;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
  }
}
