@import "../../../../../scss/index.scss";

.container {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .group {
    display: flex;
    flex-direction: column;
    gap: 12px;

    ion-label {
      @include typography($label-body-5-medium);
      color: var(--color-grayscale-800);
    }

    .section {
      display: flex;
      flex-direction: column;
      gap: 8px;

      ion-label {
        @include typography($caption-1);
        color: var(--color-grayscale-400);
      }

      .content {
        display: flex;
        flex-direction: column;
        gap: 12px;

        .row {
          display: flex;
          gap: 8px;

          .card {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 12px;
            border-radius: 8px;
            background-color: var(--color-white);
            @include typography($label-body-3-regular);
            color: var(--color-graysacle-800);
            width: 100%;

            div:first-of-type {
              flex: 1;
            }

            ion-icon {
              font-size: 18px;
              cursor: pointer;
            }

            .text {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }

          .emptyCard {
            background-color: transparent;
            border: 1px solid var(--color-grayscale-200);
            color: var(--color-grayscale-200);
          }

          .readOnlyCard {
            background-color: var(--color-grayscale-40);
            color: var(--color-grayscale-400);

            &:hover {
              cursor: not-allowed;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: $breakpoint-md) {
  .container {
    border: 1px solid var(--color-grayscale-200);
    border-radius: 8px;
    align-items: center;
    padding: 24px;

    .group {
      width: 50%;
    }
  }
}
