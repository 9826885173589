@import '../../../../../scss/index.scss';

.container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px;

    @media screen and (min-width: $breakpoint-md) {
        grid-template-columns: repeat(auto-fit, minmax(336px, 1fr));
    }


  .noFavorites {
    padding-top: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    text-align: center;
    margin: 0 auto;
    color: var(--color-grayscale-200);
    @include typography($label-body-2-semi-medium);

    ion-icon {
      font-size: 80px;
    }

    @media screen and (min-width: $breakpoint-md) {
      @include typography($title-3);

      ion-icon {
        font-size: 100px;
      }
    }
  }
}