@import '../../../scss/index.scss';

.header {
    background-color: var(--color-white);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 14px;
    @include typography($style: $label-body-1-semi-bold);
    color: var(--color-grayscale-600);
    position: relative;
    border-bottom: 1px solid var(--color-grayscale-200);
  
    ion-icon {
      position: absolute;
      right: 14px;
      top: 50%;
      transform: translateY(-50%);
      font-size: 24px;
      color: var(--color-grayscale-600);
      cursor: pointer;
      z-index: 10;
    }
  
    div {
      flex-grow: 1;
      text-align: center;
    }

    &.tour {
      padding: 24px;
      @include typography($style: $title-3);
      color: var(--color-grayscale-600);
      background-color: var(--color-grayscale-25);
      border: none;
    }
  }

  .safePadding {
    @include safe-top-padding('14px');
  }
  

  .footer {
    background-color: var(--color-white);
    display: flex;
    justify-content: space-between;
    gap: 14px;
    align-items: center;
    padding: 14px;
    border-top: 1px solid var(--color-grayscale-200);
    box-shadow: 0px -6px 10px 0px #00000014;
  
    ion-button {
      flex: 1;
    }
  }

  .toursHeader {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    padding: 24px;
    @include safe-top-padding('24px');
    background-color: var(--color-grayscale-25);

    ion-button {
      --padding-start: 0;
      --padding-end: 0;
    }

    .title {
      @include typography($style: $label-body-1-semi-bold);
      color: var(--color-grayscale-600);

      span {
        color: var(--color-green-shade1);
      }
    }
  }


  .sheetContent {
    background-color: var(--color-white);
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 24px;
  
    .modalHeader {
      position: relative;
      @include typography($style: $label-body-1-semi-bold);
      color: var(--color-grayscale-600);
      text-align: center;

      ion-icon {
        position: absolute;
        right: 12px;
        top: 45%;
        transform: translateY(-50%);
        font-size: 24px;
        color: var(--color-grayscale-600);
        cursor: pointer;
        z-index: 10;
        
      }
    }
  
    .modalInput {
      .input {
        display: flex;
        align-items: center;
        gap: 8px;
      }
    }
  
    ion-label {
      @include typography($caption-1);
      color: var(--color-grayscale-400);
    }
  
    ion-list {
      max-height: 300px;
      overflow: auto;
      .item {
        span,
        ion-icon {
          color: var(--color-grayscale-400);
        }
        &:not(:first-of-type) {
          margin-top: 8px;
        }
      }
    }

    ion-datetime {
      --background: var(--color-grayscale-25) !important;
      --background-rgb: var(--color-grayscale-25) !important;
      --wheel-fade-background-rgb: var(--color-grayscale-25) !important;
      --wheel-highlight-background: var(--color-grayscale-25) !important;
    }
  
    .item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 8px;
      border: 1px solid var(--color-grayscale-200);
      border-radius: 8px;
  
      span {
        @include typography($label-body-3-medium);
        color: var(--color-grayscale-1000);
        display: flex;
        align-items: center;
        gap: 8px;
        flex: 1;
  
        .email {
          @include typography($caption-1);
          color: var(--color-grayscale-400);
        }
  
        .tag {
          margin-left: auto;
          margin-right: 8px;
          padding: 4px 8px;
          border-radius: 8px;
          @include typography($caption-1);
        }
  
        .external {
          background-color: var(--color-grayscale-40);
          border: 1px solid var(--color-grayscale-200);
          color: var(--color-grayscale-400);
        }
  
        .verified {
          background-color: var(--color-tag-green);
          color: var(--color-white);
        }
      }
  
      ion-icon {
        font-size: 16px;
        cursor: pointer;
      }
    }
  }