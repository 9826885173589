@import "../../../../scss/index.scss";

.content {
  display: flex;
  flex-direction: column;
  gap: 12px;

  .note {
    @include typography($label-body-3-regular);
    color: var(--color-grayscale-600);

    @media screen and (min-width: 768px) {
      @include typography($label-body-2-semi-medium);
      color: var(--color-grayscale-1000);
    }
  }

  .date {
    display: flex;
    align-items: center;
    justify-content: space-between;

    span:first-of-type {
      @include typography($label-body-3-regular);
      color: var(--color-grayscale-1000);

      @media screen and (min-width: 768px) {
        @include typography($label-body-3-semi-bold);
        color: var(--color-grayscale-800);
      }
    }

    span:last-of-type {
      @include typography($label-body-5-regular);
      color: var(--color-grayscale-400);
    }
  }

  .map {
    width: 100%;
    height: 240px;
    border-radius: 8px;
    overflow: hidden;

    .marker {
        position: relative;
        .pin {
            height: 5rem;
            width: 5rem;
            fill: $grey-dark-2;
            stroke: transparent;
            stroke-width: 1px;
        }

        .order {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 35%;
            left: 50%;
            height: 18px;
            width: 18px;
            transform: translate(-50%, -50%);
            color: var(--color-grayscale-1000);
            padding: 2px;
            background-color: var(--color-white);
            border-radius: 50%;
            font-weight: 500;
        }
    }

    .new {
        .pin {
            fill: var(--color-main-mint);
        }

        .order {
            color: var(--color-main-mint);
        }
    }
  }
}
