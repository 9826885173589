@import "../../../../scss/index.scss";

.title {
  padding-top: 14px;
  @include typography($style: $label-body-1-semi-bold);
  margin-bottom: 16px;
  text-align: center;
}
.form {
  @include hide-scrollbar;
  padding: 12px 28px;
  display: flex;
  flex-direction: column;
  background-color: var(--color-white);

  label {
    @include typography($style: $label-body-3-semi-bold);
    color: var(--color-grayscale-600);
    margin-bottom: 8px;
  }

  ion-list {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .agent {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include typography($style: $label-body-3-semi-bold);
      background-color: var(--color-grayscale-25);
      padding: 4px 8px;
      border-radius: 4px;
    }
  }
}
