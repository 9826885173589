@import "../../../../scss/index.scss";

.errorToast {
  --background: #ff5500;
  --color: white;
  font-size: 1.2rem;
  --button-color: white;
}
.successToast {
  --background: #c24100;
  --color: white;
  font-size: 1.2rem;
  --button-color: white;
}
