@import "../../../scss/index.scss";

.container {
  background-color: #000;
  display: flex;
  flex-direction: column;
  gap: 36px;
  padding: 24px;

  @media screen and (min-width: $breakpoint-md) {
   padding: 72px;
  }

  .title {
    @include typography($style: $title-2);
    color: var(--color-main-mint);
    text-align: center;

    @media screen and (min-width: $breakpoint-md) {
      @include typography($style: $headline-h2);
    }
  }

  .items {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;

    @media screen and (min-width: $breakpoint-md) {
      display: flex;
      justify-content: space-between;
    }

    .item {
      display: flex;
      flex-direction: column;
      .title {
        text-align: left;
        color: var(--color-main-mint);
        padding: 10px 0;
        @include typography($style: $label-body-3-regular);
        border-bottom: 1px solid var(--color-main-mint);

        @media screen and (min-width: $breakpoint-md) {
          @include typography($style: $title-2);
        }
      }

      .search {
        @include typography($style: $label-body-3-regular);
        color: white;
        margin-bottom: 10px;

        &:first-of-type {
          margin-top: 10px;
        }

        & a {
          color: white;
        }
      }
    }
  }
}
