@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");

$base-font-size: 62.5%; // 10px
$base-font-family: "Poppins", "Open Sans", "Source Sans Pro", sans-serif;

// Breakpoints
$largest: 1824px;
$large: 1224px;
$x-medium: 959px;
$medium: 768px;
$small: 640px;
$smallest: 420px;

$search-bp1: 1600px;
$search-bp2: 1400px;
$search-bp3: 1080px;
$search-bp4: 950px;


// AECORN COLORS
$aecorn-primary-light: #00D5AA;
$aecorn-primary-dark: #00856A;

$aecorn-secondary-light: #4F4F4F;
$aecorn-secondary-medium: #2F2F2F;
$aecorn-secondary-dark: #1C1C1C;

$aecorn-black: #000000;
$aecorn-white: #FFFFFF;
$aecorn-grey: #F2F2F2;

// NEW COLORS
$yellow-light: #fff6d7;
$yellow-medium: #ffde6d;
$yellow-dark: #8a6a00;

$orange-light: #ffeee5;
$orange-medium: #ff5500;
$orange-medium-2: #ff5f00;
$orange-dark: #c24100;

$blue-light: #e0faff;
$blue-medium: #1aaecc;
$blue-dark: #007b94;

$green-light-1: #dff7f0;
$green-light-2: #00D5AA;
$green-medium-1: #00856A;
$green-dark-1: #006653;

$grey-light-1: #FFFFFF;
$grey-light-2: #F2F2F2;
$grey-medium-1: #979797;
$grey-medium-2: #4F4F4F;
$grey-dark-1: #2F2F2F;
$grey-dark-2: #1C1C1C;
$grey-dark-3: #000000;

// COLORS
// $purple-light-1: #f4f4f7;
// $purple-light-2: #e9e3f2;
// $purple-light-3: #dedaec;
// $purple-medium-1: #927bca;
// $purple-medium-2: #7158ad;
// $purple-dark-1: #33244c;
// $purple-dark-2: #110d1b;

// BORDER COLOR
$border-color: #dbdbdb;

// MAP COLORS
$cluster-bg-color: #36363650;
$pre-cluster-bg-color: #1aaeccb7;
$pre-cluster-bg-color-light: #e0faffde;
$cluster-border: 1px solid #363636;
$marker-bg-color: #363636;

// BACKGROUND COLORS
$primary-bg-color: #f2f2f2;
$secondary-bg-color: white;

// TEXT COLORS
$primary-text-color: #363636;
$secondary-text-color: #6e6e6e;

// LINE COLOR
$line-color: white;

// SHODOW COLOR
$shadow-color: #dbdbdb;
$box-shadow: 1px 1px 1.5rem #dbdbdb;

//remove them later!!!
$box-shadow-dark: 1px 1px 1.5rem #363636;
$box-shadow-grey: 1px 1px 1.5rem #00000027;
$box-shadow-map-button: 1px 1px 15px #00000034;

// COLORED TEXT
@mixin blue-light-text {
  color: #007b94;
  background-color: #e0faff;
}

@mixin orange-light-text {
  color: #c24100;
  background-color: #ffeee5;
}

@mixin orange-dark-text {
  color: white;
  background-color: #ff5500;
}

@mixin green-light-text {
  color: #1f7a60;
  background-color: #dff7f0;
}

// @mixin purple-light-text {
//   color: #7158ad;
//   background-color: #e9e3f2;
// }

@mixin grey-light-text {
  color: #363636;
  background-color: #f9f9f9;
  border: 1px solid #e5e5e5;
}

// BORDER RADIUS
$radius-sm: 0.5rem;
$radius-normal: 1rem;
$radius-md: 1.5rem;
$radius-lg: 2.5rem;

// BORDER
$dashed-border: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='25' ry='25' stroke='%23333' stroke-width='1' stroke-dasharray='8%2c 8' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");

// INPUT
$placeholder-color: #6e6e6e;
$placeholder-font-size: 1.5rem;
$placeholder-font-weight: 400;
$input-icon-color: #363636;
$input-label-color: #6e6e6e;
$input-label-font-size: 1.2rem;
$input-label-font-weight: 500;
$input-text-color: #363636;
$input-text-font-size: 1.5rem;
$input-text-font-weight: 600;
$input-border: 1px solid #e5e5e5;

// ANIMATION

@keyframes Border {
  0% {
    border-color: #f2f2f2;
  }
  10% {
    border-color: #dbdbdb;
  }
  20% {
    border-color: #9c9c9c;
  }
  30% {
    border-color: #6e6e6e;
  }
  40% {
    border-color: #363636;
  }
  50% {
    border-color: #1a1a1a;
  }
  60% {
    border-color: #363636;
  }
  70% {
    border-color: #6e6e6e;
  }
  80% {
    border-color: #9c9c9c;
  }
  90% {
    border-color: #dbdbdb;
  }
  100% {
    border-color: #f2f2f2;
  }
}

@-webkit-keyframes Border {
  0% {
    border-color: #f2f2f2;
  }
  10% {
    border-color: #dbdbdb;
  }
  20% {
    border-color: #9c9c9c;
  }
  30% {
    border-color: #6e6e6e;
  }
  40% {
    border-color: #363636;
  }
  50% {
    border-color: #1a1a1a;
  }
  60% {
    border-color: #363636;
  }
  70% {
    border-color: #6e6e6e;
  }
  80% {
    border-color: #9c9c9c;
  }
  90% {
    border-color: #dbdbdb;
  }
  100% {
    border-color: #f2f2f2;
  }
}

// Variables for custom highlight
:root {
  /* The intrinsic width of the underline stroke (in pixels). This is 
   * the same as the height of the cap images. Don't specify the
   * units! This is because of some of the calculations we do later on. */
  --underline-intrinsic-width: 8;

  /* The actual width of the underline stroke we want to render (in pixels).
    * You can modify this, and the sizing and positioning should be calculated
    * accordingly. Again, Don't specify the units! */
  --underline-width: 12;

  /* The color used to draw the underline. It should match the color
    * used in the cap images... unfortunately we can't modify the SVG
    * fill via CSS because it's a background image. */
  --underline-color: #00e0b7;

  /* We need to know the width of the cap images so that we
    * can position everything on the x axis accordingly. */
  --underline-cap-width: 4px;

  /* The border is positioned relative to the bottom of the line.
    * We can move it upwards a little to create an overlap effect. */
  --underline-offset-y: -2px;

  /* The padding to add to the x axis. By default, the caps would be
    * aligned with the beginning and end of the line. */
  --underline-padding-x: 0.12em;
}

ion-popover [popover]:not(:popover-open):not(dialog[open]) {
  display: contents;
}