@import "../../../../scss/index.scss";

.container {
  .content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .title {
      @include typography($style: $title-3);
      margin-top: 24px;
      margin-bottom: 12px;
    }

    ul {
      li {
        &::marker {
          font-size: 16px;
          color: var(--color-main-mint);
        }
      }
    }

    ion-button {
      width: 100%;
    }

    .footnote {
      @include typography($style: $label-body-5-medium);
      color: var(--color-grayscale-200);
      padding-bottom: 24px;
      border-bottom: 1px solid var(--color-grayscale-400);
    }

    span {
      @include typography($style: $label-body-3-regular);
      a {
        color: var(--color-main-mint);
      }
    }
  }

  .bg {
    display: none;
  }
}

@media screen and (min-width: $breakpoint-md) {
  .container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    .bg {
      display: block;
      grid-column: 2 / -1;
      background-image: url("/assets/img/reports-bg.jpg");
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }

    .content {
      .title {
        @include typography($style: $headline-h2);
        margin-top: 32px;
        margin-bottom: 16px;
      }

      ul {
        li {
          &::marker {
            font-size: 24px;
          }
        }
      }

      .footnote,
      span {
        @include typography($style: $label-body-3-medium);
      }
    }
  }
}
