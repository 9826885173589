@import "../../../../scss/index.scss";

.tourInfo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--color-white);
  padding: 12px;
  border-radius: 8px;
  width: 100%;

  span:first-of-type {
    @include typography($style: $label-body-3-semi-bold);
    color: var(--color-grayscale-800);
  }

  span:last-of-type {
    @include typography($style: $label-body-5-regular);
    color: var(--color-grayscale-400);
  }
}

.content {
  --padding-start: 24px;
  --padding-end: 24px;
  --background: var(--color-grayscale-25);
  position: relative;

  .webContainer {
    display: grid;
    grid-template-columns: 1fr 1.2fr;
    gap: 24px;
    padding: 24px;
    height: 100%;

    .leftSection {
      position: relative;
      height: 100%;
      overflow: hidden;

      ion-list {
        height: 100%;
        overflow: scroll;
        @include show-scrollbar;
      }
    }

    .rightSection {
      display: flex;
      flex-direction: column;
      gap: 24px;
      overflow: hidden;
      height: 100%;


      .list {
        height: 100%;
        overflow-y: scroll;
        @include show-scrollbar;
      }
    }
  }

  ion-grid {
    display: flex;
    flex-direction: column;
    gap: 12px;
    position: sticky;
    top: 0;
    z-index: 1;
    background: var(--color-grayscale-25);
    padding: 0;
  }

  .noResults {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    color: var(--color-grayscale-200);

    ion-icon {
      font-size: 80px;
    }

    span {
      @include typography($style: $label-body-2-semi-medium);
    }
  }

  ion-list {
    background: unset;
    ion-item {
      margin: 8px 0;
      --background: unset;

      .listing {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        .info {
          display: flex;
          flex-direction: column;
          gap: 4px;

          .address {
            @include typography($style: $label-body-3-regular);
            color: var(--color-grayscale-1000);

            .type {
              @include typography($style: $caption-1);
              color: var(--color-grayscale-400);
            }
          }

          .datetime {
            @include typography($style: $label-body-5-regular);
            color: var(--color-grayscale-400);
          }
        }

        .actions {
          display: flex;
          align-items: center;
          gap: 8px;

          .icon {
            font-size: 18px;
          }

          .favoriteIcon {
            color: var(--color-warning-normal);
          }

          .searchIcon {
            color: var(--color-grayscale-600);
          }

          ion-button {
            --padding-start: 4px;
            --padding-end: 4px;
            --padding-top: 4px;
            --padding-bottom: 4px;
          }
        }
      }
    }
  }

  .types {
    display: flex;
    gap: 16px;

    ion-checkbox {
      color: var(--color-grayscale-800);
      @include typography($style: $label-body-3-regular);
      &::part(label) {
        margin-left: 8px;
      }
    }
  }
}

.footer {
  padding: 12px;
  background-color: var(--color-grayscale-25);
  box-shadow: 0px -6px 10px 0px rgba(0, 0, 0, 0.08);
}

.totalButton {
  position: relative;
  @include typography($style: $label-body-1-semi-bold);
  height: 64px;

  span {
    margin-right: auto;
  }

  .total {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 12px;
    width: 36px;
    height: 36px;
    top: 50%;
    right: 4px;
    transform: translateY(-50%);
    border-radius: 8px;
    background-color: var(--color-main-mint);
    color: var(--color-white);
  }
}

.list {
  
  ion-item {
    margin-bottom: 8px;
    --border-radius: 8px;
    --border-width: 1px;
    --border-color: var(--color-grayscale-200);

    .listing {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      ion-icon {
        font-size: 16px;
        cursor: pointer;
      }
    }
  }

  @media screen and (min-width: $breakpoint-md) {
    ion-item {
      --background: var(--color-grayscale-25);
    }
  }
}

.sheetContent {
  background-color: var(--color-white);
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 24px;
  text-align: center;

  .title {
    @include typography($style: $label-body-1-semi-bold);
    color: var(--color-grayscale-600);
  }

  .listings {
    display: flex;
    flex-direction: column;
    gap: 12px;
    span {
      @include typography($style: $label-body-3-medium);
      color: var(--color-grayscale-800);
    }
  }
}
