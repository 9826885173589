@import "../../../scss/index.scss";

.header {
  padding: 24px;
  background-color: var(--color-grayscale-25);
  @include safe-top-margin;
}

.topSection {
  @media screen and (min-width: $breakpoint-md) {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0px;
  }

  .summary {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 0;
    width: 100%;

    .title {
      display: flex;
      align-items: center;
      gap: 8px;
      @include typography($label-body-1-semi-bold);
      color: var(--color-grayscale-600);
    }

    ion-button {
      height: 32px;
      width: 32px;
      --padding-start: 0;
      --padding-end: 0;

      ion-icon {
        width: 70%;
        height: 70%;
      }
    }
  }

  @media screen and (min-width: $breakpoint-md) {
    position: sticky;
    top: 0;
    left: 0;
    padding: 24px 0;
    flex-direction: row;
    gap: 12px;
    background-color: var(--color-grayscale-25);
    z-index: 10;
    margin: 0;

    .summary {
      width: auto;
      .title {
        @include typography($headline-h3);
      }
    }
  }
}

.favoritesSort {
  flex: 1;
  justify-content: end;
  direction: rtl;

  ion-button {
    height: 32px;
    width: 32px;
    --padding-start: 0;
    --padding-end: 0;

    ion-icon {
      width: 70%;
      height: 70%;
    }
  }
}

.content {
  --background: var(--color-grayscale-25);
  --padding-start: 24px;
  --padding-end: 24px;

  .segmentContainer {
    display: flex;
    flex-direction: column;
    gap: 24px;

    @media screen and (min-width: $breakpoint-md) {
      margin-top: 24px;
    }
  }
}

.segment {
  position: sticky;
  left: 0;
  right: 0;
  z-index: 10;
  @include hide-scrollbar;
  margin-top: 12px;
  margin-bottom: 12px;

  --background: var(--color-grayscale-50);

  &.noMargin {
    margin-left: calc(-50vw + 50%);
    margin-right: calc(-50vw + 50%);
  }

  ion-segment-button {
    @include typography($label-body-1-semi-bold);
  }
}

@media screen and (min-width: $breakpoint-md) {
  .header {
    padding: 0;
  }

  .content {
    @include safe-top-margin;
    position: relative;
    --padding-start: 128px;
    --padding-end: 128px;
    --padding-top: 0;
    --padding-bottom: 24px;

    ion-segment {
      margin-top: 12px;
      ion-segment-button {
        @include typography($label-body-1-semi-bold);
      }
    }
  }
}
