.container {
    height: 350px;

    .legend {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .tooltip {
        display: flex;
        flex-direction: column;
        gap: 12px;
        border-radius: 12px;
        padding: 12px;
        background-color: var(--color-grayscale-25);
        box-shadow: 0px 6px 10px 0px #00000014;
        box-shadow: 0px 1px 18px 0px #0000000F;
        box-shadow: 0px 3px 5px 0px #0000001A;

        font-size: 14px;
        font-weight: 300;
        color: var(--color-grayscale-600);
    }
}