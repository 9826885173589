@import "../../../scss/index.scss";

.header {
  display: flex;
  flex-direction: column;
  padding: 24px;
  background-color: var(--color-grayscale-25);

  ion-toolbar {
    --background: none;
    --border-width: 0 !important;

    .title {
      @include typography($title-2);
      color: var(--color-grayscale-1000);
    }

    ion-back-button {
      @include typography($label-body-2-semi-medium);
    }
  }
}

.content {
  --padding-start: 24px;
  --padding-end: 24px;
  --padding-top: 24px;
  --padding-bottom: 24px;
  --background: var(--color-grayscale-25);
  color: var(--color-grayscale-1000);

  ion-card-content {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  h1 {
    @include typography($title-3);
    margin-bottom: 12px;
  }

  p {
    @include typography($label-body-3-regular);
  }

  a {
    @include typography($label-body-3-medium);
    color: var(--color-green-shade1);
  }

  .contactUs {
    display: flex;
    flex-direction: column;
    gap: 4px;

    p:first-of-type {
        font-weight: 600;
    }
  }
}

@media screen and (min-width: $breakpoint-md) {
  .header {
    padding: 0;
  }

  .content {
    --padding-start: 128px;
    --padding-end: 128px;
    --padding-top: 48px;
    --padding-bottom: 48px;
  }
}
