@import "../../../../../scss/index.scss";

.container {
  display: flex;

  .cards {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 24px;
    section {
      display: flex;
      flex-direction: column;
      gap: 12px;

      .label {
        @include typography($label-body-3-medium);
        color: var(--color-grayscale-600);
      }

      .card {
        display: flex;
        justify-content: space-between;
        background-color: var(--color-white);
        border-radius: 8px;
        padding: 12px;
        @include typography($label-body-3-regular);
        color: var(--color-graysacle-800);

        div:first-of-type {
          flex: 1;
        }

        ion-icon {
          font-size: 18px;
          cursor: pointer;
        }
      }
    }
  }
}

.sheetContent {
  background-color: var(--color-white);
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 24px;
  text-align: center;

  ion-textarea {
    text-align: left;
  }
}

@media screen and (min-width: $breakpoint-md) {
  .agentContainer {
    padding: 12px;
    border: 1px solid var(--color-grayscale-200);
    border-radius: 8px;
    
    .cards {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;

      .card {
        min-height: 128px;
      }
    }
    
  }
}
