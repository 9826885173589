@import '../../../../scss/index.scss';

.container {
    margin: 0 auto;
    display: flex;
    align-items: center;
    gap: 12px;

    .type {
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 4px;
        @include typography($label-body-3-medium);
        color: var(--color-grayscale-1000);

        ion-icon {
            font-size: 32px;
        }
    }
}