@import "../../../../scss/index.scss";

.container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  background-color: var(--color-grayscale-1000);
  border-radius: 8px;
  padding: 12px;

  section:first-of-type {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .header {
      color: var(--color-white);
      @include typography($label-body-3-semi-bold);
      text-transform: uppercase;
    }

    .content {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .info {
        display: flex;
        flex-direction: column;

        span:first-of-type {
          color: var(--color-white);
          @include typography($label-body-3-semi-bold);
        }

        span:last-of-type {
          color: var(--color-grayscale-200);
          @include typography($label-body-5-regular);
        }

        &:last-of-type {
          direction: rtl;
        }
      }
    }
  }

  section:last-of-type {
    ion-button {
      width: 100%;
    }
  }
}

@media screen and (min-width: $breakpoint-md) {
  .container {
    display: grid;
    grid-template-columns: 1fr 1fr;

    .content {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .info {
        width: 100%;
        direction: ltr !important;
      }
    }

    section:last-of-type {
      align-self: flex-end;
      ion-button {
        width: 100%;
      }
    }
  }
}
