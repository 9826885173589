@import "../../../../scss/index.scss";

.items {
  @include hide-scrollbar;
  padding: 0 28px;
  display: flex;
  flex-direction: column;

  .item {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 18px 0;

    p {
      @include typography($style: $label-body-3-medium);
      color: var(--color-grayscale-600);
    }

    .labelContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .label {
      @include typography($style: $label-body-3-semi-bold);
      color: var(--color-grayscale-600);
      line-height: 24px;
    }

    .inputs {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 16px;
    }
  }
}
