
// Typography

@mixin typography($style, $important: false) {
    font-size: map-get($style, font-size) #{if($important, '!important', '')};
    font-weight: map-get($style, font-weight) #{if($important, '!important', '')};
    line-height: map-get($style, line-height) #{if($important, '!important', '')};
    letter-spacing: map-get($style, letter-spacing) #{if($important, '!important', '')};
    @if map-has-key($style, 'font-style') {
        font-style: map-get($style, font-style) #{if($important, '!important', '')};
    }
    @if map-has-key($style, 'text-decoration') {
        text-decoration: map-get($style, text-decoration) #{if($important, '!important', '')};
    }
}

// Caption 3
$caption-3: (
  font-size: 6px,
  font-weight: 400,
  line-height: 8px,
  letter-spacing: 0px
);

// Caption 2
$caption-2: (
  font-size: 8px,
  font-weight: 400,
  line-height: 12px,
  letter-spacing: 0px
);

// Caption 1
$caption-1: (
  font-size: 10px,
  font-weight: 400,
  line-height: 16px,
  letter-spacing: 0px
);

// Label & Body 5 (Regular)
$label-body-5-regular: (
  font-size: 12px,
  font-weight: 400,
  line-height: 18px,
  letter-spacing: 0px
);

// Label & Body 5 (Italic Regular)
$label-body-5-italic-regular: (
  font-size: 12px,
  font-weight: 400,
  line-height: 18px,
  letter-spacing: 0px,
  font-style: italic
);

// Label & Body 5 (Underlined Regular)
$label-body-5-underlined-regular: (
  font-size: 12px,
  font-weight: 400,
  line-height: 18px,
  letter-spacing: 0px,
  text-decoration: underline
);

// Label & Body 5 (Medium)
$label-body-5-medium: (
  font-size: 12px,
  font-weight: 500,
  line-height: 18px,
  letter-spacing: 0px
);

// Label & Body 4 (Regular)
$label-body-4-regular: (
  font-size: 13px,
  font-weight: 500,
  line-height: 20px,
  letter-spacing: 0px
);

// Label & Body 4 (Underlined Regular)
$label-body-4-underlined-regular: (
  font-size: 13px,
  font-weight: 500,
  line-height: 20px,
  letter-spacing: 0px,
  text-decoration: underline
);

// Label & Body 3 (Regular)
$label-body-3-regular: (
  font-size: 14px,
  font-weight: 400,
  line-height: 22px,
  letter-spacing: 0px
);

// Label & Body 3 (SemiBold)
$label-body-3-semi-bold: (
  font-size: 14px,
  font-weight: 600,
  line-height: 24px,
  letter-spacing: 0px
);

// Label & Body 3 (Medium)
$label-body-3-medium: (
  font-size: 14px,
  font-weight: 500,
  line-height: 24px,
  letter-spacing: 0px
);

// Label & Body 3 (Italic Regular)
$label-body-3-italic-regular: (
  font-size: 14px,
  font-weight: 400,
  line-height: 22px,
  letter-spacing: 0px,
  font-style: italic
);

// Label & Body 3 (Light)
$label-body-3-light: (
  font-size: 14px,
  font-weight: 300,
  line-height: 24px,
  letter-spacing: 0px
);

// Label & Body 2 (Regular)
$label-body-2-regular: (
  font-size: 16px,
  font-weight: 400,
  line-height: 24px,
  letter-spacing: 0px
);

// Label & Body 2 (Medium)
$label-body-2-semi-medium: (
  font-size: 16px,
  font-weight: 500,
  line-height: 24px,
  letter-spacing: 0px
);

// Label & Body 1 (Light)
$label-body-1-light: (
  font-size: 18px,
  font-weight: 300,
  line-height: 28px,
  letter-spacing: 0px
);

// Label & Body 1 (Regular)
$label-body-1-regular: (
  font-size: 18px,
  font-weight: 400,
  line-height: 28px,
  letter-spacing: 0px
);

// Label & Body 1 (SemiBold)
$label-body-1-semi-bold: (
  font-size: 18px,
  font-weight: 600,
  line-height: 20px,
  letter-spacing: 0px
);

// Title 3
$title-3: (
  font-size: 20px,
  font-weight: 500,
  line-height: 24px,
  letter-spacing: 0px
);

// Title 2
$title-2: (
  font-size: 22px,
  font-weight: 600,
  line-height: 32px,
  letter-spacing: 0px
);

// Title 1
$title-1: (
  font-size: 26px,
  font-weight: 600,
  line-height: 32px,
  letter-spacing: 0px
);

// Headline/H3
$headline-h3: (
  font-size: 30px,
  font-weight: 600,
  line-height: 36px,
  letter-spacing: 0.2px
);

// Headline/H2
$headline-h2: (
  font-size: 36px,
  font-weight: 600,
  line-height: 44px,
  letter-spacing: 0px
);

// Headline/H1
$headline-h1: (
  font-size: 42px,
  font-weight: 600,
  line-height: 52px,
  letter-spacing: 0px
);
