@import "../../../../../../scss/index.scss";

.container {
  margin: 12px 0;
  .toggleContainer {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 12px;

    .header {
      display: none;
    }

    ion-toggle {
        margin-left: auto;
        &::part(track) {
          width: 76px !important;
        }
      }
  }

  .room {
    flex-direction: column;
    align-items: start;
    gap: 4px;

    .description {
      @include typography($style: $label-body-4-regular);
    }

    .size, .features {
      @include typography($style: $label-body-3-light);
    }
  }

  .header {
    display: none;
  }
}


@media screen and (min-width: $breakpoint-md) {
  .container {

    .toggleContainer {
      display: none;
    }

    .room {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      .description, .size, .features {
        @include typography($style: $label-body-1-light);
      }
    }

    .header {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      column-gap: 24px;
      margin: 24px 0;
      @include typography($style: $label-body-1-semi-bold);

      span {
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: 24px;
      }
    }
  }
}
