@import "../../../../scss/index.scss";

.container {
  position: relative;
  display: grid;
  grid-template-columns: 1fr min-content;
  grid-template-rows: 1fr;
  width: 100%;
  height: 737px;
  padding: 48px;
  gap: 16px;

  .backButton {
    display: none;
  }

  .buttons {
    position: absolute;
    top: 24px;
    right: 24px;
    display: flex;
    align-items: center;
    gap: 8px;
    z-index: 10;

    .button {
      width: 48px;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--color-black);
      border-radius: 4px;
      cursor: pointer;

      ion-spinner {
        width: 24px;
        height: 24px;
      }

      ion-icon {
        color: var(--color-white);
        font-size: 24px;
      }
    }
  }

  .slider {
    width: 100%;
    height: 100%;

    .slide {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      transition: opacity 1s ease;

      &.active {
        opacity: 1;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .controls {
      position: absolute;
      bottom: 24px;
      right: 24px;

      height: 48px;
      display: flex;
      gap: 4px;
      align-items: center;

      .navigation,
      .counter {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        background-color: var(--color-grayscale-50);
        padding: 8px;
        border-radius: 4px;
        opacity: 0.8;
      }

      .navigation {
        padding: 0 12px;
        cursor: pointer;
        ion-icon {
          font-size: 16px;
          color: var(--color-grayscale-75);
        }
      }

      .counter {
        width: 104px;
        font-size: 18px;
        line-height: 28px;
        font-weight: 600;
        color: var(--color-grayscale-1000);
      }
    }
  }

  .thumbnails {
    display: flex;
    flex-direction: column;
    gap: 16px;
    height: 100%;
    overflow-y: auto;
    @include hide-scrollbar;

    .thumbnail {
      width: 225px;
      height: 150px;
      cursor: pointer;
      transition: border 0.3s ease;
      opacity: 0.6;
      border-radius: 6px;

      &.active {
        opacity: 1;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 6px;
      }
    }
  }

  .swiper {
    background-color: var(--color-grayscale-25);
    width: 100%;
    height: 100%;

    .swiperSlide {
      width: 100%;
      height: 100%;
      overflow: hidden;
      
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

.enabledFavourite {
  fill: var(--color-white);
  stroke: var(--color-white);
}

.disabledFavourite {
  fill: none;
  stroke: var(--color-white);
}

.enabledNotification {
  fill: var(--color-warning-normal) !important;
  color: var(--color-warning-normal) !important;
  stroke: var(--color-warning-normal) !important;
}

.disabledNotification {
  fill: var(--color-black) !important;
  color: var(--color-white) !important;

  &:hover {
    fill: var(--color-warning-normal);
    color: var(--color-warning-normal);
  }
}

.disabled {
  cursor: not-allowed;
  fill: var(--color-black);
  color: var(--color-grayscale-400) !important;
  stroke: var(--color-grayscale-400) !important;
}

@media screen and (max-width: $breakpoint-md) {
  .container {
    grid-template-columns: 1fr;
    height: 260px;
    padding: 0;
    gap: 0;

    .backButton {
      @include safe-top-margin;
      display: block;
      position: absolute;
      top: 24px;
      left: 24px;
      z-index: 10;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--color-black);
      border-radius: 4px;
      cursor: pointer;
      width: 32px;
      height: 32px;

      ion-icon {
        color: var(--color-white);
        font-size: 20px;
      }
    }

    .buttons {
      @include safe-top-margin;
      .button {
        width: 32px;
        height: 32px;

        ion-icon {
          font-size: 20px;
        }
      }
    }
  }
}

.galleryModal {
  --height: 95vw;
  --width: 95vw;
}


.fullScreenGallery {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: var(--color-grayscale-25);

  ion-icon {
    position: absolute;
    top: 12px;
    right: 12px;
    font-size: 24px;
    color: var(--color-grayscale-1000);
    cursor: pointer;
    z-index: 10;
  }

  --swiper-navigation-size: 24px;
  --swiper-navigation-top-offset: 50%;
  --swiper-navigation-sides-offset: 12px;
  --swiper-navigation-color: var(--color-green-shade2);

  .swiper-pagination {
    z-index: 1000 !important;
    display: block;
    backface-visibility: visible;
  }

  .modalSwiper {
    width: 100%;
    height: 100%;

    .modalSwiperSlide {
      width: 100%;
      height: 100%;

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  
}

@media screen and (min-width: $breakpoint-md) {
  .slider {
    position: relative;
  }

  .galleryModal {
    --height: 80vh;
    --width: 80vw;

    .fullScreenGallery {
      ion-icon {
        font-size: 36px;
      }

      --swiper-navigation-size: 48px;
      --swiper-navigation-sides-offset: 24px;
    }
  }
}
