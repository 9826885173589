
@import '../../../../scss/index.scss';

.filters {
  padding: 0 28px;
  display: flex;
  flex-direction: column;

  .filter {
    display: flex;
    flex-direction: column;
    gap: 12px;
    border-bottom: 1px solid var(--color-grayscale-50);
    padding: 12px 0;

    .labelContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .label {
      @include typography($style: $label-body-3-medium);
      color: var(--color-grayscale-600);
    }

    .inputs {
      display: flex;
      align-items: center;
      gap: 16px;
      @include typography($style: $label-body-3-medium);
    }
  }
}
