.container {
  display: flex;
  align-items: center;
  gap: 12px;

  ion-icon {
    cursor: pointer;
    transition: fill 0.3s, stroke 0.3s;
    width: 24px;
    height: 24px;
  }

  .enabledFavourite {
    stroke: var(--color-black);
    fill: var(--color-main-mint);
  }

  .disabledFavourite {
    fill: var(--color-white);
    stroke: var(--color-black);

    &:hover {
      fill: var(--color-main-mint);
    }
  }

  .enabledNotification {
    fill: var(--color-warning-normal);
    color: var(--color-black);
  }

  .disabledNotification {
    fill: transparent;
    color: var(--color-grayscale-200);

    &:hover {
      fill: var(--color-warning-normal);
      color: var(--color-warning-normal);
    }
  }

  .disabled {
    cursor: not-allowed;
    fill: var(--color-grayscale-200);
    color: var(--color-grayscale-200);
  }
}

.small {
  ion-icon:first-of-type {
    font-size: 22px;
  }
  ion-icon:last-of-type {
    font-size: 24px;
  }
}

.large {
  ion-icon:first-of-type {
    font-size: 24px;
  }
  ion-icon:last-of-type {
    font-size: 28px;
  }
}
