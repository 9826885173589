@import "../../../../../../scss/index.scss";

.container {
  margin: 12px 0;
}

@media screen and (min-width: $breakpoint-md) {
  .container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 4px;
    column-gap: 24px;
  }
}
