@import "../../../scss/index.scss";

.appHeader {
  @include safe-top-padding;
  ion-label {
    color: $grey-dark-1;
    font-size: 1.5rem;
    font-weight: 400;
    text-transform: initial;
  }

  .toolbar {
    --background: #f2f2f2;

    .title {
      display: flex;
      justify-content: center;
      font-size: 2.2rem;
      color: $grey-dark-2;
      font-weight: 600;
    }

    .btn {
      --color: #707070;
      --icon-font-size: 1.8rem;
      --icon-padding-end: 0.8rem;
      font-size: 1.5rem;
      margin-left: 1rem;
      text-transform: initial;

      .label {
        font-size: 1.5rem;
      }
    }

    .hidden {
      .manageAlerts {
        ion-icon {
          color: $grey-light-1 !important;
        }
        span {
          color: $grey-light-1;
        }
      }
    }

    .manageAlerts {
      display: grid;
      justify-content: end;
      align-items: center;
      grid-template-columns: auto auto;
      margin-right: 0.5rem;

      ion-icon {
        height: 1.8rem;
        width: 1.8rem;
        padding-right: 0.4rem;
        color: $grey-dark-1 !important;
      }

      span {
        color: $grey-dark-1;
        font-size: 1.5rem;
        font-weight: 400;
        text-transform: initial;
      }
    }
  }
}
