@import "../../../scss/index.scss";

.header {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 24px;
  background-color: var(--color-grayscale-25);

  ion-toolbar {
    --background: none;
    --border-width: 0 !important;

    .title {
      @include typography($title-2);
      color: var(--color-grayscale-1000);
    }

    ion-back-button {
      @include typography($label-body-2-semi-medium);
    }
  }

  .text {
    @include typography($label-body-3-regular);
    color: var(--color-grayscale-600);
  }
}

.content {
  --background: var(--color-grayscale-25);
  --padding-start: 24px;
  --padding-end: 24px;

  .container {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .step {
      display: flex;
      flex-direction: column;
      gap: 12px;
      .item {
        display: flex;
        flex-direction: column;
        gap: 4px;

        .label {
          @include typography($label-body-5-medium);
          color: var(--color-grayscale-800);
          display: flex;
          align-items: center;
          justify-content: space-between;

          ion-button {
            --padding-start: 6px;
            --padding-end: 6px;
            width: 36px;
            ion-icon {
              font-size: 20px;
            }
          }
        }

        .slot {
          display: flex;
          align-items: center;
          justify-content: space-between;
          @include typography($label-body-3-regular);
          ion-button {
            min-height: 20px;
            max-height: 20px;

            &::part(native) {
              padding: 0;
              min-height: 20px;
              max-height: 20px;
            }
          }
        }

        ion-item {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          --padding-end: 10px;
        }

        ion-textarea {
          height: 100px;
        }

        .row {
          display: flex;
          align-items: center;
          gap: 8px;

          .subItem {
            flex: 1;
            .subLabel {
              @include typography($caption-1);
              color: var(--color-grayscale-400);
            }
          }
        }
      }
    }
  }
}

.footer {
  background-color: var(--color-grayscale-25);
  box-shadow: none;
  padding: 24px;
}

.buttons {
  display: flex;
  align-items: center;
  gap: 8px;

  ion-button {
    flex: 1;
  }
}

@media screen and (min-width: $breakpoint-md) {
  .header {
    padding: 48px 128px;
  }

  .content {
    position: relative;
    height: 100%;
  }

  .container {
    width: 40%;
    height: 65%;
    overflow: auto;
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    ion-breadcrumb {
      &::part(native) {
        font-size: 18px !important;
      }
    }

    .step {
      border: 1px solid var(--color-grayscale-200);
      border-radius: 8px;
      padding: 24px;
    }

    .buttons {
      margin-top: 12px;
    }
  }
}
