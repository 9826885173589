@import "../../../../scss/index.scss";

.map {
  position: relative;
  width: 100%;
  height: 100%;

  .clusters {
    position: absolute;
  }

  .locationPin {
    height: 3rem;
    width: 3rem;
    fill: $grey-dark-2;
    stroke: transparent;
    stroke-width: 1px;
  }

  .lightLocationPin {
    fill: $grey-dark-2;
    stroke: rgba(255, 255, 255, 0.75);
    stroke-width: 1px;
  }

  .detailsPin {
    height: 2.4rem;
    width: 2.4rem;
    fill: $orange-medium;
    stroke: $orange-light;
    stroke-width: 1px;
    z-index: 100;
  }

  .lightDetailsPin {
    fill: $orange-light;
    stroke: $orange-medium;
    stroke-width: 1px;
  }

  .schoolPin {
    height: 4rem;
    width: 4rem;
    stroke-width: 1px;
    cursor: pointer;
  }

  .schoolPinDeactive {
    fill: $green-light-1;
    stroke: $green-dark-1;
  }

  .schoolPinActive {
    stroke: $green-light-1;
    fill: $green-dark-1;
  }

  .schoolPinSelected {
    stroke: white !important;
    fill: $orange-dark !important;
  }

  .schoolAnimatedPin {
    animation: mountTranslate 0.4s ease-in-out;
  }
}

.hidden {
  display: none;
}

.cards {
  border-radius: 12px;
  background-color: white;
  box-shadow: 2px 2px 13px #949494;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  min-height: 176px;
  max-height: 328px;
  width: 370px;
  padding: 12px;

  .listings {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 12px;
    overflow-y: auto;
    @include show-scrollbar;
  }
}


.schoolCard {
  box-shadow: 2px 2px 13px #949494;
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 370px;
  border-radius: 12px;
}

.layersBtn {
  position: absolute;
  left: 1.5rem;
  bottom: 1.5rem;
  width: 5rem;
  height: 5rem;
  z-index: 1000;
}

.saveSearchBtn {
  position: absolute;
  right: 1.5rem;
  bottom: 1.5rem;
  z-index: 1000;
  display: none;
}

.loadingContainer {
  position: absolute;
  @include center-horizontally;
  width: 5rem;
  height: 5rem;
  z-index: 100;
  .loading {
    width: 5rem;
    height: 5rem;
    color: $grey-dark-2;
  }
}

.removeOutlineButton {
  @include safe-top-margin;
  position: absolute;
  left: 16px;
  bottom: 16px;
  z-index: 1000;
}

.favouritesBtn {
  margin-top: 0;
}

.noResults {
  position: absolute;
  bottom: 80px;
  left: 50%;
  transform: translateX(-50%);
  width: 70%;
  background-color: var(--color-warning-tint);
  box-shadow: $box-shadow-grey;
  font-size: 12px;
  color: var(--color-grayscale-600);
  text-align: center;
  z-index: 1500;
  border-radius: $radius-md;
  line-height: 15px;

  p {
    padding-right: 2rem;
  }

  .close {
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
    font-size: 18px;
  }
}

.keyboard-shortcuts-dialog-view {
  display: none;
}

.listingsSheet {

  ion-content {
    .listings {
      background-color: var(--color-white);
      display: flex;
      flex-direction: column;
      gap: 12px;
      padding: 24px;
    }
  }
}

@keyframes Border {
  0% {
    border-color: #f2f2f2;
  }
  10% {
    border-color: #dbdbdb;
  }
  20% {
    border-color: #9c9c9c;
  }
  30% {
    border-color: #6e6e6e;
  }
  40% {
    border-color: #363636;
  }
  50% {
    border-color: #1a1a1a;
  }
  60% {
    border-color: #363636;
  }
  70% {
    border-color: #6e6e6e;
  }
  80% {
    border-color: #9c9c9c;
  }
  90% {
    border-color: #dbdbdb;
  }
  100% {
    border-color: #f2f2f2;
  }
}

@-webkit-keyframes Border {
  0% {
    border-color: #f2f2f2;
  }
  10% {
    border-color: #dbdbdb;
  }
  20% {
    border-color: #9c9c9c;
  }
  30% {
    border-color: #6e6e6e;
  }
  40% {
    border-color: #363636;
  }
  50% {
    border-color: #1a1a1a;
  }
  60% {
    border-color: #363636;
  }
  70% {
    border-color: #6e6e6e;
  }
  80% {
    border-color: #9c9c9c;
  }
  90% {
    border-color: #dbdbdb;
  }
  100% {
    border-color: #f2f2f2;
  }
}

@keyframes mountTranslate {
  0% {
    opacity: 0;
    transform: scale(1) translateY(-25px);
  }
  100% {
    opacity: 1;
    transform: scale(1) translateY(0);
  }
}
