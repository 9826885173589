@import '../../../../scss/index.scss';

.review {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;
    align-items: center;

    .owner {
        @include typography($label-body-5-medium);
        color: var(--color-grayscale-800);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .rating {
        flex: 1;
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: 16px;

        ion-icon {
            cursor: pointer;
            font-size: 30px;
            color: var(--color-white);
            stroke: var(--color-warning-normal);

            &.filled {
                fill: var(--color-warning-normal);
            }
        }
    }
}

.userReview {
    grid-template-columns: 1fr 2fr;
}