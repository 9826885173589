@import "../../../../scss/index.scss";

.container {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include typography($style: $label-body-3-regular);
  cursor: pointer;

  .name {
    color: var(--color-grayscale-1000);
  }

  .type {
    color: var(--color-grayscale-600);
  }

  .bold {
    @include typography($style: $label-body-3-medium);
  }
}
